import React from 'react';
import style from './Top.module.css';
import Accordion from './Accordion';
const TopComponent = () => {
  const boardMembers = [
    {
      id: 0,
      name: 'Owolabi Adekoya',
      role: 'Sr. SRE@VMware. Previously, Staff Software Engineer@IBM & Researcher@University of Saskatchewan.'
    },
    {
      id: 1,
      name: 'Adeshina Alani',
      role: 'TPM Privacy@Meta. Previously, Researcher @University of Victoria.'
    },
    {
      id: 2,
      name: 'Ahmed Tiamiyu, PhD',
      role: 'Ast. Professsor@University of Calgary. Previously, Research Fellow@MIT.'
    },
    {
      id: 3,
      name: 'Rachael Omodanisi',
      role: 'Business & Investment Analyst@Leadway Assurance. Data Scientist@University of Glasgow'
    },
    {
      id: 4,
      name: 'Ayoade Ilori, PhD',
      role: 'Data Analyst@Barclays. Previously, Analyst@PwC & Researcher@Aston University.'
    },
    {
      id: 5,
      name: 'Yemisi Isidi',
      role: 'Business coach@Future Females, Co-founder@Thriift Africa.Previously, Candidate@Aston University.'
    },
    {
      id: 6,
      name: 'Babatunde David, PhD',
      role: 'CEO, Seven Star Consultants Ltd & Consultant@The World Bank. Previously, Consultant @AfDB, USAID, UNDP & Research Fellow@ The University of Salford.'
    },
    {
      id: 7,
      name: 'Dayo Ibitoye, ARPA',
      role: 'Specialist@The World Bank, Consultant@UNDP. Previously, Candiate@ Harvard Kennedy School.'
    }
  ];
  return (
    <div className={style.container}>
      <div className={style.section_one_header}>
        <h1>
          Our mission is to increase the <br />
          GDP of the internet in tech and
          <br /> research
        </h1>
      </div>
      <p>
        Coachmie builds infrastructure for learners and experts to cowork on the
        internet. Learners use our infrastructure to engage with experts online
        in order to - get into tech, build tech products and get into graduate
        research programs anywhere in the world. Registered in the United
        States, United Kingdom and Nigeria, the company aims to get more people
        into tech and research.
      </p>
      <div className={style.users_description}>
        <div className={style.left}>
          <p>
            <strong className={style.text_underline}>Learners</strong> are those
            interested in tech and research careers
          </p>
        </div>
        <div className={style.right}>
          <p>
            <strong className={style.text_underline}>Experts </strong>
            are those with 5+ years of experience in tech and research
          </p>
        </div>
      </div>
      {/**Board Members */}
      {/* <div className={style.board_members}>
        <h1>Advisory Board Members</h1>
        <div className={style.board_content}>
          {boardMembers.map((boardMember) => (
            <div className={style.board_member}>
              <h2>{boardMember.name}</h2>
              <span>
                Sr. SRE@VMware. Previously, Staff Software Engineer@IBM &
                Researcher@University of Saskatchewan.
              </span>
            </div>
          ))}
        </div>
      </div> */}
      {/** End of Board Menbers */}
      <div className={style.guiding_culture}>
        <h1>Guiding Culture</h1>
        <Accordion />
      </div>
    </div>
  );
};

export default TopComponent;
