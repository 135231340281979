import { apiErrorHandler } from 'Helpers/utility'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { getTransactions, getTransactionsStats } from 'services/transactionService'
import TotalComponent from './Component/TotalComponent'
import TransactionsTable from './Component/TransactionsTable'

const Transactions = () => {
    const [state, setState] = useState({
        data: [],
        pageNo: 1,
        pageSize: 20,
        hasMore: false,
        totalRecord: 0,
        loading: true
    })

    const [stats, setStats] = useState({
        loading: true,
        data: {
            tranxSum: 0
        }
    })
    const fetchTransactions = useCallback(async (pageNo = 1) => {
        try {
            let { data: { data } } = await getTransactions(state.pageSize, pageNo)
            setState((prev: any) => ({
                ...prev,
                data: pageNo > 1 ? prev.data.concat(data.tranx) : data.tranx,
                pageNo: data.pageNo,
                totalRecord: data.count,
                hasMore: ((pageNo * state.pageSize) < data.count),
                loading: false
            }))
        } catch (error) {
            apiErrorHandler(error)
            setState((prev: any) => ({
                ...prev,
                loading: false
            }))
        }
    }, [state.pageSize])

    const fetchState = useCallback(async()=>{
        try {
            let {data: {data}} = await getTransactionsStats();
            setStats((prev)=>({
                ...prev,
                data: data,
                loading: false
            }))
        } catch (error) {
            apiErrorHandler(error)
            setStats((prev)=>({
                ...prev,
                loading: false
            }))
        }
    }, [])
    useEffect(() => {
        fetchTransactions(1)
        fetchState()
    }, [fetchTransactions, fetchState])


    return (
        <Container id="transactions">
            <Row>
                <Col>
                    <TransactionsTable loading={state.loading} header="" loadMore={() => fetchTransactions(state.pageNo + 1)} tableData={state.data} />
                </Col>
            </Row>
            <Row className='my-5'>
                <Col>
                    <TotalComponent stats={stats}/>
                </Col>
            </Row>
        </Container>
    )
}

export default Transactions