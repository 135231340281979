import React from 'react';
import style from './index.module.css';
import Accordion from './Components/Accordion';

const Index = () => {
  const BillingQuestions = [
    {
      question: 'How can I pay for my coaching sessions',
      answer:
        'You can pay online using a credit or debit card. We use third-party payment companies such as Stripe and Flutterwave (for Learners paying from Nigeria and some parts of Africa) for fulfilling payment transactions.',
      isOpened: false
    },
    {
      question: 'What are the pricing models for Coachmie?',
      answer:
        'Our pricing model is tied to the session type. As of now, we use a pay as you go pricing model. Experts determine price on the platform.',
      isOpened: false
    },
    {
      question:
        'What is the operating cost charged by Coachmie for session hosting?',
      answer:
        'Profile and group session marketing is completely free on the platform. However, for 1:1 learning session hosting the infrastructure cost is 5% of the Expert’s session price. On the other hand, the infrastructure cost for Group learning session hosting is 20% of the Expert’s group session price.',
      isOpened: false
    },
    {
      question: 'Can I get a free learning session with an expert?',
      answer:
        'Experts host free group sessions as often as possible. A learner can join any of the free Group sessions via the Coachmie’s marketing page.',
      isOpened: false
    },
    {
      isOpened: false,
      question: 'How can I change my password in case I forget it?',
      answer:
        'Both Learners and Experts can change their password by clicking on the login button on the main marketing site'
    },
    {
      question: 'What is the cancellation policy at Coachmie?',
      isOpened: false,
      answer:
        'A Learner can cancel a session 48 hours before the session with a full refund while a cancellation that is 24 hours before the session will lead to forfeiture of 50% of the session fee. There will be no refund for a cancellation less than 24 hours to the start of the session. '
    },
    {
      question: 'How can I reschedule my session?',
      isOpened: false,
      answer:
        'You can reschedule a session at most 24 hours before the start of the session.'
    }
  ];

  const Definition = [
    {
      question: 'What is Coachmie?',
      answer:
        'Coachmie is a learning platform where an Expert in tech and research can provide learning or advisory services to a Learner. Coachmie provides the infrastructure for an efficient engagement between both parties.',
      isOpened: false
    },
    {
      question: 'Who is a Learner?',
      answer:
        'A Learner engages the learning and advisory services of an Expert on the Coachmie platform either on a 1:1 basis or in groups with other Learners.'
    },
    {
      question: 'Who is an expert?',
      answer:
        'An Expert provides learning and advisory services on the Coachmie platform.',
      isOpened: false
    },
    {
      question: 'Who is a user?',
      answer:
        'Both the Learner and Expert are referred to as users on the Coachmie platform.',
      isOpened: false
    }
  ];

  const registration = [
    {
      question: 'How can I sign up as an expert?',
      answer:
        'You can sign up on the expert’s page. You first request an invite and if your request is approved after detailed verification, you will receive an email to complete the onboarding process. Experts must set up their account details before they can start hosting sessions on the Coachmie platform.',
      isOpened: false
    },
    {
      question: 'How can I sign up as a learner?',
      answer: 'You can sign up on the main Coachmie’s marketing page.',
      isOpened: false
    },
    {
      question: 'What type of learning sessions can I sign up for?',
      answer:
        'We have one-on-one (1:1) and Group type learning sessions. Each is tailored to meet your needs.',
      isOpened: false
    },
    {
      question: 'How do I schedule a 1:1 learning session?',
      answer:
        'A registered Learner can schedule a 1:1 learning session by searching for experts in an area of interest from Coachmie’s marketing site.',
      isOpened: false
    },
    {
      question: 'How do I create a Group learning session?',
      answer:
        'Only an Expert can create a group learning session. Experts can create a group learning session from their dashboard under the group tab. The session can be free or paid and a session-size can be specified depending on the number of Learners the Expert prefers.',
      isOpened: false
    },
    {
      question: 'How do I join a Group learning session?',
      answer:
        'A Learner can join any published Group learning session depending on their needs on the Coachmie’s marketing site by paying for the specific Group learning session.',
      isOpened: false
    },
    {
      question:
        'How is an expert verified and what is the requirement for an expert to host sessions on the platform?',
      answer:
        'Experts are required to have a minimum of 5 years of experience in a specific domain of expertise. We conduct rigorous background checks and vetting before we accept invites from prospective Experts for onboarding.',
      isOpened: false
    }
  ];

  const others = [
    {
      question: 'What are the benefits of Coachmie to an expert?',
      answer:
        'We take care of the logistics involved in a coaching session. The expert just needs to show up for their coaching session',
      isOpened: false
    },
    {
      question: 'What are the benefits of Coachmie to a user? ',
      answer: '',
      isOpened: false
    },
    {
      question: 'What do I do if my session isn’t satisfactory?',
      answer:
        'Send us an email at complaints@coachmie.com outlining your complaints',
      isOpened: false
    },
    {
      isOpened: false,
      question: 'Is there an age limit for enrolling for a session?',
      answer:
        'A Learner must be at least 18 years old. For some learning sessions, supervised learning services can be provided'
    },
    {
      question: 'How do I contact you if my question isn’t answered here?',
      isOpened: false,
      list: [
        'customer@coachmie.com  - for customer requests',
        'technical@coachmie.com - for technical issues',
        'billing@coachmie.com - for billing related issues',
        'complaints@coachmie.com - for issues related to session delivery'
      ],
      answer:
        'Depending on your needs, you can direct your questions to any of the following address:'
    },
    {
      question: 'How can I get technical support?',
      answer: '',
      isOpened: false
    }
  ];
  return (
    <div className={style.container}>
      <h1 className={style.section_desc}>Frequently Asked Questions</h1>
      <div className={style.definitions}>
        <h1 className={style.header}>Definitions</h1>
        <Accordion questions={Definition} />
      </div>
      <div className={style.registration}>
        <h1 className={style.header}>Registration</h1>
        <Accordion questions={registration} />
      </div>
      <div className={style.billings}>
        <h1 className={style.header}>Accounts and billing</h1>
        <Accordion questions={BillingQuestions} />
      </div>
      <div className={style.billings}>
        <h1 className={style.header}>Others</h1>
        <Accordion questions={others} />
      </div>
    </div>
  );
};

export default Index;
