import React, { useState } from 'react'

const PasswordInput: React.FC<any> = ({ invalid, ...props }) => {
    const [show, setShow] = useState(false)
    const handleChange = (e: any) => {
        props.onChange(e)
    }

    const toggleShow = (e: any) => {
        e.preventDefault()
        setShow((prev) => !prev)
    }
    return (
        <div className={`d-flex align-items-center custom-input form-control px-3 py-2 ${invalid ? 'is-invalid' : ''}`}>
            <input
                {...props}
                type={show ? "text" : "password"}
                onChange={handleChange}
            />
            <div onClick={toggleShow} >
                {!show && <i className="far fa-eye ml-auto mr-0 cursor-pointer" />}
                {show && <i className="far fa-eye-slash ml-auto mr-0 cursor-pointer" />}
            </div>

        </div>

    )
}

export default PasswordInput