import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { async_status } from "redux/Actions/types";
import { fetchUser } from "redux/Actions/userAction";
import { RootState } from "redux/Reducers";
import { useLocation } from "react-router-dom";
import { handleLogout } from "services/authService";
import Loader from "components/common/Loader";
import { ApiKeysContext } from "Contexts/ApiKeysContext";
import { getApiKeys } from "services/sessionService";
import { fetchDomains, fetchSubDomains } from "redux/Actions/domainAction";

export const AuthGuard: React.FC<any> = (props) => {
  const {
    keys: { status: apiKeysStatus },
    setKeys,
  } = useContext(ApiKeysContext);

  const dispatch = useDispatch();
  const { user, domains } = useSelector((state: RootState) => state);
  const location = useLocation();
  const fetchApiKeys = useCallback(async () => {
    try {
      const {
        data: { message },
      } = await getApiKeys();
      setKeys((prev: any) => ({
        ...prev,
        data: message,
        status: async_status.__LOADED__,
      }));
    } catch (error) {
      setKeys((prev: any) => ({
        ...prev,
        status: async_status.__FAILED__,
      }));
    }
  }, [setKeys]);
  useEffect(() => {
    if (user.status === async_status.__DEFAULT__) {
      dispatch(fetchUser());
    }
    // if (user.status === async_status.__LOADED__) {
    //     if (wishlist.status !== async_status.__LOADED__ && wishlist.status !== async_status.__LOADING__) {
    //         dispatch(fetchWishlist());
    //     }
    // }
  }, [user.status, apiKeysStatus, dispatch]);

  useEffect(() => {
    if (
      user.status === async_status.__LOADED__ &&
      apiKeysStatus !== async_status.__LOADED__
    ) {
      fetchApiKeys();
    }
  }, [user.status, apiKeysStatus, fetchApiKeys, location]);

  useEffect(() => {
    if (domains.status === async_status.__DEFAULT__) {
      dispatch(fetchDomains());
    }
  }, [dispatch, domains.status]);

  useEffect(() => {
    if (domains.subDomainStatus !== async_status.__LOADED__) {
      dispatch(fetchSubDomains());
    }
  }, [dispatch, domains.subDomainStatus]);

  if (
    user.status === async_status.__LOADING__ ||
    user.status === async_status.__DEFAULT__
  )
    return (
      <div className="vh-100">
        <Loader />
      </div>
    );
  if (user.status === async_status.__LOADED__) {
    // if (user.data.message === INCOMPLETE_PROFILE_MESSAGE
    //     // || user.data.data.regLevel !== "completed"
    // ) {
    //     navigate("/auth/signup")
    //     return ""
    // }
    return props.children;
  }
  if (user.status === async_status.__FAILED__) handleLogout();

  console.log("user.status = " + user.status);

  return "";
};

export default AuthGuard;
