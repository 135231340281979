import React from "react";

function NotificationsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      fill="none"
      viewBox="0 0 19 21"
    >
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M11.98 2.64a3 3 0 00-5.957 0 7.224 7.224 0 00-4.244 6.582v5.018L.11 17.55A1 1 0 001 19h5.171a3.001 3.001 0 005.659 0H17a1 1 0 00.893-1.45l-1.67-3.31V9.222a7.224 7.224 0 00-4.244-6.581zm2.35 12.289L15.377 17H2.626l1.046-2.071a1 1 0 00.107-.451V9.222a5.222 5.222 0 0110.444 0v5.256a1 1 0 00.108.45z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default NotificationsIcon;