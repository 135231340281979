import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
// import StarRatings from 'react-star-ratings'
import moment from 'moment';
import { DateTime } from 'luxon';
import { fetchUserData } from 'services/userService';
import { timeList } from 'Helpers/constants';
import { getExpetBookedDays } from 'services/sessionService';
export const getTimeRange = (
  hour: any,
  from: any,
  to: any,
  listParam = 'value'
) => {
  const compareFromValue =
    listParam === 'value' ? String(hour[from]?.toFixed(1)) : String(hour[from]);
  const compareToValue =
    listParam === 'value' ? String(hour[to]?.toFixed(1)) : String(hour[to]);
  const fromIndex = timeList.findIndex(
    (time: any) => time[listParam] === compareFromValue
  );
  let toIndex = timeList.findIndex(
    (time: any) => time[listParam] === compareToValue
  );
  let hoursList: any = [];
  if (fromIndex > toIndex) {
    const slicedArray = [];
    for (let i = fromIndex; i < timeList.length; i++) {
      slicedArray.push(timeList[i]);
    }
    for (let i = 0; i <= toIndex - 1; i++) {
      slicedArray.push(timeList[i]);
    }
    hoursList = slicedArray.map((list: any) => list.time);
  }
  if (fromIndex < toIndex) {
    hoursList = timeList
      .slice(fromIndex, toIndex)
      .map((list: any) => list.time);
  }
  return hoursList;
};

export const getCurrentYearByTimeZone = (timeZone: any) => {
  const currentYear = DateTime.local().setZone(timeZone).year;
  return currentYear;
};
// const getWeekDaysInMonth = (
//   year: any,
//   month: any,
//   dayOfWeek: any,
//   timeZone: any
// ) => {
//   const startDate = DateTime.local(year, month, 1, 0, 0, {
//     zone: timeZone,
//   });
//   const endDate = startDate.endOf("month");
//   const days = [];
//   let currentDate = startDate;
//   while (currentDate <= endDate) {
//     if (currentDate.weekday === dayOfWeek) {
//       days.push(currentDate);
//     }
//     currentDate = currentDate.plus({ days: 1 });
//   }
//   return days;
// };

export const getWeekDay = (day: any) => {
  switch (day) {
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    case 0:
      return 'sunday';
    default:
      return 'sunday';
  }
};

export const getSpecificDaysOfWeekInRange = (
  startDateString: string,
  endDateString: string,
  dayOfWeek: any,
  timeZone: string
) => {
  const startDate = DateTime.fromISO(startDateString, { zone: timeZone });
  const endDate = DateTime.fromISO(endDateString, { zone: timeZone });
  const daysOfWeek = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    if (currentDate.weekday === dayOfWeek) {
      daysOfWeek.push(currentDate.toISO());
    }
    currentDate = currentDate.plus({ days: 1 });
  }
  const todayInTimeZone = DateTime.local().setZone(timeZone).startOf('day');
  const futureDaysOfWeek = daysOfWeek.filter(
    (date) => DateTime.fromISO(date).setZone(timeZone) >= todayInTimeZone
  );
  return futureDaysOfWeek;
};

export const getTime = (
  time: any,
  sourceTimeZone: any,
  targetTimeZone: any
) => {
  const parsedTime = DateTime.fromFormat(time, 'h:mm a', {
    zone: sourceTimeZone
  });
  const timeInTargetTimeZone = parsedTime.setZone(targetTimeZone);

  const convertedTime = timeInTargetTimeZone.toFormat('h:mm a');
  return convertedTime;
};

export const getTimeISO = (
  time: any,
  sourceTimeZone: any,
  targetTimeZone: any
) => {
  const parsedTime = DateTime.fromISO(time, {
    zone: sourceTimeZone
  });
  const timeInTargetTimeZone = parsedTime.setZone(targetTimeZone).toISO();
  return timeInTargetTimeZone;
};
const token = localStorage.getItem('authToken');

const Top: React.FC<any> = ({
  toggleModal,
  data,
  id,
  accountInfo,
  loggedInUser
}) => {
  const availability = useMemo(
    () => data?.profilePages?.availabilityPage,
    [data]
  );
  const [nextAvailable, setNextAvailable] = useState('');
  const [timeZone, setZone] = useState('');

  const [learnerCurrentWeekDay, setLearnerWeekDay] = useState(0);
  const [state, setState] = useState<any>({
    groupedBookedTime: []
  });

  console.log(availability, 'availability');
  const expertCurrentDate = DateTime.now().setZone(availability.timeZone);
  console.log(expertCurrentDate, 'date');
  const [Token, setToken] = useState<any>(null);

  useEffect(() => {
    setToken(token);
  }, []);

  // fetchUserData(token)
  //   .then((res: any) => {})
  //   .catch((err) => {
  //     throw new Error(err?.response?.data?.message);
  //   });

  useEffect(() => {
    // let mounted = true;
    const fetchAccountData = async () => {
      try {
        const res = await fetchUserData(Token);
        setZone(res?.data?.data?.timezone);
        const weekDay = DateTime.now().setZone(
          res?.data?.data?.timezone
        ).weekday;
        setLearnerWeekDay((prev: any) => prev + weekDay);
      } catch (err: any) {
        throw new Error(err?.response?.data?.message);
      }
    };
    if (Token !== null) {
      fetchAccountData();
    }
  }, [Token]);
  const expertTimeZone = data?.profilePages?.availabilityPage?.timeZone;
  const learnerTimeZone = timeZone;
  const sessionStartDate = DateTime.fromISO(availability?.endDate, {
    zone: availability?.timeZone
  }).toString();
  const currentDate = DateTime.now().setZone(expertTimeZone).toString();

  const startDate = availability?.startDate;
  const endDate = availability?.endDate;
  const schedules = availability?.serviceScheduling;
  const [Schedules, SetSchedules] = useState<any>({
    monday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    tuesday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    wednesday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    thursday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    friday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    saturday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: timeZone
    },
    sunday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: timeZone
    }
  });
  const dateString = useCallback(
    (date: any, type = 'default') => {
      if (type === 'default') {
        return DateTime.fromISO(date, {
          zone: timeZone
        }).toFormat('hh:mm a');
      }
      if (type === 'add-minute') {
        return DateTime.fromISO(date, {
          zone: timeZone
        })
          .plus({ minutes: 1 })
          .toFormat('hh:mm a');
      }
    },
    [timeZone]
  );
  const getSessions = useCallback(async () => {
    try {
      let {
        data: { data }
      } = await getExpetBookedDays(id);
      let bookedTimeValues: any[] = [];
      if (data.length > 0) {
        data.forEach((element: any) => {
          let from = dateString(element?.fromDate);
          let to = dateString(element?.toDate, 'add-minute');
          let newElement = {
            from,
            to
          };
          let range = getTimeRange(newElement, 'from', 'to', 'time');
          let day = moment(element.fromDate).local().weekday();
          const date = DateTime.fromISO(element.fromDate)
            .setZone(timeZone)
            .toString();
          const weekDay = getWeekDay(day);
          bookedTimeValues = [
            ...bookedTimeValues,
            {
              day: weekDay,
              date: date.slice(0, 10),
              time: [...range]
            }
          ];
        });
      }
      let groupedBookedTimeValues = bookedTimeValues.reduce((r, a) => {
        const isoDate = a.date;
        if (!r[isoDate]) {
          r[isoDate] = [];
        }
        r[isoDate] = r[isoDate].concat(a.time);
        return r;
      }, {});
      setState((prev: any) => ({
        ...prev,
        groupedBookedTime: groupedBookedTimeValues
      }));
    } catch (error) {
      // apiErrorHandler(error)
    }
  }, [id, dateString, timeZone]);
  useEffect(() => {
    if (availability && Token !== null) {
      getSessions();
    }
  }, [getSessions, availability, Token]);

  const utcDateTimeString = (date: any, timeString: any, zone: string) => {
    const dateUTC = `${date} ${timeString}`;
    const luxonDateTime = DateTime.fromFormat(dateUTC, 'yyyy-MM-dd hh:mm a', {
      zone,
      setZone: true
    });
    const isoString = luxonDateTime.toISO();
    return isoString;
  };

  const DateByZone = (date: any, timeString: any, zone: any) => {
    const utcDateTime = utcDateTimeString(date, timeString, zone);
    return utcDateTime;
  };

  // const generateDate = (dateTime: any, timeString: any, timeZone: any) => {
  //   const targetDate = DateTime.fromISO(dateTime, { zone: timeZone });
  //   const time = DateTime.fromFormat(timeString, 'h:mm a');
  //   const combinedDateTime = targetDate.set({
  //     hour: time.hour,
  //     minute: time.minute,
  //     second: time.second,
  //     millisecond: time.millisecond
  //   });
  //   return combinedDateTime.toString();
  // };
  // const getConvertedTime = (
  //   sourceTime: any,
  //   sourceZone: any,
  //   targetZone: any
  // ) => {
  //   const originalDateTime = DateTime.fromISO(sourceTime, {
  //     zone: sourceZone
  //   });
  //   const targetTimeZone = targetZone;
  //   const convertedDateTime = originalDateTime.setZone(targetTimeZone);
  //   return {
  //     date: convertedDateTime.toString(),
  //     localTime: convertedDateTime.toFormat('hh:mm a')
  //   };
  // };

  useEffect(() => {
    if (schedules?.sunday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        7,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.sunday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.sunday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.sunday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.sunday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.sunday?.hours?.length;
      const to = schedules?.sunday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          sunday: {
            dates: [...dates],
            active: true,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            hrs: [...slots],
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.monday?.active === true) {
      console.log(startDate, endDate, 'date');
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        1,
        expertTimeZone
      );

      const slots: any = [];
      const timeToExclude: any = [];
      schedules.monday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.monday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.monday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.monday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.monday?.hours?.length;
      const to = schedules?.monday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);

      SetSchedules((prev: any) => {
        return {
          ...prev,
          monday: {
            dates: [...dates],
            active: true,
            expertSt: fromInAMPM,
            hrs: [...slots],
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }

    if (schedules?.tuesday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        2,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.tuesday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.tuesday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.tuesday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.tuesday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.tuesday?.hours?.length;
      const to = schedules?.tuesday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          tuesday: {
            dates: [...dates],
            hrs: [...slots],
            active: true,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }

    if (schedules?.wednesday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        3,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.wednesday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.thursday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.wednesday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.wednesday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.wednesday?.hours?.length;
      const to =
        schedules?.wednesday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          wednesday: {
            dates: [...dates],
            hrs: [...slots],
            active: true,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }

    if (schedules?.thursday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        4,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.thursday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.thursday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.thursday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.thursday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.thursday?.hours?.length;
      const to = schedules?.thursday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          thursday: {
            dates: [...dates],
            hrs: [...slots],
            active: true,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }

    if (schedules?.friday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        5,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.friday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.friday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.friday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.friday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.friday?.hours?.length;
      const to = schedules?.friday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);

      SetSchedules((prev: any) => {
        return {
          ...prev,
          friday: {
            dates: [...dates],
            hrs: [...slots],
            active: true,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }

    if (schedules?.saturday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        6,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.saturday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.saturday?.hours[i]?.to?.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.saturday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule?.to?.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      // schedules?.saturday?.hours[0]?.from?.toFixed(1)
      const from = schedules?.saturday?.hours[0]?.from.toFixed(1);
      const schedulesLength = schedules?.saturday?.hours?.length;
      const to = schedules?.saturday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          saturday: {
            dates: [...dates],
            active: true,
            expertSt: fromInAMPM,
            hrs: [...slots],
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
  }, [
    expertTimeZone,
    timeZone,
    schedules,
    endDate,
    learnerTimeZone,
    startDate
  ]);
  const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
    Schedules;

  useEffect(() => {
    const getData = () => {
      if (learnerCurrentWeekDay === 1) {
        console.log(monday, 'mondat');
        if (monday?.hrs?.length === 0) {
          setLearnerWeekDay(2);
        }
        const date = monday?.dates[0];
        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < monday?.hrs?.length; i++) {
          const expertDateTime = DateByZone(
            date?.slice(0, 10),
            monday?.hrs[i].time,
            expertTimeZone
          );
          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');

          availability.push({ learnerDate, time: learnerLocalTime });

          // const dateTime = generateDate(
          //   date,
          //   monday?.hrs[i].time,
          //   expertTimeZone
          // );
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);
          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === monday?.hrs?.length) {
              setLearnerWeekDay(2);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(2);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability?.length > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(2);
            }
          }
          if (availableTime.length > 0 && props.length === 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
        }
      }
      if (learnerCurrentWeekDay === 2) {
        if (tuesday?.hrs?.length === 0) {
          setLearnerWeekDay(3);
        }
        const date = tuesday?.dates[0];
        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < tuesday?.hrs?.length; i++) {
          const expertDateTime = DateByZone(
            date?.slice(0, 10),
            tuesday?.hrs[i].time,
            expertTimeZone
          );
          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');
          // const dateTime = generateDate(
          //   date,
          //   tuesday?.hrs[i].time,
          //   expertTimeZone
          // );
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);
          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === tuesday?.hrs?.length) {
              setLearnerWeekDay(3);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(3);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability?.length > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(3);
            }
          }
          if (availableTime.length > 0 && props.length === 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
        }
      }
      if (learnerCurrentWeekDay === 3) {
        if (wednesday?.hrs?.length === 0) {
          setLearnerWeekDay(4);
        }
        const date = wednesday?.dates[0];
        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < wednesday?.hrs?.length; i++) {
          const expertDateTime = DateByZone(
            date.slice(0, 10),
            wednesday?.hrs[i].time,
            expertTimeZone
          );
          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');

          // const dateTime = generateDate(
          //   date,
          //   wednesday?.hrs[i].time,
          //   expertTimeZone
          // );
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);
          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === wednesday?.hrs?.length) {
              setLearnerWeekDay(4);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(4);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability.lenght > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(4);
            }
          }
          if (availableTime.length > 0 && props.length === 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
        }
      }
      if (learnerCurrentWeekDay === 4) {
        if (thursday?.hrs?.length === 0) {
          setLearnerWeekDay(5);
        }
        const date = thursday?.dates[0];
        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < thursday?.hrs?.length; i++) {
          const expertDateTime = DateByZone(
            date.slice(0, 10),
            thursday?.hrs[i].time,
            expertTimeZone
          );
          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');

          // const dateTime = generateDate(
          //   date,
          //   thursday?.hrs[i].time,
          //   expertTimeZone
          // );
          // const info = DateByZone(
          //   date.slice(0, 10),
          //   friday?.hrs[i].time,
          //   expertTimeZone
          // );
          // console.log(info, 'data');
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);
          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === thursday?.hrs?.length) {
              setLearnerWeekDay(5);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(5);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(5);
            }
          }
          if (availableTime.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
        }
      }
      if (learnerCurrentWeekDay === 5) {
        if (friday?.hrs?.length === 0) {
          setLearnerWeekDay(6);
        }
        const date = friday?.dates[0];

        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < friday?.hrs?.length; i++) {
          const expertDateTime = DateByZone(
            date.slice(0, 10),
            friday?.hrs[i].time,
            expertTimeZone
          );
          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');

          const expertLocal = DateTime.fromISO(expertDateTime)
            .setZone(expertTimeZone)
            .toFormat('hh:mm a');

          // const dateTime = generateDate(
          //   date,
          //   friday?.hrs[i].time,
          //   expertTimeZone
          // );
          // const info = DateByZone(
          //   date.slice(0, 10),
          //   friday?.hrs[i].time,
          //   expertTimeZone
          // );
          // console.log(info, 'info_date', expertTimeZone);
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);
          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === friday?.hrs?.length) {
              setLearnerWeekDay(6);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(6);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(6);
            }
          }
          if (availableTime.length > 0 && props.length === 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
        }
      }
      if (learnerCurrentWeekDay === 6) {
        if (saturday.hrs?.length === 0) {
          setLearnerWeekDay(7);
        }
        const date = saturday?.dates[0];
        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < saturday?.hrs?.length; i++) {
          // const dateTime = generateDate(
          //   date,
          //   saturday?.hrs[i].time,
          //   expertTimeZone
          // );
          const expertDateTime = DateByZone(
            date?.slice(0, 10),
            saturday?.hrs[i].time,
            expertTimeZone
          );

          const expertLocal = DateTime.fromISO(expertDateTime)
            .setZone(expertTimeZone)
            .toFormat('hh:mm a');

          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');

          // console.log(saturday?.hrs[i].time, 'time');
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);

          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === saturday?.hrs?.length) {
              setLearnerWeekDay(7);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(7);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(7);
            }
          }
          if (availableTime.length > 0 && props.length === 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );

            setNextAvailable(dateTime[0]);
          }
        }
      }
      if (learnerCurrentWeekDay === 7) {
        if (sunday.hrs?.length === 0) {
          setLearnerWeekDay(1);
        }
        const date = sunday?.dates[0];
        const availability: any = [];
        let availableTime: any = [];
        const currentTime = DateTime.now().setZone(timeZone).toISO();
        for (let i = 0; i < sunday?.hrs?.length; i++) {
          const expertDateTime = DateByZone(
            date?.slice(0, 10),
            sunday?.hrs[i].time,
            expertTimeZone
          );
          const learnerDate = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toString();

          const learnerLocalTime = DateTime.fromISO(expertDateTime)
            .setZone(timeZone)
            .toFormat('hh:mm a');

          // const dateTime = generateDate(
          //   date,
          //   sunday?.hrs[i].time,
          //   expertTimeZone
          // );
          // const { date: learnerDate, localTime: learnerLocalTime } =
          //   getConvertedTime(dateTime, expertTimeZone, timeZone);
          availability.push({ learnerDate, time: learnerLocalTime });
        }
        const props = Object.keys(state.groupedBookedTime);
        if (availability.length > 0) {
          const currentAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() >= currentTime
          );
          const pastAvailability = availability.filter(
            (el: any) =>
              DateTime.fromISO(el.learnerDate).toString() < currentTime
          );
          const timeBooked = state.groupedBookedTime[
            date?.slice(0, 10)
          ]?.filter((el: any) =>
            currentAvailability.some((val: any) =>
              Object.values(val)?.includes(el)
            )
          );
          if (props.length === 0) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (pastAvailability?.length === sunday?.hrs?.length) {
              setLearnerWeekDay(1);
            }
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            }
          }
          if (
            props.length > 0 &&
            timeBooked?.length === currentAvailability?.length
          ) {
            setLearnerWeekDay(1);
          }
          if (
            props.length > 0 &&
            timeBooked?.length !== currentAvailability?.length
          ) {
            const currentAvailability = availability.filter(
              (el: any) =>
                DateTime.fromISO(el.learnerDate).toString() >= currentTime
            );
            if (currentAvailability.length > 0) {
              availableTime = [...currentAvailability];
            } else {
              setLearnerWeekDay(1);
            }
          }
          if (availableTime.length > 0 && props.length === 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
          if (availableTime.length > 0 && props.length > 0) {
            const dateTime = availableTime.map((el: any) =>
              DateTime.fromISO(el.learnerDate).toString()
            );
            setNextAvailable(dateTime[0]);
          }
        }
      }
    };
    getData();
  }, [
    learnerCurrentWeekDay,
    nextAvailable,
    expertTimeZone,
    saturday?.dates,
    saturday?.hrs,
    timeZone,
    monday?.dates,
    monday?.hrs,
    sunday?.dates,
    sunday?.hrs,
    thursday?.dates,
    friday?.hrs,
    friday?.dates,
    sunday,
    thursday?.hrs,
    tuesday?.hrs,
    wednesday?.dates,
    wednesday?.hrs,
    wednesday,
    tuesday?.dates,
    state.groupedBookedTime
  ]);
  const linkedin =
    data.profilePages?.professionalInformationPage?.linkedInProfileUrl;

  return (
    <div className='top'>
      <div className='first-half'></div>
      <Container>
        <Row>
          <Col md='12'>
            <div className='d-block d-md-flex align-items-center pb-3 border-bottom'>
              <div className='image-div mr-3'>
                <img
                  src={
                    data?.profilePages?.profileMediaPage?.profilePicture ||
                    require(`assets/images/pages/profile/user-avatar.png`)
                  }
                  className='img-fluid'
                  alt=''
                />
              </div>
              <div className='name-div'>
                <div className='d-flex align-items-center'>
                  <h2 className='mt-3 text-capitalize'>
                    {data?.profilePages?.registrationPage?.firstname ||
                      data?.profilePages?.registrationPage?.firstName}{' '}
                    {data?.profilePages?.registrationPage?.lastName}{' '}
                  </h2>
                  {linkedin && (
                    <a
                      target='_blank'
                      className='mt-3 h2 ml-3'
                      rel='noreferrer'
                      href={linkedin}>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  )}
                </div>
                <p className='m-0 font-weight-light'>
                  {data.profilePages?.professionalInformationPage?.jobTitle ||
                    'N/A'}
                </p>
              </div>
              {/* (window.location.href = `/auth/login?callback=$ */}
              {/* {window.location.pathname}`) */}
              <div className='ml-auto'>
                <div className='d-flex pt-3 mb-3 align-items-center'>
                  <Button
                    className='py-3'
                    disabled={
                      accountInfo.isAccountLinked === false ||
                      sessionStartDate < currentDate
                    }
                    onClick={() => {
                      if (!loggedInUser) {
                        window.location.href = `/auth/login`;
                      } else {
                        toggleModal();
                      }
                    }}
                    color='primary'>
                    Book 1:1 Session
                  </Button>
                </div>
                {nextAvailable && loggedInUser && (
                  <p className='text-left text-md-right mb-3 font-weight-light'>
                    <div>
                      {sessionStartDate >= currentDate && (
                        <span className='text-capitalize '>
                          Expert Earliest Date :{' '}
                          {DateTime.fromISO(nextAvailable).toFormat(
                            'MMMM dd, yyyy'
                          )}
                        </span>
                      )}
                    </div>
                  </p>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Top;
