import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import Logo from "./Logo";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/Reducers";
import { async_status } from "redux/Actions/types";
import { fetchUser } from "redux/Actions/userAction";
import { handleLogout } from "services/authService";

const Header = ({ toggler, logoType, ...props }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();

  const toggle = () => setIsOpen(!isOpen);

  const { user } = useSelector((state: RootState) => state);

  const loggedInUser = useMemo(
    () => user.status === async_status.__LOADED__,
    [user.status]
  );

  useEffect(() => {
    if (user.status === async_status.__DEFAULT__) {
      dispatch(fetchUser());
    }
  }, [user.status, dispatch]);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const getActiveClassName = (url: string) => {
    if (`${window.location.pathname}${window.location.hash}` === url) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <Navbar
      id="header"
      fixed="top"
      color="white"
      expand="lg"
      className="pt-3 px-4"
    >
      {/* <Container fluid> */}
      <Link className="nav-brand" to="/">
        <Logo
          logoType={logoType}
          style={{ height: "50px", maxWidth: "180px" }}
          className="img-fluid"
        />
      </Link>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto mt-md-0 mt-3 align-items-center pr-3" navbar>
          {[
            {
              name: "About Us",
              link: "/about",
            },
          ].map((route) => (
            <NavItem key={route.link}>
              <Link
                className={`${getActiveClassName(
                  `${route.link}`
                )} nav-link mx-3 my-md-0 my-2 text-darkBlue`}
                to={route.link}
              >
                {route.name}
              </Link>
            </NavItem>
          ))}
          {!loggedInUser &&
            [
              {
                name: "Find an Expert",
                link: "/search",
              },
              {
                name: "Become an Expert",
                link: "#",
                onclick: () =>
                  window.open(process.env.REACT_APP_EXPERT_APP_URL, "_self"),
              },
            ].map((route) => (
              <NavItem key={route.link}>
                <Link
                  onClick={route.onclick ? route.onclick : () => {}}
                  className={`${getActiveClassName(
                    `${route.link}`
                  )} nav-link mx-3 my-md-0 my-2 text-darkBlue`}
                  to={route.link}
                >
                  {route.name}
                </Link>
              </NavItem>
            ))}
          {loggedInUser &&
            [
              {
                name: "Dashboard",
                link: "/overview",
              },
              {
                name: "Profile",
                link: "/profile",
              },
            ].map((route) => (
              <NavItem key={route.link}>
                <Link
                  className={`${getActiveClassName(
                    `${route.link}`
                  )} nav-link mx-3 my-md-0 my-2 text-darkBlue`}
                  to={route.link}
                >
                  {route.name}
                </Link>
              </NavItem>
            ))}
          {!loggedInUser ? (
            <>
              <NavItem className={`${getActiveClassName("/auth/login")}`}>
                <Link
                  className="btn btn-light btn-block my-md-0 my-3 py-md-2 py-2 text-darkBlue"
                  to="/auth/login"
                >
                  Login
                </Link>
              </NavItem>
              <NavItem className={`${getActiveClassName("/")}`}>
                <Link
                  to="/auth/signup"
                  className=" btn btn-warning btn-block my-md-0 my-3 py-md-2 py-2 ml-0 ml-md-3"
                >
                  Sign Up
                </Link>
              </NavItem>
            </>
          ) : (
            <NavItem className={`${getActiveClassName("/")}`}>
              <button
                onClick={() => handleLogout("/")}
                className=" btn btn-warning btn-block my-md-0 my-3 py-md-2 py-2 ml-0 ml-md-3"
              >
                Logout
              </button>
            </NavItem>
          )}
        </Nav>
      </Collapse>
      {/* </Container> */}
    </Navbar>
  );
};

export default Header;
