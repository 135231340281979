import { getAllSubDomains, getDomains } from 'services/domainService';
import {
  DOMAIN_DATA_FAILED,
  DOMAIN_DATA_SUCCESS,
  DOMAIN_DATA_LOADING,
  SUB_DOMAIN_DATA_LOADING,
  SUB_DOMAIN_DATA_SUCCESS,
  SUB_DOMAIN_DATA_FAILED
} from './types';

export const fetchDomains = () => async (dispatch: any) => {
  dispatch({
    type: DOMAIN_DATA_LOADING,
    payload: {}
  });
  try {
    let { data } = await getDomains();
    dispatch({
      type: DOMAIN_DATA_SUCCESS,
      payload: { data: data }
    });
  } catch (e) {
    dispatch({
      type: DOMAIN_DATA_FAILED,
      payload: {}
    });
  }
};

export const fetchSubDomains = () => async (dispatch: any) => {
  dispatch({
    type: SUB_DOMAIN_DATA_LOADING,
    payload: {
      isLoading: true
    }
  });
  try {
    let { data } = await getAllSubDomains();
    dispatch({
      type: SUB_DOMAIN_DATA_SUCCESS,
      payload: { data: data, isLoading: false }
    });
  } catch (e) {
    dispatch({
      type: SUB_DOMAIN_DATA_FAILED,
      payload: { isLoading: false }
    });
  }
};
