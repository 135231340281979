import React from "react";

function BookingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M12.2 2V1a1 1 0 112 0v1.005A4 4 0 0118 6v9a4 4 0 01-4 4H4a4 4 0 01-4-4V6a4 4 0 014-4h.2V1a1 1 0 012 0v1h2V1a1 1 0 112 0v1h2zm0 2h-2a1 1 0 11-2 0h-2a1 1 0 11-2 0H4a2 2 0 00-2 2v9a2 2 0 002 2h10a2 2 0 002-2V6a2 2 0 00-1.8-1.99 1 1 0 01-2-.01zM6 10a1 1 0 110-2h7a1 1 0 110 2H6zm0 4a1 1 0 110-2h5a1 1 0 110 2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BookingsIcon;