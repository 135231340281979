import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import SendIcon from '../Icons/SendIcon'
import PerfectScrollbar from "react-perfect-scrollbar";
import ReadIcon from '../Icons/ReadIcon';
import UnReadIcon from '../Icons/UnreadIcon';
import UserAvatar from 'components/defaults/UserAvatar';

const messages = [
    {
        name: 'Ajagbe Opeyemi',
        isUser: false,
        text: `Hello Joe,
I will be unavailable at the scheduled time, However a session
can hold within tomorrow and nextfrom 4pm to 8pm. 
Please keep the feedbackloop going to reschedule a convenient
for thsis session.
        
Best Owolabi`,
        time: '10:09',
        read: true
    },
    {
        name: 'Ajagbe Opeyemi',
        isUser: true,
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Magna ac placerat vestibulum lectus mauris ultrices.`,
        time: '00:25',
        read: true
    },
    {
        name: 'Ajagbe Opeyemi',
        isUser: false,
        text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Magna ac placerat vestibulum lectus mauris ultrices.`,
        time: '00:25',
        read: false
    }
]
const Messaging = () => {
    return (
        <Container>
            <Row>
                <Col sm="12">
                    <div className="messaging-div my-4 p-3">
                        <div className="messages pb-3">
                            <PerfectScrollbar>
                                <div className="container">
                                    <div className="row">
                                        {messages.map((message, i) => (
                                            <div key={i} className={`col-md-6 ${message.isUser ? 'offset-md-0' : 'offset-md-6'} message-box`}>
                                                <div className="d-flex">
                                                    {message.isUser && <div className='avatar d-flex align-items-end mr-2'>
                                                        <UserAvatar />
                                                    </div>}
                                                    <div className={`${message.isUser ? 'user-div' : 'messager-div'} p-3`}>
                                                        <h5>{message.name}</h5>
                                                        <p className='font-weight-lighter'> {message.text} </p>
                                                        <p className="font-weight-lighter small text-right">{message.time}{message.read ?
                                                            <ReadIcon /> : <UnReadIcon />}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </PerfectScrollbar>
                        </div>
                        <div className="input-div">
                            <input type="text" placeholder='Type here...' />
                            <button>
                                <SendIcon />
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Messaging