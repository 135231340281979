import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
  return (
    <footer className='px-0 px-md-5 pb-2'>
      <Container fluid>
        <Row className='align-items-center'>
          <Col md='3' className='my-2'>
            <div className='d-flex justify-content-center align-items-top'>
              {[
                // {
                //     route: "Insights",
                //     // link: `${process.env.REACT_APP_EXPERT_APP_URL}insights`,
                //     link: `#`,
                //     isComingSoon: true
                // },
                {
                  route: 'FAQ',
                  link: '/faq'
                },
                {
                  route: 'Privacy',
                  link: '/privacy'
                },
                {
                  route: 'Terms',
                  // link: `${process.env.REACT_APP_EXPERT_APP_URL}terms`,
                  link: '/terms'
                }
              ].map((linkObj) => (
                <p
                  key={linkObj.route}
                  className='align-items-center justify-content-center mb-0'>
                  <a
                    className='mx-2 text-muted d-flex align-items-center'
                    href={linkObj.link}
                    key={linkObj.link}>
                    {linkObj.route}
                  </a>
                  {/* {linkObj.isComingSoon && <ComingSoonBadge />} */}
                </p>
              ))}
            </div>
          </Col>
          <Col md='6' className='text-center my-2'>
            <a href='mailto:hello@coachmie.com' className='text-muted'>
              {/* <img src={require(`assets/images/svg/icons/ic_mail.svg`).default} width="30" alt="mail" /> */}
              <i className='fas fa-envelope' aria-hidden='true'></i>
            </a>
            {[
              {
                image: 'linkedin',
                link: 'https://www.linkedin.com/company/coachmie-limited',
                className: 'fab fa-linkedin-in'
              },
              {
                image: 'twitter',
                link: 'https://twitter.com/coachmie_',
                className: 'fab fa-twitter'
              },
              {
                image: 'instagram',
                link: 'https://www.instagram.com/coachmie_/',
                className: 'fab fa-instagram'
              }
            ].map((linkObj) => (
              <a
                className='ml-1 text-muted'
                href={linkObj.link}
                key={linkObj.link}>
                <i className={linkObj.className} aria-hidden='true'></i>
                {/* <img src={require(`assets/images/svg/icons/ic-${linkObj.image}.svg`)} width="30" alt={linkObj.image} /> */}
              </a>
            ))}
          </Col>

          <Col md='3' className='text-center my-2 text-md-right'>
            <p
              className='pb-0 text-muted cursor-pointer'
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              }}>
              Coachmie, Inc
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
