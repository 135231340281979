import Buton from 'components/common/Button';
import Loader from 'components/common/Loader';
import React from 'react'
import { Card, CardBody } from 'reactstrap';

const NotificationsSwitch: React.FC<any> = ({ submitNotificationSettings, loading, submitting, notificationSettings, toggleNotification, notificationsArray }) => {
    return (
        <Card>
            <CardBody>
                {loading ? <Loader /> : <>
                    <h4 className='pb-2'>Select notifications you want to recieve</h4>
                    {notificationsArray.map((notification: Record<any, any>, i: number) => (

                        <div className="d-flex align-items-center my-2 pb-2" key={i}>
                            <p className="text-grayScale mb-0">{notification.text}</p>
                            <div className={`ml-auto switch ${notificationSettings[notification.parameter] ? 'active-switch' : ''}`} onClick={() => toggleNotification(`${notification.parameter}`)}>
                                <div></div>
                            </div>
                        </div>
                    ))}
                    <div className="text-right mt-2">
                        <Buton onClick={submitNotificationSettings} loading={submitting} color="primary" text="Submit" />
                    </div>
                </>}
            </CardBody>
        </Card>
    )
}

export default NotificationsSwitch;