import React from 'react'
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap'

const New = () => {
  return (
    <Container>
        <Row>
            <Col md="10">
                <Card>
                    <CardBody>
                        <h4 className='border-bottom pb-2'>Invite Friends</h4>
                        <p className='text-greyScaleLabel'>Use email to invite your friends to learn on coachmie</p>
                            <Row>
                                <Col md="10">
                                    <Input type='email' />
                                </Col>
                                <Col md="2">
                                    <Button block color='darkBlue'>Invite</Button>
                                </Col>
                            </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default New