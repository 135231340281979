import { apiErrorHandler, onChange } from "Helpers/utility";
import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { editUser } from "services/userService";
import { useDispatch } from "react-redux";
import { fetchUser } from "redux/Actions/userAction";
import { toast } from "react-toastify";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import Button from "components/common/Button";
import FormPassword from "./FormPassword";
import { changePassword, handleLogout } from "services/authService";
import { Input } from "reactstrap";
import { allTimeZones } from "Pages/Auth/Onboarding/SubComponents/Community/time_zone";
const Settings: React.FC<any> = ({ userInfo }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState<any>({
    formEdit: {
      about: "",
      company: "",
      firstname: "",
      industry: "",
      jobTitle: "",
      lastname: "",
      timezone: "",
      location: "",
      date: "",
      time: "",
    },
    formPassword: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    passwordLoading: false,
    timezoneLoading: false,
  });
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleChange = (e: any) => {
    onChange(e, setState);
  };

  const loadEditInfo = useCallback(() => {
    setState((prev: any) => ({
      ...prev,
      formEdit: {
        ...prev.formEdit,
        ...userInfo,
        timezone: userInfo.timezone
          ? userInfo.timezone
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: userInfo.language?.split(",") || [],
        domains: userInfo.domains?.split(",") || [],
        date: userInfo.dateTime ? moment(userInfo.dateTime).local() : "",
        time: userInfo.dateTime ? moment(userInfo.dateTime).local() : "",
      },
    }));
  }, [userInfo]);

  useEffect(() => {
    loadEditInfo();
  }, [loadEditInfo]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!state.formEdit.timezone || state.formEdit.timezone === "") {
      toast.error("Select Timezone");
    }
    if (state.formEdit.timezone !== userTimezone) {
      toast.error(` Note: Kindly ensure your computer or mobile device's
      timezone is the same as ${userTimezone}`);
    }
    // else if (state.formEdit.date === "") {
    //     toast.error("Select Date")
    // } else if (state.formEdit.time === "") {
    //     toast.error("Select Time")
    // }
    else {
      let dataToSend = {
        ...state.formEdit,
        // dateTime: `${moment(state.formEdit.date).local().format('YYYY-MM-DD')} ${moment(state.formEdit.time).local().format('h:mm:ss a')}`
        dateTime: `${moment().local().format("YYYY-MM-DD")} ${moment()
          .local()
          .format("h:mm:ss a")}`,
      };
      delete dataToSend.date;
      delete dataToSend.time;
      try {
        toggleLoading("timezoneLoading");
        let { data } = await editUser(dataToSend);
        dispatch(fetchUser(true));
        toast.success(data.message);
      } catch (error) {
        apiErrorHandler(error);
      } finally {
        toggleLoading("timezoneLoading");
      }
    }
  };

  const toggleLoading = (param: string) => {
    setState((prev: any) => ({
      ...prev,
      [param]: !prev[param],
    }));
  };

  const handlePasswordSubmit = async () => {
    let dataToSend = {
      ...state.formPassword,
      NewPassword: state.formPassword.password,
      userId: userInfo.userId,
    };
    try {
      await changePassword(dataToSend);
      handleLogout();
    } catch (error) {
      apiErrorHandler(error);
    }
  };
  return (
    <div className="my-4">
      <Container>
        <form
          className="row"
          name={"formEdit"}
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <Col lg="10">
            <Card>
              <CardBody>
                <h5>Timezone</h5>
                <p className="font-weight-lighter">
                  When you change timezone in your coachmie account, your
                  availability hours and scheduled calls adapt to the new time
                  zone. For example, a 10AM call PT changes to a 1PM ET call if
                  you travel from San Rancisco to New York. No matter where you
                  set your weekly availability, everyone will see it in theirown
                  time zone.
                </p>
                {/* {userTimezone !== state.formEdit.timezone ? (
                  <div className="px-2 py-1 text-muted">
                    <p className="m-0 pb-2"></p>
                  </div>
                ) : (
                  ""
                )} */}
                {/* <Input
                                    type='select'
                                    className='form-control'
                                    name="timezone"
                                    required={true}
                                    onChange={handleChange}
                                    value={state.formEdit.timezone}
                                >
                                    <option value="">Select Timezone</option>
                                    {tz.names().map((zone) => (
                                        <option value={zone} key={zone}>{zone}</option>
                                    ))}
                                </Input> */}
                <Input
                  type="select"
                  name="timezone"
                  className="form-control"
                  value={state.formEdit.timezone}
                  onChange={(e: any) =>
                    handleChange({
                      target: {
                        form: { name: "formEdit" },
                        name: "timezone",
                        value: e.value,
                      },
                    })
                  }
                >
                  <option>Select Time Zone</option>
                  {allTimeZones.map((timezone: any, id: any) => (
                    <option key={id} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </Input>

                {/* <TimezoneSelect
                                    value={state.formEdit.timezone}
                                    onChange={(e) => {
                                        handleChange({ target: { form: { name: "formEdit" }, name: "timezone", value: e.value } })
                                    }}
                                /> */}
                <div className="text-right mt-3">
                  <Button
                    loading={state.timezoneLoading}
                    role={"submit"}
                    onClick={handleSubmit}
                    type="submit"
                    color="darkBlue"
                    className="ml-auto"
                    text="Update"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="10" className="d-none">
            <Card className="my-4">
              <CardBody>
                <h5>Date {"&"} Time</h5>
                <form>
                  <Row>
                    <Col md="5">
                      {/* <Input
                                                type='select'
                                                className='form-control'
                                                required
                                                name="date"
                                                value={state.formEdit.date}
                                            >
                                                <option value="">(GMT-04:00) Toronto (04:01 PM)</option>
                                            </Input> */}
                      <DatePicker
                        className="form-control"
                        name="date"
                        aria-required={true}
                        value={state.formEdit.date}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              form: { name: "formEdit" },
                              name: "date",
                              value: e,
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col md="5">
                      {/* <Input
                                                type='select'
                                                className='form-control'
                                                required
                                                name="time"
                                                value={state.formEdit.time}
                                            >
                                                <option value="">(GMT-04:00) Toronto (04:01 PM)</option>
                                            </Input> */}
                      <TimePicker
                        className="form-control"
                        name="time"
                        aria-required={true}
                        value={state.formEdit.time}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              form: { name: "formEdit" },
                              name: "time",
                              value: e,
                            },
                          });
                        }}
                      />
                    </Col>
                    <Col md="2" className="d-block d-md-flex">
                      <Button
                        loading={state.timezoneLoading}
                        role={"submit"}
                        onClick={handleSubmit}
                        type="submit"
                        color="darkBlue"
                        className="ml-auto"
                        text="Update"
                      />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </form>
        <Row>
          <Col lg="10">
            <Card className="my-4">
              <CardBody>
                <h5>Change password</h5>
                <FormPassword
                  formControl={state.formPassword}
                  formName="formPassword"
                  onChange={handleChange}
                  onSubmit={handlePasswordSubmit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Settings;
