import axios from "./apiAdapter";

export const getTransactions = (PageSize = 20, PageNumber?: any) =>
  axios.get(`/transaction/user?perPage=${PageSize}&pageNo=${PageNumber}`);

export const getTransactionsStats = () => axios.get(`/transaction/user/stats`);

export const getTranxRecords = (sessionId: any, token: any) =>
  axios.get(`/transaction-record/${sessionId}`, {
    headers: { "x-auth-token": token },
  });
