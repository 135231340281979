import { getAllGroupSessions } from 'services/sessionService';
import {
  SESSION_DATA_FAILED,
  SESSION_DATA_SUCCESS,
  SESSION_DATA_LOADING
} from './types';

export const fetchGroupSessions = () => async (dispatch: any) => {
  dispatch({
    type: SESSION_DATA_LOADING,
    payload: {}
  });
  try {
    let { data } = await getAllGroupSessions(30, 1);
    dispatch({
      type: SESSION_DATA_SUCCESS,
      payload: { data: data.data?.sessions || data.data }
    });
  } catch (error) {
    dispatch({
      type: SESSION_DATA_FAILED,
      payload: {}
    });
  }
};
