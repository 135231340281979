import * as React from "react";
import { async_status } from "redux/Actions/types";

type ApiKeysType = {
    data: any,
    status: string
}

export const ApiKeysContext = React.createContext<any>({
    keys:{},
    setKeys: () => { }
});

export const ApiKeysProvider: React.FC<any> = ({ children }) => {
    const [keys, setKeys] = React.useState<ApiKeysType>({
        data: {},
        status: async_status.__DEFAULT__
    })

    return (
        <ApiKeysContext.Provider value={{ keys, setKeys }}>
            {children}
        </ApiKeysContext.Provider>
    )
} 