import React from 'react'

function GroupSessionIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <path
      fill="#A0A3BD"
      fillRule="evenodd"
      d="M11 6a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2zm-1 2h2a3 3 0 013 3v1a3 3 0 01-3 3h-2a3 3 0 01-3-3V9a3 3 0 013-3zm0 2a1 1 0 00-1 1v1a1 1 0 001 1h2a1 1 0 001-1V9a1 1 0 00-1-1h-2zm8 7a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2zm-1 2h2a3 3 0 013 3v1a3 3 0 01-3 3h-2a3 3 0 01-3-3v-1a3 3 0 013-3zm0 2a1 1 0 00-1 1v1a1 1 0 001 1h2a1 1 0 001-1v-1a1 1 0 00-1-1h-2zM4 15a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2zm-1 2h2a3 3 0 013 3v1a3 3 0 01-3 3H3a3 3 0 01-3-3v-1a3 3 0 013-3zm0 2a1 1 0 00-1 1v1a1 1 0 001 1h2a1 1 0 001-1v-1a1 1 0 00-1-1H3z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default GroupSessionIcon