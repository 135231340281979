import Loader from "components/common/Loader";
import Tabs from "components/common/Tabs";
import { apiErrorHandler } from "Helpers/utility";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { getSingleGroupSession } from "services/sessionService";
import Description from "./Components/Description";
import Messaging from "./Components/Messaging";
import CalenderIcon from "./Icons/CalenderIcon";
import SessionIcon from "./Icons/SessionIcon";
import TicketIcon from "./Icons/TicketIcon";

const MapSubComponents: React.FC<any> = ({ activeTab, state, ...props }) => {
  let componnents: Record<string, any> = {
    [`Description`]: <Description state={state} />,
    [`Message`]: <Messaging />,
  };

  return componnents[activeTab];
};
const BookingsDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState<any>({
    tabs: ["Description", "Message"],
    activeTab: "Description",
    loading: true,
    data: {},
  });

  const handleGoBack = useCallback(() => navigate(-1), [navigate]);

  const updateTab = (param: string) => {
    setState((prev: any) => ({
      ...prev,
      activeTab: param,
    }));
  };

  const fetchDetails = useCallback(async () => {
    try {
      let {
        data: { data },
      } = await getSingleGroupSession(id);
      setState((prev: any) => ({
        ...prev,
        loading: false,
        data: data,
      }));
    } catch (error) {
      apiErrorHandler(error);
      handleGoBack();
    }
  }, [id, handleGoBack]);
  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  return (
    <>
      <Container>
        <Row>
          <Col md="6">
            <h5 className="pb-2 px-0 page-header">Bookings</h5>
          </Col>
          <Col md="6" className="text-left text-md-right">
            <Button color="white" className=" py-2 px-4" onClick={handleGoBack}>
              {" "}
              {"<   "} Back
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {state.loading ? (
                  <div style={{ height: "50vh" }}>
                    {" "}
                    <Loader />{" "}
                  </div>
                ) : (
                  <>
                    <h3 className="border-bottom pb-2">{state.data?.title}</h3>
                    <Row>
                      <Col md="6" className="mb-3">
                        <img
                          src={
                            require("assets/images/svg/pages/bookings/bookings-image.svg")
                              .default
                          }
                          alt=""
                          className="bookings-img img-fluid w-100"
                        />
                      </Col>
                      <Col md="6" className="mb-3">
                        <div className="bookings-inner-border p-4 h-100 d-flex align-items-center">
                          <div>
                            <div className="d-flex">
                              <CalenderIcon />
                              <div className="ml-3">
                                <p className="mb-0 font-weight-lighter">
                                  {moment(state.data?.fromDate)
                                    .local()
                                    .format("MMMM Do YYYY, h:mm a")}
                                </p>
                                <p className="mb-0 font-weight-lighter">
                                  {moment(state.data?.toDate)
                                    .local()
                                    .format("MMMM Do YYYY, h:mm a")}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex my-3 align-items-center">
                              <SessionIcon />
                              <div className="ml-3">
                                <p className="mb-0 font-weight-lighter">
                                  {" "}
                                  1:1 One-off session
                                </p>
                              </div>
                            </div>
                            <div className="d-flex my-3 align-items-center">
                              <TicketIcon />
                              <div className="ml-3">
                                <p className="mb-0 font-weight-lighter">
                                  {" "}
                                  Ticket : ${state.data?.fee}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4 mb-3">
          <Col md="12">
            <Tabs
              className="border"
              tabs={state.tabs}
              activeTab={state.activeTab}
              onClickTab={updateTab}
            />
          </Col>
        </Row>
      </Container>
      <MapSubComponents activeTab={state.activeTab} state={state} />
    </>
  );
};

export default BookingsDetails;
