import axios from "./apiAdapter";

export const addUser = (data: any) => axios.post("/user", data);

export const editUser = (data: any) => axios.patch("/user", data);

export const addUserImage = (data: any) => axios.patch("/user/image", data);

export const addUserWishlist = (data: any) =>
  axios.post("/user/wishlist", data);

export const getExpertAccount = (id: any) =>
  axios.get(`/expert/${id}/isBankAccountLinked`);

export const fetchUserWishlist = (PageSize = 20, PageNumber?: any) =>
  axios.get(`/user/wishlist?perPage=${PageSize}&pageNo=${PageNumber}`);

export const fetchNotifications = (PageSize = 20, PageNumber?: any) =>
  axios.get(`/user/notification?perPage=${PageSize}&pageNo=${PageNumber}`);

export const sendNotificationSettings = (data: any) =>
  axios.put(`/user/notification/settings`, data);

export const fetchNotificationSettings = () =>
  axios.get(`/user/notification/settings`);

export const fetchReviews = (PageSize = 20, PageNumber?: any) =>
  axios.get(`/user/review?perPage=${PageSize}&pageNo=${PageNumber}`);

export const fetchExpertReviews = (id: any, PageSize = 20, PageNumber?: any) =>
  axios.get(`/user/review/${id}?perPage=${PageSize}&pageNo=${PageNumber}`);

export const addReview = (data: any, id: any) =>
  axios.post(`/user/review/${id}`, data);

export const fetchUserData = (token: any) =>
  axios.get("/user", {
    headers: {
      "x-auth-token": token,
    },
  });
