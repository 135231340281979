import axios from 'axios';
import { handleLogout } from './authService';

export const apiService = (service, additional = '', noAuth = false) => {
  let authInstance = axios.create({
    baseURL: process.env[service] + additional
  });
  if (service !== 'REACT_APP_BASE_AUTH_URL' && !noAuth) {
    authInstance.interceptors.request.use(
      (config) => {
        let token = localStorage.getItem('authToken');
        if (token) {
          //set interceptor token header
          setToken(config, token);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    authInstance.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      async (error) => {
        if (error?.response?.status === 401) {
          // logout
          // handleLogout()
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
  return authInstance;
};

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api/v1'
});

export function setToken(config, idToken = '') {
  if (idToken && idToken !== '') {
    config.headers.common['x-auth-token'] = `${idToken}`;
  }
}

AxiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('authToken');
    if (token) {
      //set interceptor token header
      setToken(config, token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      // logout
      handleLogout();
    } else {
      return Promise.reject(error);
    }
  }
);

// returns default axios config
export default apiService('REACT_APP_BASE_URL', '/api/v1');
export const adhocApi = process.env.REACT_APP_ADHOC_API;
export const serviceNoAuth = apiService('REACT_APP_BASE_URL', '/api/v1', false);
export const AuthAxiosInstance = apiService('REACT_APP_BASE_AUTH_URL', '/api');
export const setAccessToken = (token) => {
  localStorage.setItem('sessionId', token);
};
