import Navbar from 'components/defaults/Navbar/Navbar';
import Sidebar from 'components/defaults/Sidebar/Sidebar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'redux/Reducers';
import { AuthGuard } from 'routes/guards/AuthGuard';
import Footer from 'components/common/Footer';

export const DashboardLayout: React.FC<any> = ({ children, header }) => {
  const [toggled, setToggled] = useState(false);
  const toggleSidebar = () => setToggled((prev) => !prev);
  const location = useLocation();

  const {
    sidebar: { hidden }
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    setToggled(false);
  }, [location]);
  return (
    <AuthGuard>
      <div>
        {!hidden && <Sidebar toggled={toggled} toggleSidebar={toggleSidebar} />}
        <main
          id='main'
          className={`${
            (toggled || hidden) && 'main-toggled'
          } pt-md-3 pt-0 px-3 px-md-5`}>
          {!hidden && (
            <Navbar
              header={header}
              toggled={toggled}
              toggleSidebar={toggleSidebar}
            />
          )}
          <div className='px-0 py-5'>{children}</div>
          <Footer />
        </main>
      </div>
    </AuthGuard>
  );
};
