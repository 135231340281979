import { apiErrorHandler } from 'Helpers/utility'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Container, Row } from 'reactstrap'
import { fetchNotifications, fetchNotificationSettings, sendNotificationSettings } from 'services/userService'
import Activity from './Components/Activity'
import NotificationsSwitch from './Components/Notifications'
import Verify from './Components/Verify'


type StateType = {
    activity: Record<any, any>[],
    pageSize: number,
    pageNo: number,
    hasMore: boolean,
    loadingActivity: boolean,
}

const Notifications = () => {
    const [state, setState] = useState<StateType>({
        activity: [],
        pageSize: 20,
        pageNo: 1,
        hasMore: false,
        loadingActivity: true

    })

    const [settingsState, setSettingsState] = useState({
        loadingNotiifications: true,
        submittingNotiifications: false,
        notificationSettings: {
            newSession: true,
            cancelSession: true,
            rescheduleSession: true,
            rescheduleEvents: true,
            minitueBefore: true,
            hourBefore: true,
        },
        notificationsArray: [
            {
                text: 'New Session is booked',
                parameter: 'newSession'
            }, {
                text: 'Session is cancelled',
                parameter: 'cancelSession'
            }, {
                text: 'Session is rescheduled',
                parameter: 'rescheduleSession'
            }, {
                text: 'Event is rescheduled',
                parameter: 'rescheduleEvents'
            }, {
                text: '15 minutes before session or event',
                parameter: 'minitueBefore'
            }, {
                text: '1 hours before session or event',
                parameter: 'hourBefore'
            },
        ]
    })
    const toggleLoading = (param: string | number, seateFn: any = setState) => {
        seateFn((prev: any) => ({
            ...prev,
            [param]: !prev[param]
        }))
    }

    const submitNotificationSettings = async () => {
        toggleLoading("submittingNotiifications", setSettingsState)
        const { notificationSettings } = settingsState
        try {
            let { data } = await sendNotificationSettings(notificationSettings)
            toast.success(data.message || "Successful")
        } catch (error) {
            apiErrorHandler(error);
        } finally {
            toggleLoading("submittingNotiifications", setSettingsState)
        }
    }

    const getNotificationSettings = useCallback(async () => {
        try {
            let { data } = await fetchNotificationSettings()
            setSettingsState((prev) => ({
                ...prev,
                loadingNotiifications: false,
                notificationSettings: {
                    ...prev.notificationSettings,
                    newSession: data.data.newSession,
                    cancelSession: data.data.cancelSession,
                    rescheduleSession: data.data.rescheduleSession,
                    rescheduleEvents: data.data.rescheduleEvents,
                    minitueBefore: data.data.minitueBefore,
                    hourBefore: data.data.hourBefore,
                }
            }))
        } catch (error) {
            apiErrorHandler(error);
            toggleLoading("loadingNotiifications", setSettingsState)
        }
    }, [])

    const toggleNotification = (param: string) => {
        setSettingsState((prev: any) => ({
            ...prev,
            notificationSettings: {
                ...prev.notificationSettings,
                [param]: !prev.notificationSettings[param]
            }
        }))
    }

    const loadNotifications = useCallback(async (pageNo = 1) => {
        pageNo > 1 && toggleLoading("loadingActivity");
        try {
            let { data: { data } } = await fetchNotifications(state.pageSize, pageNo)
            setState((prev) => ({
                ...prev,
                activity: data.notifications,
                pageNo: pageNo,
                totalRecord: data.count,
                hasMore: ((pageNo * state.pageSize) < data.count),
                loadingActivity: false
            }))

        } catch (error) {
            apiErrorHandler(error);
            pageNo > 1 && toggleLoading("loadingActivity");

        }
    }, [state.pageSize])

    useEffect(() => {
        getNotificationSettings()
    }, [getNotificationSettings])

    useEffect(() => {
        loadNotifications(1)
    }, [loadNotifications])
    return (
        <Container id="notification">
            <Row>
                <Col md="7" className='mb-2'>
                    <Activity loading={settingsState.loadingNotiifications} data={state.activity} />
                </Col>
                <Col md="4">
                    <Verify />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col md="7">
                    <NotificationsSwitch submitNotificationSettings={submitNotificationSettings} submitting={settingsState.submittingNotiifications} loading={settingsState.loadingNotiifications} notificationsArray={settingsState.notificationsArray} notificationSettings={settingsState.notificationSettings} toggleNotification={toggleNotification} />
                </Col>
            </Row>
        </Container>
    )
}

export default Notifications