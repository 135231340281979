import React from 'react'
import { Card, CardBody } from 'reactstrap'
import PerfectScrollBar from 'react-perfect-scrollbar'
import moment from "moment";
import Loader from 'components/common/Loader';

const Activity: React.FC<any> = ({ data, loading }) => {
    return (
        <Card className='activity pb-5'>
            <CardBody className=' py-4'>
                <h4>Activity</h4>
                {loading && <Loader />}
                {!loading && <>
                    <div className="data-div">
                        {data.length === 0 && <div className={`d-flex`}>
                            <p>No Notifications</p>
                        </div>}
                        <PerfectScrollBar>

                            {data.map((notification: Record<any, any>, i: number) => (
                                <div className={`d-flex ${i % 2 !== 0 ? 'text-grayScalePlaceholder' : 'text-black'}`} key={i}>
                                    <p>
                                        {notification.note}
                                    </p>
                                    <p className='ml-auto small'>
                                        {moment(notification.createdAt).local().format('MMMM Do YYYY, h:mm:ss a')}
                                    </p>
                                </div>
                            ))}
                        </PerfectScrollBar>
                    </div>
                </>}
            </CardBody>
        </Card>
    )
}

export default Activity