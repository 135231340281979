import React from 'react'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'

const Sent = () => {
  return (
    <Container>
        <Row>
            <Col md="8">
                <Card className='table-card'>
                    <CardBody>
                        <h4 className='border-bottom pb-2'>Invitations</h4>
                        <Table>
                            <thead>

                            </thead>
                            <tbody>
                                {[{
                                    date: '20, Aug',
                                    email:'abiondunjohn@gmail.com',
                                    status: 'Pending'
                                },{
                                    date: '20, Aug',
                                    email:'abiondunjohn@gmail.com',
                                    status: 'Pending'
                                },{
                                    date: '20, Aug',
                                    email:'abiondunjohn@gmail.com',
                                    status: 'Pending'
                                },{
                                    date: '20, Aug',
                                    email:'abiondunjohn@gmail.com',
                                    status: 'successful'
                                },{
                                    date: '20, Aug',
                                    email:'abiondunjohn@gmail.com',
                                    status: 'successful'
                                },{
                                    date: '20, Aug',
                                    email:'abiondunjohn@gmail.com',
                                    status: 'successful'
                                }].map((item, i)=>(
                                <tr key={i}>
                                    <td className='text-greyScaleLabel'>
                                        {item.date}
                                    </td>
                                    <td className='text-greyScaleLabel'>
                                        {item.email}
                                    </td>
                                    <td>
                                        <div className={`${item.status === "Pending"? 'bg-pending' : 'bg-full'} text-center`}>
                                        {item.status}
                                        </div>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default Sent