import axios, { AuthAxiosInstance, adhocApi } from './apiAdapter';
export const getAllExperts = (PageSize: any, PageNumber?: any, search?: any) =>
  axios.get(`${adhocApi}/expert${search ? `/search?domain=${search}` : ''}`);
// AuthAxiosInstance.post(
//     `/v1-5/register/get-all-registered-expert?PageSize=${PageSize}&PageNumber=${PageNumber}${
//       search ? `&Search=${search}` : ""
//     }`
//   );
export const getSingleExpert = (id: any) =>
  AuthAxiosInstance.post(`/v1/register/get-registered-expert?ExpertId=${id}`);

export const getExpertBankAccountInfo = (id: any) =>
  axios.get(`/expert/${id}/isBankAccountLinked`);
export const getExpertRating = (expertId: any) =>
  axios.get(`/avgRating/${expertId}`);
