import * as types from "../Actions/types";

const initialState = {
  hidden: false
};

const sidebarReducer = (state = initialState, action: types.Actonsnterface) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        hidden: action.payload.data,
      };
      default:
      return state;
  }
};

export default sidebarReducer;
