import Logo from 'components/common/Logo';
import Main from 'layout/Main';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { fetchCountries } from 'redux/Actions/countryAction';
import { fetchSubDomains } from 'redux/Actions/domainAction';
import { async_status } from 'redux/Actions/types';
import { RootState } from 'redux/Reducers';
import { Community } from './SubComponents/Community';

export interface ItemmInnterface {
  name: String;
  text: String;
}
let componentsArray = [
  { name: 'community', text: 'Join the community' },
  { name: 'personal', text: 'Personal info' },
  { name: 'professional', text: 'Professional Info' },
  { name: 'interests', text: 'Personal interests' }
];
const MapSubComponents: React.FC<any> = ({ activeTab, ...props }) => {
  console.log(activeTab, 'active_tab');

  let componnents = {
    [`${componentsArray[0].name}`]: <Community {...props} />
  };
  return componnents[`${activeTab}`] || <></>;
};
const Onboarding = () => {
  const dispatch = useDispatch();
  const { user, countries, domains } = useSelector((state: RootState) => {
    return state;
  });
  const [state, setState] = useState({
    components: componentsArray,
    activeTab: componentsArray[0].name,
    userDetails: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  });

  console.log(state.activeTab, 'tab');

  const updateTab = useCallback(
    (tab: string, userDetails?: any | undefined) => {
      setState((prev) => ({
        ...prev,
        activeTab: tab,
        userDetails: userDetails ? userDetails : prev.userDetails
      }));
    },
    [setState]
  );

  useEffect(() => {
    if (countries.status === async_status.__DEFAULT__) {
      dispatch(fetchCountries());
    }

    // if (user.status === async_status.__DEFAULT__) {
    //     dispatch(fetchUser())
    // } else if (user.status === async_status.__LOADED__) {
    //     // let { data: { data } } = user
    //     // if (data?.regLevel === "personalInfo") {
    //     //     updateTab("personal");
    //     // } else if (data?.regLevel === "professionalInfo") {
    //     //     updateTab("professional");
    //     // } else if (data?.regLevel === "personalInterest") {
    //     //     // updateTab("interests");
    //     //     updateTab("professional");

    //     // } else {
    //     //     updateTab("personal");
    //     // }
    // }
  }, [user.status, countries.status, dispatch, updateTab, user]);

  useEffect(() => {
    if (domains.subDomainStatus !== async_status.__LOADED__) {
      dispatch(fetchSubDomains());
    }
  }, [dispatch, domains.subDomainStatus]);

  // if (user.status === async_status.__LOADING__)
  //     return (
  //         <div className="vh-100">
  //             <Loader />
  //         </div>
  //     )
  return (
    <div id='signup'>
      {/* <Sidebar items={state.components} activeTabIndex={componentsArray.findIndex(el => el.name === state.activeTab)} activeTab={state.activeTab} /> */}
      <Main>
        <div className='d-flex align-items-center minh-100'>
          <Container>
            <Row>
              <Col md='10' lg='6' className='m-auto'>
                <div className='text-center d-block mb-3 mt-5'>
                  <Link to='/' className='text-center mb-4'>
                    <Logo />
                  </Link>
                </div>
                {/* <Card> */}
                {/* <CardBody> */}
                <MapSubComponents
                  activeTab={state.activeTab}
                  updateTab={updateTab}
                  userDetails={state.userDetails}
                />
                {/* </CardBody> */}
                {/* </Card> */}
              </Col>
            </Row>
          </Container>
        </div>
      </Main>
    </div>
  );
};

export default Onboarding;
