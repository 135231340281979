import ExpertCards from 'components/common/ExpertCards';
import GroupCards from 'components/common/GroupCards';
import Loader from 'components/common/Loader';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { fetchExperts } from 'redux/Actions/expertAction';
import { fetchGroupSessions } from 'redux/Actions/sessionAction';
import { async_status } from 'redux/Actions/types';
import { RootState } from 'redux/Reducers';

export default function CardScroller() {
  const { experts, sessions } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      experts.status === async_status.__DEFAULT__ ||
      experts.status === async_status.__FAILED__
    ) {
      dispatch(fetchExperts());
    }
  }, [dispatch, experts.status]);

  useEffect(() => {
    if (
      sessions.status === async_status.__DEFAULT__ ||
      sessions.status === async_status.__FAILED__
    ) {
      dispatch(fetchGroupSessions());
    }
  }, [dispatch, sessions.status]);

  var settings = {
    draggable: true,
    dots: true,
    autoplay: false /* this is the new line */,
    autoplaySpeed: 500,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 3
        }
      },
      // {
      //   breakpoint: 700,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 1,
      //     initialSlide: 2
      //   }
      // },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    // centerMode: true,
    // touchThreshold: 1000,
  };

  return (
    <div className='container-fluid'>
      <Row className='px-0'>
        <Col>
          <div className='continent-div p-5' style={{ borderRadius: '27px' }}>
            <div className='my-5'>
              {/* <h1 className='landing-mid-header'>Learn from the world’s best</h1> */}
              <h1 className='landing-mid-header'>
                Learn from the world's best
                {/* Promoted experts for 1:1 sessions */}
              </h1>
              {experts.status === async_status.__LOADING__ ? (
                <Loader />
              ) : (
                <ExpertCards
                  cardInfo={experts.data}
                  settings={{
                    ...settings,
                    slidesToShow:
                      experts?.data?.length > 4 ? 4 : experts?.data?.length
                  }}
                />
              )}
            </div>
            {sessions?.data?.length > 0 && (
              <div className='my-5'>
                <h1 className='text-darkBlue font-weight-bolder mb-4'>
                  Join Group sessions
                </h1>
                <GroupCards
                  sessionInfo={sessions.data}
                  settings={{
                    ...settings,
                    slidesToShow:
                      sessions?.data?.length > 4 ? 4 : sessions?.data?.length
                  }}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
