import React, { useState } from 'react';
import style from './Accordion.module.css';
import { RiArrowDropDownLine as DropdownIcon } from 'react-icons/ri';

type propsType = {
  question: string;
  answer: string;
  isOpened: boolean;
};
const Accordion = (props: any) => {
  const [state, setState] = useState<propsType[]>(props.questions);
  const handleClick = (index: number) => {
    const updatedQuestions = state.map((el, idx: number) => {
      if (idx === index) {
        return { ...el, isOpened: !el.isOpened };
      } else {
        return { ...el, isOpened: false };
      }
    });
    setState(updatedQuestions);
  };

  return (
    <div className={style.container}>
      <div className={style.accordion}>
        {props?.questions?.map((question: any, key: any) => (
          <div key={key} className={style.accordion_content}>
            <div className={style.accordion_header} onClick={() => {}}>
              <h2
                onClick={() => {
                  handleClick(key);
                }}
                className={style.question_text}>
                {question.question}
              </h2>
              <DropdownIcon
                className={
                  state[key].isOpened
                    ? style.dropdown_active
                    : style.dropdown_icon
                }
                onClick={() => handleClick(key)}
              />
            </div>
            {state[key].isOpened && (
              <div onClick={() => handleClick(key)}>
                <span className={style.answer}>{question.answer}</span>
                <ul className={style.sub_answers}>
                  {question?.list?.map((list: string, key: string) => (
                    <li>{list}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
