import React, { useCallback, useContext, useEffect } from 'react';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import { getApiKeys } from 'services/sessionService';
import { ApiKeysContext } from 'Contexts/ApiKeysContext';
import { async_status } from 'redux/Actions/types';
import { useLocation } from 'react-router-dom';

const BaseLayout: React.FC<any> = ({ children, hideFooter }) => {
  const {
    keys: { status: apiKeysStatus },
    setKeys
  } = useContext(ApiKeysContext);
  const location = useLocation();

  const fetchApiKeys = useCallback(async () => {
    try {
      const {
        data: { message }
      } = await getApiKeys();
      setKeys((prev: any) => ({
        ...prev,
        data: message,
        status: async_status.__LOADED__
      }));
    } catch (error) {
      setKeys((prev: any) => ({
        ...prev,
        status: async_status.__FAILED__
      }));
    }
  }, [setKeys]);
  useEffect(() => {
    if (apiKeysStatus !== async_status.__LOADED__) {
      fetchApiKeys();
    }
  }, [apiKeysStatus, fetchApiKeys, location]);

  return (
    <>
      <Header />
      {children}
      {!hideFooter && <Footer />}
    </>
  );
};

export default BaseLayout;
