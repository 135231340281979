import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Table } from 'reactstrap';
import InfiniteScroll from './InfiniteScroll';

const SessionViewTable: React.FC<any> = ({
  header,
  tableData,
  state,
  loadMore
}) => {
  const navigate = useNavigate();
  return (
    <Card className='table-card'>
      <CardBody>
        <InfiniteScroll
          dataLength={state.data.length}
          next={loadMore}
          hasMore={state.hasMore}>
          <h3>{header}</h3>
          <hr />
          <Table responsive striped={false}>
            <thead>
              <tr>
                {['Dates', 'Name', 'Time', 'Expert'].map((head) => (
                  <td className='text-grayScale'>{head}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((data: any) => (
                <tr
                  key={data.sessionId}
                  onClick={() => navigate(`/group-sessions/${data.sessionId}`)}
                  className='cursor-pointer'>
                  <td className='text-muted'>
                    {moment(data.Session.fromDate)
                      .local()
                      .format('MMMM Do, YYYY')}
                  </td>
                  <td className='text-muted'>{data.Session?.title}</td>
                  <td className='text-muted'>
                    {moment(data.Session.fromDate).local().format('h:mm a')}
                  </td>
                  <td className='text-muted'>
                    {data?.expert?.profilePages?.registrationPage?.firstname ||
                      data?.expert?.profilePages?.registrationPage
                        ?.firstName}{' '}
                    {data?.expert?.profilePages?.registrationPage?.lastname ||
                      data?.expert?.profilePages?.registrationPage?.lastName}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </CardBody>
    </Card>
  );
};

export default SessionViewTable;
