import React from "react";

function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3.5h12c.825 0 1.5.675 1.5 1.5v9c0 .825-.675 1.5-1.5 1.5H3c-.825 0-1.5-.675-1.5-1.5V5c0-.825.675-1.5 1.5-1.5z"
      ></path>
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 5L9 10.25 1.5 5"
      ></path>
    </svg>
  );
}

export default EmailIcon;
