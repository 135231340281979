export const USER_DATA_LOADING = "USER_DATA_LOADING"
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS"
export const USER_DATA_FAILED = "USER_DATA_FAILED"


export const COUNTRY_DATA_LOADING = "COUNTRY_DATA_LOADING"
export const COUNTRY_DATA_SUCCESS = "COUNTRY_DATA_SUCCESS"
export const COUNTRY_DATA_FAILED = "COUNTRY_DATA_FAILED"


export const EXPERT_DATA_LOADING = "EXPERT_DATA_LOADING"
export const EXPERT_DATA_SUCCESS = "EXPERT_DATA_SUCCESS"
export const EXPERT_DATA_FAILED = "EXPERT_DATA_FAILED"

export const SESSION_DATA_LOADING = "SESSION_DATA_LOADING"
export const SESSION_DATA_SUCCESS = "SESSION_DATA_SUCCESS"
export const SESSION_DATA_FAILED = "SESSION_DATA_FAILED"

export const DOMAIN_DATA_LOADING = "DOMAIN_DATA_LOADING"
export const DOMAIN_DATA_SUCCESS = "DOMAIN_DATA_SUCCESS"
export const DOMAIN_DATA_FAILED = "DOMAIN_DATA_FAILED"

export const SUB_DOMAIN_DATA_LOADING = "SUB_DOMAIN_DATA_LOADING"
export const SUB_DOMAIN_DATA_SUCCESS = "SUB_DOMAIN_DATA_SUCCESS"
export const SUB_DOMAIN_DATA_FAILED = "SUB_DOMAIN_DATA_FAILED"

export const WISHLIST_DATA_LOADING = "WISHLIST_DATA_LOADING"
export const WISHLIST_DATA_SUCCESS = "WISHLIST_DATA_SUCCESS"
export const WISHLIST_DATA_FAILED = "WISHLIST_DATA_FAILED"

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"


export const async_status = {
  __DEFAULT__: 'async__DEFAULT__',
  __LOADING__: 'async__LOADING__',
  __LOADED__: 'async__LOADED__',
  __FAILED__: 'async__FAILED__',
};


export interface Actonsnterface {
  type: any,
  payload: {
    status: 'async__DEFAULT__' | 'async__LOADING__' | 'async__LOADED__' | 'async__FAILED__',
    data: any
  }
}