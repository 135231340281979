import React from "react";

function OverviewIcon() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#A0A3BD"
      fillRule="evenodd"
      d="M5 10H4a4 4 0 00-4 4v2a4 4 0 004 4h1a4 4 0 004-4v-2a4 4 0 00-4-4zm-3 4a2 2 0 012-2h1a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zM7 3.5a3.5 3.5 0 10-7 0v1a3.5 3.5 0 107 0v-1zm-5 0a1.5 1.5 0 113 0v1a1.5 1.5 0 11-3 0v-1zM16 9h-1a4 4 0 00-4 4v3a4 4 0 004 4h1a4 4 0 004-4v-3a4 4 0 00-4-4zm-3 4a2 2 0 012-2h1a2 2 0 012 2v3a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3zM16.5 0h-4a3.5 3.5 0 100 7h4a3.5 3.5 0 100-7zM11 3.5A1.5 1.5 0 0112.5 2h4a1.5 1.5 0 010 3h-4A1.5 1.5 0 0111 3.5z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default OverviewIcon;