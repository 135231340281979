import Tabs from "components/common/Tabs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { fetchCountries } from "redux/Actions/countryAction";
import { async_status } from "redux/Actions/types";
import { RootState } from "redux/Reducers";
import Details from "./Components/Details";
import Settings from "./Components/Settings";

const MapSubComponents: React.FC<{ activeTab: string; userInfo: any }> = ({
  activeTab,
  ...props
}) => {
  let componnents: Record<string, any> = {
    [`Profile Details`]: <Details {...props} />,
    [`Settings`]: <Settings {...props} />,
  };

  return componnents[activeTab];
};

const Profile: React.FC<any> = () => {
  const {
    user: {
      data: { data: user },
    },
    countries,
  } = useSelector((state: RootState) => state);
  const [state, setState] = useState({
    tabs: ["Profile Details", "Settings"],
    activeTab: "Profile Details",
  });

  const dispatch = useDispatch();

  const toggleTab = (tab: string) => {
    setState((prev) => ({
      ...prev,
      activeTab: tab,
    }));
  };

  useEffect(() => {
    if (countries.status === async_status.__DEFAULT__) {
      dispatch(fetchCountries());
    }
  }, [countries.status, dispatch]);
  return (
    <div id="profile">
      <Container>
        <Row>
          <Col md="12">
            <Tabs
              activeTab={state.activeTab}
              tabs={state.tabs}
              onClickTab={toggleTab}
            />
          </Col>
        </Row>
      </Container>
      <MapSubComponents
        activeTab={state.activeTab}
        userInfo={{ ...user, countries: countries.data }}
      />
    </div>
  );
};

export default Profile;
