import { GroupCard } from "components/common/GroupCards";
import InfiniteScroll from "components/common/InfiniteScroll";
import Loader from "components/common/Loader";
import { apiErrorHandler } from "Helpers/utility";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { getAllGroupSessions } from "services/sessionService";

const Sessions: React.FC<any> = ({ col, search }) => {
  const [state, setState] = useState({
    sessions: [],
    pageNo: 0,
    pageSize: 20,
    hasMore: false,
    totalRecord: 0,
    loading: false,
  });

  const toggleLoading = () => {
    setState((prev) => ({
      ...prev,
      loading: !prev.loading,
    }));
  };
  const loadSessions = useCallback(
    async (pageNo = 1, searchParam = null) => {
      pageNo === 1 && toggleLoading();
      try {
        let { data } = await getAllGroupSessions(
          state.pageSize,
          pageNo,
          searchParam
        );
        setState((prev) => ({
          ...prev,
          sessions:
            pageNo > 1
              ? prev.sessions.concat(data.data.sessions)
              : data.data.sessions,
          pageNo: data.data.pageNo,
          totalRecord: data.data.totalRecord,
          hasMore: pageNo * state.pageSize < data.data.totalRecord,
          loading: false,
        }));
      } catch (error) {
        apiErrorHandler(error);
        pageNo === 1 && toggleLoading();
      }
    },
    [state.pageSize]
  );

  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    setState({
      sessions: [],
      pageNo: 0,
      pageSize: 20,
      hasMore: false,
      totalRecord: 0,
      loading: false,
    });
    loadSessions(1, search?.trim() !== "" ? search : null);
    // }, 3000)

    // return () => clearTimeout(delayDebounceFn)
  }, [search, loadSessions]);

  // useEffect(() => {
  //     loadSessions(1)
  // }, [loadSessions])
  return (
    <div>
      <Container>
        <Row>
          <Col sm="12">
            <div>
              <h5 className="text-grayScale my-3">Join group sessions</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className="expert-group-cards"
        id="scrollTarget"
        style={{ height: "90%", overflow: "auto" }}
      >
        {state.loading && <Loader />}
        {!state.loading && state.sessions.length === 0 && (
          <h3 className="text-center">No Data</h3>
        )}
        <InfiniteScroll
          dataLength={state.sessions.length}
          next={() =>
            loadSessions(state.pageNo + 1, search.trim() !== "" ? search : null)
          }
          hasMore={state.hasMore}
        >
          <Container>
            <Row>
              {state.sessions?.map((info, i) => (
                <Col {...col} className="my-3" key={i}>
                  <GroupCard data={info} />
                </Col>
              ))}
            </Row>
          </Container>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Sessions;
