import CustomModal from "components/common/CustomModal";
import React, { FormEvent, useState } from "react";
import { Col, Container, Input, Row } from "reactstrap";
import StarRatings from "react-star-ratings";
import Button from "components/common/Button";
import { apiErrorHandler } from "Helpers/utility";
import { addReview } from "services/userService";
import { toast } from "react-toastify";

const AddReview: React.FC<any> = ({ meetingData, id }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleModal = () => setShowModal((prev) => !prev);
  const [state, setState] = useState({
    formRating: {
      stars: 0,
      highlights: "",
      recomend: "yes",
    },
  });

  const handleChange = (e: any) => {
    setState((prev) => ({
      ...prev,
      formRating: {
        ...prev.formRating,
        [e.target.name]: e.target.value,
      },
    }));
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (state.formRating.highlights.trim() === "") {
      toast.error("Kindly Enter your highlights");
      return;
    }
    if (state.formRating.stars < 1) {
      toast.error("Select atleast one star rating");
      return;
    }
    try {
      setLoading(true);
      await addReview(
        {
          review: state.formRating.highlights,
          rating: state.formRating.stars,
          recommend: state.formRating.recomend === "yes",
          sessionId: id,
        },
        meetingData?.expertId
      );

      window.location.href = "/review";
    } catch (error) {
      apiErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          <h3>Reviews</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>No Reviews Posted</h5>
          <div className="session-ended d-flex align-items-center">
            <div>
              <h4>Successful Session</h4>
              <p>
                A successful session just ended tell us about your learning
                experience.
              </p>
              <Button
                onClick={toggleModal}
                color="darkBlue"
                className="text-white"
                text="Write review"
              ></Button>
            </div>
          </div>
        </Col>
      </Row>
      <CustomModal
        isOpen={showModal}
        toggle={toggleModal}
        header="Review Session"
        keyboard={false}
      >
        <form name="formRating" className="form-review" onSubmit={handleSubmit}>
          <div>
            <p className="mb-0">How would you rate your experience?</p>
            <StarRatings
              rating={state.formRating.stars}
              starRatedColor="#F7A32C"
              changeRating={(e) =>
                handleChange({ target: { value: e, name: "stars" } })
              }
              starSpacing="2"
              numberOfStars={6}
              name="stars"
            />
          </div>
          <div className="my-3">
            <p className="mb-1">
              What are the highlights of your mentoring session with{" "}
              {meetingData?.expert?.profilePages?.registrationPage?.firstName}{" "}
              {meetingData?.expert?.profilePages?.registrationPage?.lastName}?
            </p>
            <Input
              type="textarea"
              rows="10"
              name="highlights"
              onChange={handleChange}
            />
          </div>
          <div>
            <p className="mb-1">
              Would you recomend{" "}
              {meetingData?.expert?.profilePages?.registrationPage?.firstName}{" "}
              {meetingData?.expert?.profilePages?.registrationPage?.lastName} a
              to other designers as a mentor?
            </p>
            <div className="select-div">
              <div
                className={`${state.formRating.recomend === "yes" && "active"}`}
                onClick={() =>
                  handleChange({ target: { value: "yes", name: "recomend" } })
                }
              >
                Yes
              </div>
              <div
                className={`${
                  state.formRating.recomend === "no" && "active"
                } mx-3`}
                onClick={() =>
                  handleChange({ target: { value: "no", name: "recomend" } })
                }
              >
                Maybe Not
              </div>
            </div>
          </div>
          <Button
            loading={loading}
            color="lightBlue"
            text="Submit Review"
            block
          ></Button>
        </form>
      </CustomModal>
    </Container>
  );
};

export default AddReview;
