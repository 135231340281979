import * as types from "../Actions/types";

const initialState = {
  status: types.async_status.__DEFAULT__,
  data: {}
};

const userReducer = (state = initialState, action: types.Actonsnterface) => {
  switch (action.type) {
    case types.USER_DATA_LOADING:
      return {
        ...state,
        status: types.async_status.__LOADING__,
      };
    case types.USER_DATA_SUCCESS:
      return {
        status: types.async_status.__LOADED__,
        data: {
          ...action.payload.data
        }
      };
    case types.USER_DATA_FAILED:
      return {
        status: types.async_status.__FAILED__,
        data: {}
      };
    default:
      return state;
  }
};

export default userReducer;
