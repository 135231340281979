import React from "react";

function TicketIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#258AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 15c1.5 0 2.5.94 2.5 2h3V2h-3c0 1-1 2-2.5 2s-2.5-1-2.5-2h-3v7"
      ></path>
      <path
        stroke="#258AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 22h-3c0-1.06-1-2-2.5-2S7 20.94 7 22H4V7h3c0 1 1 2 2.5 2S12 8 12 7h3v15z"
      ></path>
      <path
        fill="#258AFF"
        d="M7 13a1 1 0 100-2 1 1 0 000 2zM9.5 13a1 1 0 100-2 1 1 0 000 2zM12 13a1 1 0 100-2 1 1 0 000 2z"
      ></path>
    </svg>
  );
}

export default TicketIcon;