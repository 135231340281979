import React from "react";

function CalenderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#258AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.25 3H3.75a1.5 1.5 0 00-1.5 1.5V15a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V4.5a1.5 1.5 0 00-1.5-1.5zM12 1.5v3M6 1.5v3M2.25 7.5h13.5"
      ></path>
    </svg>
  );
}

export default CalenderIcon;