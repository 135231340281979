import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

const SessionsComponent = () => {
    return (
        <div className='sessions my-5'>
            <Container fluid className='my-md-5 my-0'>
                <Row className='px-md-5 px-0'>
                    <Col md="6">
                        <div className='sessions-card p-5 one-on-one mb-2'>
                            <h1 className='text-white'>Book 1:1 Sessions</h1>
                            <p className="small">Book a personal learning session with an expert for your
                                next level.</p>
                            <Link className='btn btn-yellow' to="/search?tab=experts">Explore Experts</Link>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className='sessions-card p-5 group-session mb-2'>
                            <h1 className='text-white'>Join Group Sessions</h1>
                            <p className="small">Group learning is one of the fastest way to upskill.
Find and join a session based on your needs!</p>
                            <Link className='btn btn-yellow' to="/search?tab=group">Explore Group Session</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SessionsComponent