import Loader from 'components/common/Loader'
import { formatter } from 'Helpers/utility'
import React from 'react'
import { Card, CardBody } from 'reactstrap'

const TotalComponent:React.FC<any> = ({stats}) => {
    return (
        <Card className='table-card'>
            <CardBody>
                {stats.loading? <Loader /> :
                <div className="d-flex align-items-center">
                    <div>
                        <h6 className='mb-0'>Total Transaction</h6>
                        <p className='mb-0 small text-muted'>Overall payment on your learing experience</p>
                    </div>
                    <div className='ml-auto'>
                      <h4 className='text-lightBlue mb-0'>$ {formatter.format(stats.data.tranxSum)}</h4>
                    </div>
                </div>
}
            </CardBody>
        </Card>
    )
}

export default TotalComponent