import React from 'react';
import style from './Index.module.css';

const Privacy = () => {
  return (
    <div className={style.container}>
      <div className={`m-4 ${style.hero}`}>
        <h1 className='h3 mb-3' id='privacy-policy'>
          Coachmie
        </h1>
        <span className='h1 font-weight-bold'>Terms of Use Agreement</span>
        <p className='lead text-muted mt-3'>Last Updated On January 2023</p>
      </div>
      <div className={style.text_desc}>
        <p>
          This Terms of Use Agreement (“Agreement”), along with our Company
          Privacy Notice (__________________________________ [Privacy Policy
          URL]), and Service agreements constitute a legally binding agreement
          made between you, whether personally or on behalf of an entity (“user”
          or “you”) and COACHMIE LIMITED (COACHMIE, Inc) and its affiliated
          companies, Websites, applications and tools concerning your access to
          and use of the: _____________________
          ____________________________________________ [https://coachmie.com]
          Website(s) as well as any other media form, media channel, mobile
          website or mobile application related or connected thereto
          (collectively, the “Sites”).
        </p>
        <p>
          COACHMIE LTD is an incorporated company with the Corporate Affairs
          Commission under the Laws of the Federal Republic of Nigeria.
          COACHMIE, Inc is also registered in the United Kingdom and United
          States of America. COACHMIE LTD was founded for the purpose of
          developing a business concept in the area of professional and
          educational Software Development that will be usable primarily in
          Africa and the rest of the world by individuals, Institutions,
          Organizations, Companies and Nations.
        </p>

        <p>
          Supplemental terms and conditions or documents that may be posted on
          the Sites from time to time, are hereby expressly incorporated into
          this Agreement by reference.
        </p>

        <p>
          Company makes no representation that the Sites is appropriate or
          available in other locations other than where it is operated by
          Company. The information provided on the Sites is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject Company to any registration
          requirement within such jurisdiction or country. Accordingly, those
          persons who choose to access the Sites from other locations do so on
          their own initiative and are solely responsible for compliance with
          local laws, if and to the extent local laws are applicable.
        </p>

        <div className={style.policies}>
          <div className={style.policy}>
            <h1 className={style.policy_header}>A. MINORS </h1>
            <p>
              <span className='font-weight-bold'>
                Users under 18 need permission from a parent or guardian to use
                the Sites.
              </span>{' '}
              All users who are minors in the jurisdiction in which they reside
              (generally under the age of 18) must have the permission of, and
              be directly supervised by, their parent or guardian to use the
              Sites. If you are a minor, you must have your parent or guardian
              read and agree to this Agreement prior to you using the Sites. You
              accept and agree to be bound by this agreement by acknowledging
              such acceptance during the registration process (if applicable)
              and also by continuing to use the sites. If you do not agree to
              abide by this agreement, or to modifications that company may make
              to this agreement in the future, do not use or access or continue
              to use or access the company services or the sites.
            </p>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>B. PURCHASES; PAYMENT</h1>
            <h2 className={style.policy_sub_header}>
              i. Company sells products or services
            </h2>
            <p>
              The Company may offer a free trial or sample of our products or
              services. The duration of the free trial period and all other
              details of the offer will be posted on our Sites.
            </p>
            <p>
              If you wish to try our free options please read through them
              carefully first. Company will bill you through a third party
              payment provider (PayStack) for our Services.
            </p>
            <p>
              By using our paid options, you agree to pay Company all charges at
              the prices then in effect for the products or services you or
              other persons using your billing account may purchase, and you
              authorise Company to charge your chosen payment provider for any
              such purchases. You agree to make payment using that selected
              payment method. If you have selected a service that is subject to
              recurring charges then you agree for us to charge your payment
              method on a recurring basis, without requiring your prior approval
              from you for each recurring charge until such time as you cancel
              the applicable product or service.
            </p>
            <p>
              Company reserves the right to correct any errors or mistakes in
              pricing that it makes even if it has already requested or received
              payment. The Company may change prices at any time. All payments
              shall be in the initial approved currency.
            </p>
            <h2>ii. Refund and Return</h2>
            <p>
              For more information on our return and refund policy please visit:
              __________________________________ [Return/refund policy URL].
            </p>
          </div>

          <div className={style.policy}>
            <h1>C. USER REPRESENTATIONS</h1>
            <h2 className={style.policy_sub_header}>
              i. Regarding Your Registration
            </h2>
            <p className='mb-0'>
              By using the Company Services, you represent and warrant that:
            </p>
            <ol type='a'>
              <li>
                all registration information you submit is truthful and
                accurate;
              </li>
              <li>you will maintain the accuracy of such information;</li>
              <li>
                you will keep your password confidential and will be responsible
                for all use of your password and account;
              </li>
              <li>
                you are not a minor in the jurisdiction in which you reside, or
                if a minor, you have received parental permission to use our
                Sites; and
              </li>
              <li>
                your use of the Company Services does not violate any applicable
                law or regulation.
              </li>
            </ol>

            <p className='mb-0'>You also agree to:</p>
            <ol type='a'>
              <li>
                provide true, accurate, current and complete information about
                yourself as prompted by the Site’s registration form and
              </li>
              <li>
                maintain and promptly update registration data to keep it true,
                accurate, current and complete.
              </li>
            </ol>

            <p>
              If you provide any information that is untrue, inaccurate, not
              current or incomplete, or Company has reasonable grounds to
              suspect that such information is untrue, inaccurate, not current
              or incomplete, Company has the right to suspend or terminate your
              account and refuse any and all current or future use of the Sites
              (or any portion thereof). We reserve the right to remove or
              reclaim or change a user name you select if we determine
              appropriate in our discretion, such as when the user name is
              obscene or otherwise objectionable or when a trademark owner
              complains about a username that does not closely relate to a
              user's actual name.
            </p>

            <h2 className={style.policy_sub_header}>
              ii. Regarding Content You Provide
            </h2>
            <p className='mb-0'>
              We may invite you to chat or participate in blogs, message boards,
              online forums and other functionality and may provide you with the
              opportunity to create, submit, post, display, transmit, perform,
              publish, distribute or broadcast content and materials to our
              Sites and/or to or via the Sites’ forms, emails, chat agents,
              pop-ups, including, without limitation, text, writings, video,
              audio, photographs, graphics, comments, suggestions or personally
              identifiable information or other material (collectively
              "Contributions"). Any Contributions you transmit to the Company
              will be treated as non-confidential and non-proprietary. When you
              create or make available a Contribution, you thereby represent and
              warrant that:
            </p>

            <ol type='a'>
              <li>
                the creation, distribution, transmission, public display and
                performance, accessing, downloading and copying of your
                Contribution does not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret or moral rights of any third party;
              </li>
              <li>
                you are the creator and owner of or have the necessary licences,
                rights, consents, releases and permissions to use and to
                authorise Company and the Sites’ users to use your Contributions
                as necessary to exercise the licences granted by you under this
                Agreement;
              </li>
              <li>
                you have the written consent, release, and/or permission of each
                and every identifiable individual person in the Contribution to
                use the name or likeness of each and every such identifiable
                individual person to enable inclusion and use of the
                Contribution in the manner contemplated by our Sites;
              </li>
              <li>
                your Contribution is not obscene, lewd, lascivious, filthy,
                violent, harassing or otherwise objectionable (as determined by
                Company), libellous or slanderous, does not ridicule, mock,
                disparage, intimidate or abuse anyone, does not advocate the
                violent overthrow of any government, does not incite, encourage
                or threaten physical harm against another, does not violate any
                applicable law, regulation, or rule, and does not violate the
                privacy or publicity rights of any third party;
              </li>
              <li>
                your Contribution does not contain material that solicits
                personal information from anyone under 18 or exploit people
                under the age of 18 in a sexual or violent manner, and does not
                violate any federal or state law concerning child pornography or
                otherwise intended to protect the health or well-being of
                minors;
              </li>
              <li>
                your Contribution does not include any offensive comments that
                are connected to race, national origin, gender, sexual
                preference or physical handicap;
              </li>
              <li>
                your Contribution does not otherwise violate, or link to
                material that violates, any provision of this Agreement or any
                applicable law or regulation.
              </li>
            </ol>
          </div>

          <div className={style.policy}>
            <p>
              By posting Contributions to any part of the Sites, or making them
              accessible to the Sites by linking your account to any of your
              social network accounts, you automatically grant, and you
              represent and warrant that you have the right to grant, to the
              Company an unrestricted, unconditional, unlimited, irrevocable,
              perpetual, non-exclusive, transferable, royalty-free, fully-paid,
              worldwide right and licence to host, use, copy, reproduce,
              disclose, sell, resell, publish, broadcast, retitle, archive,
              store, cache, publicly perform, publicly display, reformat,
              translate, transmit, excerpt (in whole or in part) and distribute
              such Contributions (including, without limitation, your image and
              voice) for any purpose, commercial, advertising, or otherwise, to
              prepare derivative works of, or incorporate into other works, such
              Contributions, and to grant and authorise sublicenses of the
              foregoing.
            </p>
            <p>
              The use and distribution of such contents may occur in any media
              formats and through any media channels. Such use and distribution
              licence will apply to any form, media, or technology now known or
              hereafter developed, and includes our use of your name, company
              name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names and logos, personal and
              commercial images you provide.
            </p>
            <p>
              Company does not assert any ownership over your Contributions;
              rather, as between us and you, subject to the rights granted to us
              in this Agreement, you retain full ownership of all of your
              Contributions and any intellectual property rights or other
              proprietary rights associated with your Contributions. We will not
              use your contribution in a way that infringes on your rights and
              will always process your personal information lawfully and with
              your consent.
            </p>
            <p className='mb-0'>
              The Company has the right and the sole and absolute discretion, to
            </p>
            <ol type='i'>
              <li>edit, redact or otherwise change any Contributions,</li>
              <li>
                re-categorize any Contributions to place them in more
                appropriate locations or
              </li>
              <li>
                pre-screen or delete any Contributions that are determined to be
                inappropriate or otherwise in violation of this Agreement.
              </li>
            </ol>
            <p>
              By uploading your Contributions to the Sites, you hereby authorise
              Company to grant to each end user a personal, limited,
              no-transferable, perpetual, non-exclusive, royalty-free,
              fully-paid licence to access, download, print and otherwise use
              your Contributions for their internal purposes and not for
              distribution, transfer, sale or commercial exploitation of any
              kind.
            </p>
          </div>
          <div className={style.policy}>
            <h1 className={style.policy_header}>E. GUIDELINES FOR REVIEWS</h1>

            <p className='font-weight-bold'>
              User reviews of Company products or services are allowed.
            </p>

            <p className='mb-0'>
              Company may accept, reject or remove reviews at her sole
              discretion. Company has absolutely no obligation to screen reviews
              or to delete reviews, even if anyone considers reviews
              objectionable or inaccurate. Those persons posting reviews should
              comply with the following criteria:
            </p>

            <ol type='1'>
              <li>
                reviewers should have firsthand experience with the
                person/entity being reviewed;
              </li>
              <li>
                reviews should not contain: offensive language, profanity, or
                abusive, racist, or hate language; discriminatory references
                based on religion, race, gender, national origin, age, marital
                status, sexual orientation or disability; or references to
                illegal activity;
              </li>
              <li>
                reviewers should not be affiliated with competitors if posting
                negative reviews;
              </li>
              <li>
                reviewers should not make any conclusions as to the legality of
                conduct; and
              </li>
              <li>
                reviewers may not post any false statements or organise a
                campaign encouraging others to post reviews, whether positive or
                negative. Reviews are not endorsed by Company, and do not
                represent the views of Company or of any affiliate or partner of
                Company. The Company does not assume liability for any review or
                for any claims, liabilities or losses resulting from any review.
                By posting a review, the reviewer hereby grants to Company a
                perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
                assignable and sublicensable licence to Company to reproduce,
                modify, translate, transmit by any means, display, perform
                and/or distribute all content relating to reviews.
              </li>
            </ol>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>
              F. MOBILE APPLICATION LICENCE
            </h1>

            <p className='font-weight-bold'>Use Licence</p>

            <p>
              If you are accessing the Company Services via a mobile
              application, then Company grants you a revocable, non-exclusive,
              non-transferable, limited right to install and use the application
              on wireless handsets owned and controlled by you, and to access
              and use the application on such devices strictly in accordance
              with the terms and conditions of this licence.
            </p>

            <p>
              You shall use the application strictly in accordance with the
              terms of this licence and shall not:
            </p>

            <ol type='a'>
              <li>
                decompile, reverse engineer, disassemble, attempt to derive the
                source code of, or decrypt the application;
              </li>
              <li>
                make any modification, adaptation, improvement, enhancement,
                translation or derivative work from the application;
              </li>
              <li>
                violate any applicable laws, rules or regulations in connection
                with your access or use of the application;
              </li>
              <li>
                remove, alter or obscure any proprietary notice (including any
                notice of copyright or trademark) of Company or its affiliates,
                partners, suppliers or the licensors of the application;
              </li>
              <li>
                use the application for any revenue generating endeavour,
                commercial enterprise, or other purpose for which it is not
                designed or intended;
              </li>
              <li>
                make the application available over a network or other
                environmental permitting access or use by multiple devices or
                users at the same time;
              </li>
              <li>
                use the application for creating a product, service or software
                that is, directly or indirectly, competitive with or in any way
                a substitute for the application;
              </li>
              <li>
                use the application to send automated queries to any Sites or to
                send any unsolicited commercial e-mail; or
              </li>
              <li>
                use any proprietary information or interfaces of Company or
                other intellectual property of Company in the design,
                development, manufacture, licensing or distribution of any
                applications, accessories or devices for use with the
                application.
              </li>
            </ol>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>
              G. TERMS APPLICABLE TO APPLE AND ANDROID DEVICES
            </h1>

            <p>
              The following terms apply when you use a mobile application
              obtained from either the Apple Store or Google Play to access the
              Company Services. You acknowledge that this Agreement is concluded
              between you and Company only, and not with Apple Inc. or Google,
              Inc. (each an “App Distributor”), and Company, not an App
              Distributor, is solely responsible for the Company application and
              the content thereof.
            </p>

            <ol type='1'>
              <li>
                SCOPE OF LICENCE: The licence granted to you for the Company's
                application is limited to a non-transferable licence to use the
                Company's application on a device that utilises the Apple iOS or
                Android operating system, as applicable, and in accordance with
                the usage rules set forth in the applicable App Distributor
                terms of service.
              </li>
              <li>
                MAINTENANCE AND SUPPORT: Company is solely responsible for
                providing any maintenance and support services with respect to
                the Company application, as specified in this Agreement, or as
                required under applicable law. You acknowledge that each App
                Distributor has no obligation whatsoever to furnish any
                maintenance and support services with respect to the Company
                application.
              </li>
              <li>
                WARRANTY: Company is solely responsible for any product
                warranties, whether express or implied by law, to the extent not
                effectively disclaimed. In the event of any failure of the
                Company application to conform to any applicable warranty, you
                may notify an App Distributor, and the App Distributor, in
                accordance with its terms and policies, may refund the purchase
                price, if any, paid for the Company application, and to the
                maximum extent permitted by applicable law, an App Distributor
                will have no other warranty obligation whatsoever with respect
                to the Company application, and any other claims, losses,
                liabilities, damages, costs or expenses attributable to any
                failure to conform to any warranty will be Company sole
                responsibility.
              </li>
              <li>
                PRODUCT CLAIMS: You acknowledge that Company, not an App
                Distributor, is responsible for addressing any claims of yours
                or any third party relating to the Company application or your
                possession and/or use of the Company application, including, but
                not limited to:
                <ol type='i'>
                  <li>product liability claims;</li>
                  <li>
                    any claim that the Company application fails to conform to
                    any applicable legal or regulatory requirement; and
                  </li>
                  <li>
                    claims arising under consumer protection or similar
                    legislation.
                  </li>
                </ol>
              </li>
              <li>
                THIRD PARTY TERMS OF AGREEMENT: You must comply with applicable
                third party terms of agreement when using the Company
                application, e.g., if you have a VoIP application, then you must
                not be in violation of their wireless data service agreement
                when using the Company application.
              </li>
              <li>
                THIRD PARTY BENEFICIARY: Company and you acknowledge and agree
                that the App Distributors, and their subsidiaries, are third
                party beneficiaries of this Agreement, and that, upon your
                acceptance of the terms and conditions of this Agreement, each
                App Distributor will have the right (and will be deemed to have
                accepted the right) to enforce this Agreement against you as a
                third party beneficiary thereof.
              </li>
            </ol>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>H. SOCIAL MEDIA</h1>

            <p className='mb-0'>
              As part of the functionality of the Sites, you may link your
              account with online accounts you may have with third party service
              providers (each such account, a “Third Party Account”) by either:
            </p>
            <ol type='i'>
              <li>
                providing your Third Party Account login information through the
                Sites; or
              </li>
              <li>
                allowing Company to access your Third Party Account, as is
                permitted under the applicable terms and conditions that govern
                your use of each Third Party Account.
              </li>
            </ol>

            <p>
              You represent that you are entitled to disclose your Third Party
              Account login information to Company and/or grant Company access
              to your Third Party Account (including, but not limited to, for
              use for the purposes described herein), without breach by you of
              any of the terms and conditions that govern your use of the
              applicable Third Party Account and without obligating Company to
              pay any fees or making Company subject to any usage limitations
              imposed by such third party service providers.
            </p>

            <p className='mb-0'>
              By granting Company access to any Third Party Accounts, you
              understand that
            </p>

            <ol type='i'>
              <li>
                Company may access, make available and store (if applicable) any
                content that you have provided to and stored in your Third Party
                Account (the “Social Network Content”) so that it is available
                on and through the Sites via your account, including without
                limitation any friend lists, and
              </li>
              <li>
                The Company may submit and receive additional information to
                your Third Party Account to the extent you are notified when you
                link your account with the Third Party Account.
              </li>
            </ol>

            <p>
              Depending on the Third-Party Accounts you choose and subject to
              the privacy settings that you have set in such Third-Party
              Accounts, personally identifiable information that you post to
              your Third-Party Accounts may be available on and through your
              account on the Sites. Please note that if a Third-Party Account or
              associated service becomes unavailable or Company access to such
              Third-Party Account is terminated by the third-party service
              provider, then the Social Network Content may no longer be
              available on and through the Sites
            </p>

            <p>
              You will have the ability to disable the connection between your
              account on the sites and your third-party accounts at any time.
              Please note that your relationship with the third-party service
              providers associated with your third-party accounts is governed
              solely by your agreement(s) with such third-party service
              providers.
            </p>

            <p>
              Company makes no effort to review any Social Network Content for
              any purpose, including but not limited to, for accuracy, legality
              or non-infringement, and Company is not responsible for any Social
              Network Content. You acknowledge and agree that the Company may
              access your e-mail address book associated with a Third-Party
              Account and your contacts list stored on your mobile device or
              tablet computer solely for the purposes of identifying and
              informing you of those contacts who have also registered to use
              the Sites. At your request made via email to our email address
              listed below, or through your account settings (if applicable),
              the Company will deactivate the connection between the Sites and
              your Third Party Account and delete any information stored on
              Company servers that was obtained through such Third-Party
              Account, except the username and profile picture that become
              associated with your account
            </p>
          </div>

          <div className={style.policy}>
            <h1>I. SUBMISSIONS</h1>

            <p>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback or other information about the Sites
              or the Company Services ("Submissions") provided by you to Company
              are non-confidential and Company (as well as any designee of
              Company) shall be entitled to the unrestricted use and
              dissemination of these Submissions for any purpose, commercial or
              otherwise, without acknowledgment or compensation to you.
            </p>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>J. PROHIBITED ACTIVITIES</h1>
            <p className='mb-0'>
              You may not access or use the Sites for any other purpose other
              than that for which Company makes it available. The Sites may not
              be used in connection with any commercial endeavours except those
              that are specifically endorsed or approved by the Company.
              Prohibited activity includes, but is not limited to:
            </p>
            <ul>
              <li>
                attempting to bypass any measures of the Sites designed to
                prevent or restrict access to the Sites, or any portion of the
                Sites
              </li>
              <li>
                attempting to impersonate another user or person or using the
                username of another user
              </li>
              <li>criminal or tortious activity</li>
              <li>
                deciphering, decompiling, disassembling or reverse engineering
                any of the software comprising or in any way making up a part of
                the Sites
              </li>
              <li>
                deleting the copyright or other proprietary rights notice from
                any Sites’ content
              </li>
              <li>
                engaging in any automated use of the system, such as using any
                data mining, robots or similar data gathering and extraction
                tools
              </li>
              <li>
                except as may be the result of standard search engine or
                Internet browser usage, using or launching, developing or
                distributing any automated system, including, without
                limitation, any spider, robot (or "bot"), cheat utility, scraper
                or offline reader that accesses the Sites, or using or launching
                any unauthorised script or other software
              </li>
              <li>
                harassing, annoying, intimidating or threatening any Company
                employees or agents engaged in providing any portion of the
                Company Services to you
              </li>
              <li>
                interfering with, disrupting, or creating an undue burden on the
                Sites or the networks or services connected to the Sites
              </li>
              <li>
                making any unauthorised use of the Company Services, including
                collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited
                email, or creating user accounts by automated means or under
                false pretences
              </li>
              <li>selling or otherwise transferring your profile</li>
              <li>
                systematic retrieval of data or other content from the Sites to
                create or compile, directly or indirectly, a collection,
                compilation, database or directory without written permission
                from Company
              </li>
              <li>
                tricking, defrauding or misleading Company and other users,
                especially in any attempt to learn sensitive account information
                such as passwords
              </li>
              <li>
                using any information obtained from the Sites in order to
                harass, abuse, or harm another person
              </li>
              <li>
                using the Company Services as part of any effort to compete with
                Company or to provide services as a service bureau
              </li>
              <li>
                using the Sites in a manner inconsistent with any and all
                applicable laws and regulations
              </li>
              <li>
                other: _____________________________________________________
              </li>
            </ul>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>
              K. INTELLECTUAL PROPERTY RIGHTS
            </h1>
            <p>
              The content on the Site (“Company Content”) and the trademarks,
              service marks and logos contained therein (“Marks”) are owned by
              or licensed to Company, and are subject to copyright and other
              intellectual property rights under Nigerian Law and foreign laws
              and international conventions. Company Content includes, without
              limitation, all source code, databases, functionality, software,
              Sites’ designs, audio, video, text, photographs and graphics. All
              Company graphics, logos, designs, page headers, button icons,
              scripts and service names are registered trademarks, common law
              trademarks or trade dress of Company in the United States and/or
              other countries. Company trademarks and trade dress may not be
              used, including as part of trademarks and/or as part of domain
              names, in connection with any product or service in any manner
              that is likely to cause confusion and may not be copied, imitated,
              or used, in whole or in part, without the prior written permission
              of the Company. The Company Content on the Sites is provided to
              you “AS IS” for your information and personal use only and may not
              be used, copied, reproduced, aggregated, distributed, transmitted,
              broadcast, displayed, sold, licensed, or otherwise exploited for
              any other purposes whatsoever without the prior written consent of
              the respective owners. Provided that you are eligible to use the
              Sites, you are granted a limited license to access and use the
              Sites and the Company Content and to download or print a copy of
              any portion of the Company Content to which you have properly
              gained access solely for your personal, non-commercial use. The
              Company reserves all rights not expressly granted to you in and to
              the Sites and Company Content and Marks.
            </p>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>
              L. THIRD PARTY WEBSITES AND CONTENT
            </h1>

            <p>
              The Site contains (or you may be sent through the Sites or the
              Company Services) links to other websites ("Third Party Websites")
              as well as articles, photographs, text, graphics, pictures,
              designs, music, sound, video, information, applications, software
              and other content or items belonging to or originating from third
              parties (the "Third Party Content"). Such Third Party Websites and
              Third Party Content are not investigated, monitored or checked for
              accuracy, appropriateness, or completeness by us, and we are not
              responsible for any Third Party accessed through the Sites or any
              Third Party Content posted on, available through or installed from
              the Sites, including the content, accuracy, offensiveness,
              opinions, reliability, privacy practices or other policies of or
              contained in the Third Party Websites or the Third Party Content.
              Inclusion of, linking to or permitting the use or installation of
              any Third Party Websites or any Third Party Content does not imply
              approval or endorsement thereof by us.
            </p>
            <p>
              If you decide to leave the Sites and access the Third Party
              Websites or to use or install any Third Party Content, you do so
              at your own risk and you should be aware that our terms and
              policies no longer govern. You should review the applicable terms
              and policies, including privacy and data gathering practices, of
              any websites to which you navigate from the Sites or relating to
              any applications you use or install from the Sites. Any purchases
              you make through Third Party Websites will be through other
              websites and from other companies, and the Company takes no
              responsibility whatsoever in relation to such purchases which are
              exclusively between you and the applicable third party.
            </p>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>M. SITE MANAGEMENT</h1>

            <p className='mb-0'>
              Company reserves the right but does not have the obligation:
            </p>

            <ol type='i'>
              <li>to monitor the Sites for violations of this Agreement;</li>
              <li>
                to take appropriate legal action against anyone who, in Company
                sole discretion, violates this Agreement, including without
                limitation, reporting such user to law enforcement authorities;
              </li>
              <li>
                (in Company's sole discretion and without limitation) to refuse,
                restrict access to or availability of, or disable (to the extent
                technologically feasible) any user’s contribution or any portion
                thereof that may violate this Agreement or any Company policy;
              </li>
              <li>
                (in Company’s sole discretion and without limitation, notice or
                liability) to remove from the Sites or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to Company's systems;
              </li>
              <li>
                to otherwise manage the Sites in a manner designed to protect
                the rights and property of Company and others and to facilitate
                the proper functioning of the Sites.
              </li>
            </ol>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>N. TERM AND TERMINATION</h1>

            <p>
              This Agreement shall remain in full force and effect while you use
              the Sites or are otherwise a user or member of the Sites, as
              applicable. You may terminate your use or participation at any
              time, for any reason, by following the instructions for
              terminating user accounts in your account settings, if available,
              or by contacting us using the contact information below.
            </p>

            <p>
              Without limiting any other provision of this agreement, company
              reserves the right to, in company’s sole discretion and without
              notice or liability, deny access to and use of the sites and the
              company services, to any person for any reason or for no reason at
              all, including without limitation for breach of any
              representation, warranty or covenant contained in this agreement,
              or of any applicable law or regulation, and company may terminate
              your use or participation in the sites and the company services,
              delete your profile and any content or information that you have
              posted at any time, without warning, in company’s sole discretion.
              In order to protect the integrity of the Sites and Company
              Services, Company reserves the right at any time in its sole
              discretion to block certain IP addresses from accessing the Sites
              and Company Services. Any provisions of this Agreement that, in
              order to fulfill the purposes of such provisions, need to survive
              the termination or expiration of this Agreement, shall be deemed
              to survive for as long as necessary to fulfill such purposes.
            </p>

            <p>
              If the Company terminates or suspends your account for any reason,
              you are prohibited from registering and creating a new account
              under your name, a fake or borrowed name, or the name of any third
              party, even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, Company
              reserves the right to take appropriate legal action, including
              without limitation pursuing civil, criminal, and injunctive
              redress.
            </p>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>O. MODIFICATIONS</h1>

            <h2 className={style.policy_sub_header}>a. To Agreement</h2>
            <p>
              The Company may modify this Agreement from time to time. Any and
              all changes to this Agreement will be posted on the Sites and
              revisions will be indicated by date. You agree to be bound to any
              changes to this Agreement when you use the Company Services after
              any such modification becomes effective. Company may also, in its
              discretion, choose to alert all users with whom it maintains email
              information of such modifications by means of an email to their
              most recently provided email address. It is therefore important
              that you regularly review this Agreement and keep your contact
              information current in your account settings to ensure you are
              informed of changes. You agree that you will periodically check
              the Sites for updates to this Agreement and you will read the
              messages we send you to inform you of any changes. Modifications
              to this Agreement shall be effective after posting.
            </p>

            <h2 className={style.policy_sub_header}>b. To Services</h2>
            <p>
              Company reserves the right at any time to modify or discontinue,
              temporarily or permanently, the Company Services (or any part
              thereof) with or without notice. You agree that the Company shall
              not be liable to you or to any third party for any modification,
              suspension or discontinuance of the Company Services.
            </p>
          </div>

          <div className={style.policy}>
            <h1 className={style.policy_header}>P. DISPUTES</h1>
            <h2 className={style.policy_sub_header}>i. Between Users</h2>

            <p>
              If there is a dispute between users of the Sites, or between users
              and any third party, you understand and agree that the Company is
              under no obligation to become involved. In the event that you have
              a dispute with one or more other users, you hereby release the
              Company, its officers, employees, agents and successors in rights
              from claims, demands and damages (actual and consequential) of
              every kind or nature, known or unknown, suspected and unsuspected,
              disclosed and undisclosed, arising out of or in any way related to
              such disputes and/or the Company Services.
            </p>

            <h2 className={style.policy_sub_header}>ii. With Company</h2>
            <ol type='1'>
              <li>
                <span className='font-weight-bold'>
                  Governing Law; Jurisdiction.
                </span>{' '}
                This Agreement and all aspects of the Sites and Company Services
                shall be governed by and construed in accordance with the
                internal laws of the FEDERAL REPUBLIC OF NIGERIA without regard
                to conflict of law provisions. With respect to any disputes or
                claims not subject to informal dispute resolution or arbitration
                (as set forth below), you agree not to commence or prosecute any
                action in connection therewith other than in the state and
                federal courts located in NIGERIA, and you hereby consent to,
                and waive all defences of lack of personal jurisdiction and
                forum non conveniens with respect to, venue and jurisdiction in
                such state and federal courts. In no event shall any claim,
                action or proceeding by you related in any way to the Sites or
                Company Services be instituted more than one (1) year after the
                cause of action arose.
              </li>
              <li>
                <span className='font-weight-bold'>Informal Resolution.</span>{' '}
                To expedite resolution and control the cost of any dispute,
                controversy or claim related to this Agreement ("Dispute"), you
                and Company agree to first attempt to negotiate any Dispute
                (except those Disputes expressly provided below) informally for
                at least ___28__________ days before initiating any arbitration
                or court proceeding. Such informal negotiations commence upon
                written notice from one person to the other.
              </li>
            </ol>

            <h2 className={style.policy_sub_header}>
              iii. Binding Arbitration.
            </h2>
            <p>
              If you and Company are unable to resolve a Dispute through
              informal negotiations, either you or the Company may elect to have
              the Dispute (except those Disputes expressly excluded below)
              finally and exclusively resolved by binding arbitration. Any
              election to arbitrate by one party shall be final and binding on
              the other. YOU UNDERSTAND THAT UNLESS WE AGREE OTHERWISE, YOU
              WOULD HAVE THE RIGHT TO SUE IN COURT. The arbitration shall be
              commenced and conducted under the{' '}
              <span className='font-weight-bold'>
                ARBITRATION AND CONCILIATION ACT CAP A18 LAWS OF THE FEDERAL
                REPUBLIC OF NIGERIA 2004 (the "ACA'').
              </span>{' '}
              Except as otherwise provided in this Agreement, you and the
              Company may litigate in court to compel arbitration, stay
              proceedings pending arbitration, or to confirm, modify, vacate or
              enter judgement on the award entered by the arbitrator.
            </p>
          </div>

          <h2 className={style.policy_sub_header}>iv. Restrictions.</h2>
          <p>
            You and Company agree that any arbitration shall be limited to the
            Dispute between Company and you individually. To the full extent
            permitted by law,
          </p>

          <ol type='1'>
            <li>no arbitration shall be joined with any other; </li>
            <li>
              {' '}
              there is no right or authority for any Dispute to be arbitrated on
              a class-action basis or to utilise class action procedures; and{' '}
            </li>
            <li>
              {' '}
              there is no right or authority for any Dispute to be brought in a
              purported representative capacity on behalf of the general public
              or any other persons.
            </li>
          </ol>

          <h2 className={style.policy_sub_header}>
            v. Exceptions to Informal Negotiations and Arbitration.
          </h2>
          <p>
            You and the Company agree that the following Disputes are not
            subject to the above provisions concerning informal negotiations and
            binding arbitration:
          </p>
          <ol type='1'>
            <li>
              any Disputes seeking to enforce or protect, or concerning the
              validity of any of your or the Company’s intellectual property
              rights;
            </li>
            <li>
              any Dispute related to, or arising from, allegations of theft,
              piracy, invasion of privacy or unauthorized use;
            </li>
            <li>
              any claim for injunctive relief. If this Section is found to be
              illegal or unenforceable then neither you nor the Company will
              elect to arbitrate any Dispute falling within that portion of this
              Section found to be illegal or unenforceable and such Dispute
              shall be decided by a court of competent jurisdiction within the
              courts listed for jurisdiction above, and you and Company agree to
              submit to the personal jurisdiction of that court.
            </li>
          </ol>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>Q. CORRECTIONS</h1>
          <p>
            Occasionally there may be information on the Sites that contains
            typographical errors, inaccuracies or omissions that may relate to
            service descriptions, pricing, availability, and various other
            information. Company reserves the right to correct any errors,
            inaccuracies or omissions and to change or update the information at
            any time, without prior notice.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>R. DISCLAIMERS</h1>
          <p>
            Company cannot control the nature of all of the content available on
            the Sites. By operating the Sites, Company does not represent or
            imply that Company endorses any blogs, contributions or other
            content available on or linked to by the Sites, including without
            limitation content hosted on third party websites or provided by
            third party applications, or that the Company believes
            contributions, blogs or other content to be accurate, useful or
            non-harmful. We do not control and are not responsible for unlawful
            or otherwise objectionable content you may encounter on the Sites or
            in connection with any contributions. The Company is not responsible
            for the conduct, whether online or offline, of any user of the Sites
            or Company Services
          </p>

          <p>
            You agree that your use of the sites and company services will be at
            your sole risk. To the fullest extent permitted by law, company, its
            officers, directors, employees, and agents disclaim all warranties,
            express or implied, in connection with the sites and the company
            services and your use thereof, including, without limitation, the
            implied warranties of merchantability, fitness for a particular
            purpose and non-infringement. The Company makes no warranties or
            representations about the accuracy or completeness of the sites’
            content or the content of any websites linked to our sites and
            assumes no liability or responsibility for any
          </p>
          <ol type='a'>
            <li>errors, mistakes, or inaccuracies of content and materials,</li>
            <li>
              personal injury or property damage, of any nature whatsoever,
              resulting from your access to and use of our sites,
            </li>
            <li>
              any unauthorized access to or use of our secure servers and/or any
              and all personal information and/or financial information stored
              therein,
            </li>
            <li>
              any interruption or cessation of transmission to or from the sites
              or company services,
            </li>
            <li>
              any bugs, viruses, trojan horses, or the like which may be
              transmitted to or through our sites by any third party, and/or
            </li>
            <li>
              any errors or omissions in any content and materials or for any
              loss or damage of any kind incurred as a result of the use of any
              content posted, transmitted, or otherwise made available via the
              sites.
            </li>
          </ol>

          <p>
            Company does not warrant, endorse, guarantee, or assume
            responsibility for any product or service advertised or offered by a
            third party through the sites or any hyperlinked sites or featured
            in any banner or other advertising, and company will not be a party
            to or in any way be responsible for monitoring any transaction
            between you and third-party providers of products or services as
            with the purchase of a product or service through any medium or in
            any environment, you should use your best judgement and exercise
            caution where appropriate.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>S. LIMITATIONS OF LIABILITY</h1>
          <p>
            In no event shall company or its directors, employees, or agents be
            liable to you or any third party for any direct, indirect,
            consequential, exemplary, incidental, special or punitive damages,
            including lost profit, lost revenue, loss of data or other damages
            arising from your use of the sites or company services, even if
            company has been advised of the possibility of such damages.
            Notwithstanding anything to the contrary contained herein, company’s
            liability to you for any cause whatsoever and regardless of the form
            of the action, will at all times be limited to the amount paid, if
            any, by you to company for the company services during the period of
            3 (three) months prior to any cause of action arising.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>T. INDEMNITY</h1>
          <p>
            You agree to defend, indemnify and hold Company, its subsidiaries,
            and affiliates, and their respective officers, agents, partners and
            employees, harmless from and against, any loss, damage, liability,
            claim, or demand, including reasonable attorneys’ fees and expenses,
            made by any third party due to or arising out of your contributed
            content, use of the Company Services, and/or arising from a breach
            of this Agreement and/or any breach of your representations and
            warranties set forth above. Notwithstanding the foregoing, Company
            reserves the right, at your expense, to assume the exclusive defence
            and control of any matter for which you are required to indemnify
            the Company, and you agree to cooperate, at your expense, with the
            Company’s defence of such claims. The Company will use reasonable
            efforts to notify you of any such claim, action, or proceeding which
            is subject to this indemnification upon becoming aware of it.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_sub_header}>U. NOTICES</h1>
          <p>
            Except as explicitly stated otherwise, any notices given to the
            Company shall be given by email to the address listed in the contact
            information below. Any notices given to you shall be given to the
            email address you provided during the registration process, or such
            other address as each party may specify. Notice shall be deemed to
            be given twenty-four (24) hours after the email is sent, unless the
            sending party is notified that the email address is invalid. We may
            also choose to send notices by regular mail.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>V. USER DATA</h1>
          <p>
            Our Sites will maintain certain data that you transfer to the Sites
            for the purpose of the performance of the Company Services, as well
            as data relating to your use of the Company’s Services. Although we
            perform regular routine backups of data, you are primarily
            responsible for all data that you have transferred or that relates
            to any activity you have undertaken using the Company’s Services.
            You agree that the Company shall have no liability to you for any
            loss or corruption of any such data, and you hereby waive any right
            of action against the Company arising from any such loss or
            corruption of such data.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>W. ELECTRONIC CONTRACTING</h1>
          <p>
            Your use of the Company Services includes the ability to enter into
            agreements and/or to make transactions electronically. You
            acknowledge that your electronic submissions constitute your
            agreement and intent to be bound by such agreements and
            transactions. Your agreement and intent to be bound by electronic
            submissions applies to all records relating to all transactions you
            enter into relating to the company services, including notices of
            cancellation, policies, contracts, and applications. In order to
            access and retain your electronic records, you may be required to
            have certain hardware and software, which are your sole
            responsibility.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>X. ELECTRONIC SIGNATURES</h1>
          <p>
            Users are allowed on _____________________________________ [Website
            name] to transmit and receive valid electronic signatures.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>Y. MISCELLANEOUS</h1>
          <p>
            This Agreement constitutes the entire agreement between you and the
            Company regarding the use of the Company Services. The failure of
            the Company to exercise or enforce any right or provision of this
            Agreement shall not operate as a waiver of such right or provision.
            The section titles in this Agreement are for convenience only and
            have no legal or contractual effect. This Agreement operates to the
            fullest extent permissible by law. This Agreement and your account
            may not be assigned by you without our express written consent. The
            Company may assign any or all of its rights and obligations to
            others at any time. The Company shall not be responsible or liable
            for any loss, damage, delay or failure to act due to any event
            beyond the Company's reasonable control.
          </p>
          <p>
            If any provision or part of a provision of this Agreement is
            unlawful, void or unenforceable, that provision or part of the
            provision is deemed severable from this Agreement and does not
            affect the validity and enforceability of any remaining provisions.
            There is no joint venture, partnership, employment or agency
            relationship created between you and the Company as a result of this
            Agreement or use of the Sites and the Company’s Services. Upon
            Company’s request, you will furnish the Company with any
            documentation, substantiation or releases necessary to verify your
            compliance with this Agreement. You agree that this Agreement will
            not be construed against the Company by virtue of having drafted
            them. You hereby waive any and all defences you may have based on
            the electronic form of this Agreement and the lack of signing by the
            parties hereto to execute this Agreement.
          </p>
        </div>

        <div className={style.policy}>
          <h1 className={style.policy_header}>Z. CONTACT US</h1>
          <p className='lead'>
            Company Name: COACHMIE LIMITED (COACHMIE, Inc) <br />
            Email: privacy@coachmie.com <br />
            Phone: +13067150398
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
