import React from "react";

function WishListIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M12 4.13l.222-.229a6.206 6.206 0 018.94 0c2.45 2.52 2.45 6.594 0 9.113l-7.728 7.946a2 2 0 01-2.828.04l-.04-.04-7.728-7.946c-2.45-2.52-2.45-6.593 0-9.113a6.206 6.206 0 018.94 0L12 4.13zm7.728 7.49c1.696-1.744 1.696-4.581 0-6.324a4.206 4.206 0 00-6.073 0l-.938.965a1 1 0 01-1.434 0l-.938-.965a4.206 4.206 0 00-6.073 0c-1.696 1.743-1.696 4.58 0 6.324L12 19.566l7.728-7.946z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default WishListIcon;