import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Experts from './Components/Experts';
import ExploreTabs from './Components/ExploreTabs';
import SearchComponent from './Components/SearchComponent';
import Sessions from './Components/Sessions';
const MapSubComponents: React.FC<{ activeTab: string; search: string }> = ({
  activeTab,
  ...props
}) => {
  let componnents: Record<string, any> = {
    [`Experts`]: <Experts col={{ md: 6, lg: 4 }} {...props} />,
    [`Group Sessions`]: <Sessions col={{ md: 6, lg: 4 }} {...props} />
  };
  return componnents[activeTab];
};

const Explore: React.FC<any> = () => {
  const [state, setState] = useState({
    tabs: ['Experts', 'Group Sessions'],
    activeTab: 'Experts',
    search: '',
    searchSent: ''
  });
  const resetSearchField = () => {
    setState({
      ...state,
      search: '',
      searchSent: ''
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const updateTab = useCallback(
    (param: string) => {
      setSearchParams({
        tab: param === 'Group Sessions' ? 'group' : 'experts'
      });
      setState((prev) => ({
        ...prev,
        activeTab: param,
        search: ''
      }));
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (tab && tab === 'group') {
      updateTab('Group Sessions');
    }
    if (tab && tab === 'experts') {
      updateTab('Experts');
    }
  }, [tab, updateTab]);

  const handleChange = (e: any) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      searchSent: prev.search
    }));
  };

  return (
    <div>
      <Container>
        <Row>
          <Col sm='12'>
            <ExploreTabs
              tabs={state.tabs}
              activeTab={state.activeTab}
              updateTab={updateTab}
            />
          </Col>
          <Col md='7'>
            <SearchComponent
              name='search'
              handleSearch={handleSearch}
              handleChange={handleChange}
              search={state.search}
              resetfield={resetSearchField}
            />
          </Col>
        </Row>
      </Container>
      <MapSubComponents activeTab={state.activeTab} search={state.searchSent} />
    </div>
  );
};

export default Explore;
