import React from 'react';
import { IoIosClose as EditInput } from 'react-icons/io';

const SearchComponent: React.FC<any> = ({
  search,
  handleChange,
  handleSearch,
  resetfield,
  name
}) => {
  return (
    <form className='d-block d-md-flex mt-4' onSubmit={handleSearch}>
      <div className='search-input mb-2 d-flex align-items-center px-3 w-100 mr-3'>
        <img
          alt=''
          src={require('assets/images/svg/icons/ic_search.svg').default}
          className='mr-2'
        />
        <input
          name={name}
          value={search}
          onChange={handleChange}
          type='search'
          placeholder='Search by company, expertise, role'
        />
        {search !== '' && (
          <EditInput
            onClick={resetfield}
            style={{
              fontSize: '2.2rem'
            }}
          />
        )}
      </div>
      {/* <select className='search-dropdown mb-2 px-3'>
            <option>Expertise</option>
        </select> */}
    </form>
  );
};

export default SearchComponent;
