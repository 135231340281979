import Tabs from "components/common/Tabs";
import { apiErrorHandler } from "Helpers/utility";
import React, { memo, useCallback, useEffect, useState } from "react";
import { getExpetGroupSessions } from "services/sessionService";
import { fetchExpertReviews } from "services/userService";
import Reviews from "./Reviews";
import { Sessions } from "./Sessions";

const initialData = [
  {
    date: "September 23, 2021",
    data: "Nabanita was of great help! I have a complex career background but she was able to quickly put herself in my shoes. Drawing from her own past experience, she answered my questions of imposter syndrome, and where my strong points lay, following up with advice on how to position myself on my UX career path. Leading by example, she also explained best practices in which to present my portfolio. I am always amazed at how everyone l meet in the UX industry are so generous and kind.",
  },
  {
    date: "September 23, 2021",
    data: "Nabanita was of great help! I have a complex career background but she was able to quickly put herself in my shoes. Drawing from her own past experience, she answered my questions of imposter syndrome, and where my strong points lay, following up with advice on how to position myself on my UX career path. Leading by example, she also explained best practices in which to present my portfolio. I am always amazed at how everyone l meet in the UX industry are so generous and kind.",
  },
  {
    date: "September 23, 2021",
    data: "Nabanita was of great help! I have a complex career background but she was able to quickly put herself in my shoes. Drawing from her own past experience, she answered my questions of imposter syndrome, and where my strong points lay, following up with advice on how to position myself on my UX career path. Leading by example, she also explained best practices in which to present my portfolio. I am always amazed at how everyone l meet in the UX industry are so generous and kind.",
  },
  {
    date: "September 23, 2021",
    data: "Nabanita was of great help! I have a complex career background but she was able to quickly put herself in my shoes. Drawing from her own past experience, she answered my questions of imposter syndrome, and where my strong points lay, following up with advice on how to position myself on my UX career path. Leading by example, she also explained best practices in which to present my portfolio. I am always amazed at how everyone l meet in the UX industry are so generous and kind.",
  },
  {
    date: "September 23, 2021",
    data: "Nabanita was of great help! I have a complex career background but she was able to quickly put herself in my shoes. Drawing from her own past experience, she answered my questions of imposter syndrome, and where my strong points lay, following up with advice on how to position myself on my UX career path. Leading by example, she also explained best practices in which to present my portfolio. I am always amazed at how everyone l meet in the UX industry are so generous and kind.",
  },
  {
    date: "September 23, 2021",
    data: "Nabanita was of great help! I have a complex career background but she was able to quickly put herself in my shoes. Drawing from her own past experience, she answered my questions of imposter syndrome, and where my strong points lay, following up with advice on how to position myself on my UX career path. Leading by example, she also explained best practices in which to present my portfolio. I am always amazed at how everyone l meet in the UX industry are so generous and kind.",
  },
];

const MapSubComponents: React.FC<{
  activeTab: string;
  reviews: any;
  loading: any;
  fetchReviews: () => void;
  fetchSessions: () => void;
  sessionState: Record<any, any>;
}> = memo(({ activeTab, reviews, loading, fetchReviews, ...props }) => {
  let componnents: Record<string, any> = {
    [`Reviews`]: (
      <Reviews
        loading={reviews.loading}
        data={reviews.data}
        hasMore={reviews.hasMore}
        loadMore={fetchReviews}
      />
    ),
    [`Upcoming Sessions`]: (
      <Sessions
        sessionState={props.sessionState}
        fetchSessions={props.fetchSessions}
      />
    ),
    [`Past Sessions`]: (
      <Sessions
        sessionState={props.sessionState}
        fetchSessions={props.fetchSessions}
      />
    ),
  };
  return componnents[activeTab];
});
const ExpertTabs: React.FC<any> = memo(({ id }) => {
  const [state, setState] = useState<any>({
    tabs: ["Reviews", "Upcoming Sessions", "Past Sessions"],
    activeTab: "Reviews",
    data: initialData,
    loading: false,
  });

  const [reviewState, setReviewState] = useState({
    data: [],
    pageSize: 5,
    pageNo: 1,
    hasMore: false,
    loading: true,
  });

  const [sessionState, setSessionState] = useState({
    data: [],
    pageSize: 5,
    pageNo: 1,
    hasMore: false,
    loading: true,
  });

  const toggleLoading = (changeState = setReviewState) => {
    changeState((prev: any) => ({
      ...prev,
      loading: !prev.loading,
    }));
  };
  const toggleTab = (param: String) => {
    setState((prev: Object) => ({
      ...prev,
      activeTab: param,
      data: param === "Group Sessions" ? [] : initialData,
    }));

    if (param.includes("Sessions")) {
      getSessions(1, param.split(" ")[0]);
    }
  };

  const getReviews = useCallback(
    async (pageNo = 1) => {
      try {
        let {
          data: { data },
        } = await fetchExpertReviews(id, 5, pageNo);
        setReviewState((prev) => ({
          ...prev,
          data: pageNo > 1 ? prev.data.concat(data.reviews) : data.reviews,
          pageNo: pageNo,
          totalRecord: data.count,
          hasMore: pageNo * reviewState.pageSize < data.count,
          loading: false,
        }));
      } catch (error) {
        // apiErrorHandler(error)
        pageNo === 1 && toggleLoading(setReviewState);
      }
    },
    [id, reviewState.pageSize]
  );

  useEffect(() => {
    getReviews(1);
  }, [getReviews]);

  const getSessions = useCallback(
    async (pageNo = 1, status = "upcoming") => {
      try {
        setSessionState((prev) => ({
          ...prev,
          loading: true,
        }));
        let {
          data: { data },
        } = await getExpetGroupSessions(
          id,
          sessionState.pageSize,
          pageNo,
          status
        );
        // let {
        //   data: { data },
        // } = await getLearnerOneOnOneSessions(
        //   id,
        //   sessionState.pageSize,
        //   pageNo,
        //   status
        // );
        setSessionState((prev) => ({
          ...prev,
          data: pageNo > 1 ? prev.data.concat(data.sessions) : data.sessions,
          pageNo: pageNo,
          totalRecord: data.count,
          hasMore: pageNo * sessionState.pageSize < data.count,
          loading: pageNo === 1 ? prev.loading : false,
        }));
      } catch (error) {
        apiErrorHandler(error);
      } finally {
        pageNo === 1 &&
          setSessionState((prev) => ({
            ...prev,
            loading: false,
          }));
      }
    },
    [id, sessionState.pageSize]
  );

  useEffect(() => {
    getSessions(1);
  }, [getSessions]);
  return (
    <div>
      <Tabs
        tabs={state.tabs}
        activeTab={state.activeTab}
        onClickTab={toggleTab}
        className="border rounded"
      />
      <div className="border review-content-tab rounded mt-3">
        <MapSubComponents
          activeTab={state.activeTab}
          loading={state.loading}
          reviews={reviewState}
          fetchReviews={() => {
            getReviews(reviewState.pageNo + 1);
          }}
          sessionState={sessionState}
          fetchSessions={getSessions}
        />
      </div>
    </div>
  );
});

export default ExpertTabs;
