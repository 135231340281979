import Loader from 'components/common/Loader';
import { apiErrorHandler } from 'Helpers/utility';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { async_status } from 'redux/Actions/types';
import { RootState } from 'redux/Reducers';
import { getExpertRating, getSingleExpert } from 'services/expertService';
import About from './Components/About';
import { BookModal } from './Components/BookModal';
import ExpertTabs from './Components/ExpertTabs';
import Top from './Components/Top';
import { createSession } from 'services/sessionService';
import SuccessModal from './Components/SuccessModal';
import { getExpertBankAccountInfo } from 'services/expertService';

//  {
//    loggedInUser
//                 ? toggleModal()
//                 : (window.location.href = `/auth/login?callback=${window.location.pathname}`) */
//   }
const Expert: React.FC<any> = () => {
  const [state, setState] = useState<any>({
    modalOpen: false,
    successModalOpen: false,
    userData: {},
    loading: true
  });
  const [successData, setSuccessData] = useState({});
  const [searchParams] = useSearchParams();
  const paymentStatus = searchParams.get('status');
  const transactionId = searchParams.get('transaction_id');
  const { user } = useSelector((state: RootState) => state);
  const loggedInUser = useMemo(
    () => user.status === async_status.__LOADED__,
    [user.status]
  );
  const [accountInfo, setAccountInfo] = useState<any>({
    isAccountLinked: false,
    currency: ''
  });

  const { id } = useParams();
  const toggleModal = (param = 'modalOpen') => {
    setState((prev: any) => ({
      ...prev,
      [param]: !prev[param]
    }));
    // if (param !== 'modalOpen') {
    //   window.open(`/expert/${id}`, '_self');
    //   // setTimeout(() => {
    //   //   window.location.reload();
    //   // }, 500);
    // }
  };

  const toggleLoading = () => {
    setState((prev: any) => ({
      ...prev,
      loading: !prev.loading
    }));
  };

  // const getExpert = useCallback(async () => {
  //   try {
  //     let { data } = await getSingleExpert(id);
  //     setState((prev: any) => ({
  //       ...prev,
  //       userData: data,
  //       loading: false
  //     }));
  //   } catch (error) {
  //     apiErrorHandler(error);
  //     toggleLoading();
  //   }
  // }, [id]);

  // useEffect(() => {
  //   if (paymentStatus && paymentStatus === 'successful') {
  //     toggleModal('successModalOpen');
  //   }
  // }, [paymentStatus]);

  useEffect(() => {
    const getExpert = async () => {
      try {
        let { data } = await getSingleExpert(id);
        setState((prev: any) => ({
          ...prev,
          userData: data,
          loading: false
        }));
      } catch (error) {
        apiErrorHandler(error);
        toggleLoading();
      }
    };
    if (id !== '' || id !== null) {
      getExpert();
    }
  }, [id]);

  useEffect(() => {
    const callOnSuccess = async () => {
      const dataToSend = {
        transactionId: transactionId,
        paymentType: 'flutterwave',
        expertId: id,
        currency: 'NGN',
        fromDate: '',
        toDate: ''
      };
      try {
        let {
          data: {
            data: { session }
          }
        } = await createSession(dataToSend);
        setSuccessData(session);
        toggleModal('successModalOpen');
      } catch (error) {
        apiErrorHandler(error);
      }
      // finally {
      //   getExpert();
      // }
    };

    if (paymentStatus === 'successful') {
      callOnSuccess();
    }
  }, [paymentStatus]);

  useEffect(() => {
    let mounted = true;
    const fetchBankAccountInfo = async (id: string | undefined) => {
      try {
        const { data } = await getExpertBankAccountInfo(id);
        if (typeof data === 'object') {
          if (mounted) {
            setAccountInfo({
              isAccountLinked: data?.data?.isAccountLinked,
              currency: data.data?.currency
            });
          }
        }
      } catch (err: any) {
        setAccountInfo({
          isAccountLinked: false,
          currency: ''
        });
      }
    };
    fetchBankAccountInfo(id);
    return () => {
      mounted = false;
    };
  }, [id]);
  const [rating, setRating] = useState(0);
  // const token = localStorage.getItem("authToken");
  useEffect(() => {
    const rating = async () => {
      try {
        const { data } = await getExpertRating(id);
        setRating(data?.data[0]?.avgRating);
      } catch (err) {
        setRating(0);
      }
    };
    rating();
  }, [id]);

  return (
    <div id='expert'>
      {state.loading ? (
        <div className='vh-80'>
          <Loader />
        </div>
      ) : (
        <>
          <Top
            toggleModal={toggleModal}
            id={id}
            accountInfo={accountInfo}
            data={state.userData}
            loggedInUser={loggedInUser}
          />
          <Container className='mt-5 mt-md-0 '>
            <Row>
              <Col md='6'>
                <About
                  data={{ ...state.userData, rating }}
                  currency={state?.userData?.accountCurrency}
                />
              </Col>
              <Col md='6'>
                <ExpertTabs id={id} />
              </Col>
            </Row>
          </Container>
        </>
      )}
      <BookModal
        isOpen={state.modalOpen}
        toggleModal={() => toggleModal()}
        transactionId={transactionId}
        paymentStatus={paymentStatus}
        setExpertState={setState}
        id={id}
        setSuccessData={setSuccessData}
        availability={state.userData?.profilePages?.availabilityPage}
        expertInfo={state.userData?.profilePages?.registrationPage}
        userData={user?.data?.data}
        accountInfo={accountInfo}
      />

      <SuccessModal
        isOpen={state.successModalOpen}
        toggleModal={() => {
          toggleModal('successModalOpen');
          window.open(`/expert/${id}`, '_self');
        }}
        data={successData}
      />
    </div>
  );
};

export default Expert;
