import { getAllExperts } from 'services/expertService';
import {
  EXPERT_DATA_FAILED,
  EXPERT_DATA_SUCCESS,
  EXPERT_DATA_LOADING
} from './types';

export const fetchExperts = () => async (dispatch: any) => {
  dispatch({
    type: EXPERT_DATA_LOADING,
    payload: {}
  });
  try {
    let { data } = await getAllExperts(30, 1);
    console.log(data, 'data');
    dispatch({
      type: EXPERT_DATA_SUCCESS,
      payload: { data: data.data.expert }
    });
  } catch (error) {
    dispatch({
      type: EXPERT_DATA_FAILED,
      payload: {}
    });
  }
};
