import React from "react";

function SendIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      fill="none"
      viewBox="0 0 23 22"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.096 1L14.3 21l-3.883-9L1.68 8l19.415-7z"
      ></path>
    </svg>
  );
}

export default SendIcon;