import InfiniteScrollComponent from "react-infinite-scroll-component";

import React from "react";
import { Spinner } from "reactstrap";

const InfiniteScroll: React.FC<any> = ({
  scrollableTarget = "main",
  dataLength,
  hasMore,
  next,
  children,
  ...props
}) => {
  return (
    <InfiniteScrollComponent
      scrollableTarget={scrollableTarget}
      dataLength={dataLength} //This is important field to render the next data
      next={next}
      style={{ overflow: "hidden" }}
      hasMore={hasMore}
      useWindow={false}
      loader={
        <div className="text-center">
          <Spinner color="yellow">
            <></>
          </Spinner>
        </div>
      }
      {...props}
    >
      {children}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
