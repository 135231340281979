import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Button, Card, CardBody } from 'reactstrap'

const Verify = () => {
    const [value, setValue] = useState<string>('')
    return (
        <Card>
            <CardBody>
                <h6>Enable text notification to never miss any of your calls</h6>
                <PhoneInput
                    className='phone-input'
                    placeholder="Enter phone number"
                    value={value}
                    onChange={(e) => {
                        if (e)
                            setValue(e)
                    }}
                    onCountryChange={(e) => console.log(e)}
                />
                <small className='d-block text-lightBlue cursor-pointer my-3'>resend verification code</small>
                <Button color='lightBlue' block>Verify</Button>
            </CardBody>
        </Card>
    )
}

export default Verify