import { fetchUserDetails } from "services/authService"
import { fetchUserWishlist } from "services/userService"
import { USER_DATA_FAILED, USER_DATA_LOADING, USER_DATA_SUCCESS, WISHLIST_DATA_FAILED, WISHLIST_DATA_LOADING, WISHLIST_DATA_SUCCESS } from "./types"

export const fetchUser = (hideLoading?: boolean) => async (dispatch: any) => {
    if (!hideLoading) {
        dispatch({
            type: USER_DATA_LOADING,
            payload: {}
        })
    }
    try {
        let { data } = await fetchUserDetails()
        dispatch({
            type: USER_DATA_SUCCESS,
            payload: { data: data }
        })
    } catch (e) {
        dispatch({
            type: USER_DATA_FAILED,
            payload: {}
        })
    }
}

export const fetchWishlist = (hideLoading?: boolean) => async (dispatch: any) => {
    if (!hideLoading) {
        dispatch({
            type: WISHLIST_DATA_LOADING,
            payload: {}
        })
    }
    try {
        let { data } = await fetchUserWishlist()
        dispatch({
            type: WISHLIST_DATA_SUCCESS,
            payload: { data: data }
        })
    } catch (e) {
        dispatch({
            type: WISHLIST_DATA_FAILED,
            payload: {}
        })
    }
}


export const updateUser = (data: any) => (dispatch: any) => {
    dispatch({
        type: USER_DATA_SUCCESS,
        payload: { data: data }
    });
}