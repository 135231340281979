import React from 'react';
import style from './Index.module.css';
import { useState } from 'react';
import { VerifyOtp, ResendOtp } from '../../../services/authService';
import Loader from '../../../components/Spinner/Index';
import { toast } from 'react-toastify';
const VerificationInput = () => {
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const submitHandler = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    VerifyOtp({ otp })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = '/overview';
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.message;
        toast.error(error);
        setIsLoading(false);
      });
  };

  const pattern = new RegExp(/^\d+$/);

  const valueItems = React.useMemo(() => {
    const valuesArray = otp.split('');
    const items: string[] = [];
    for (let i = 0; i < 4; i++) {
      const char = valuesArray[i];
      if (pattern.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  }, [otp]);

  const focusToNextInput = (target: HTMLInputElement) => {
    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const focusToPreviousInput = (target: HTMLInputElement) => {
    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const targetValueDigit = pattern.test(targetValue);
    target.setSelectionRange(0, targetValue.length);
    if (!targetValueDigit && targetValue !== '') {
      return;
    }
    targetValue = targetValueDigit ? targetValue : ' ';
    const targetValueLength = targetValue.length;
    if (targetValueLength === 1) {
      const newValue =
        otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
      setOtp(newValue);

      if (!targetValueDigit) {
        return;
      }
      focusToNextInput(target);
    } else if (targetValueLength === 4) {
      setOtp(targetValue);
      target.blur();
    }
  };

  const inputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { key } = e;
    const targetValue = target.value;
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      focusToNextInput(target);
      return;
    }
    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      focusToPreviousInput(target);
      return;
    }
    target.setSelectionRange(0, targetValue.length);
    if (key !== 'Backspace' || target.value !== '') {
      return;
    }
    focusToPreviousInput(target);
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    target.setSelectionRange(0, target.value.length);
  };

  return (
    <form onSubmit={submitHandler}>
      <div className={style.row}>
        {valueItems.map((el, idx) => (
          <input
            required
            key={idx}
            maxLength={6}
            autoComplete='one-time-code'
            inputMode='numeric'
            pattern='\d{1}'
            className={style.input_code}
            onChange={(e) => onChange(e, idx)}
            type='text'
            value={el}
            onKeyDown={inputKeyDown}
            onFocus={inputOnFocus}
          />
        ))}
      </div>
      <div className={style.login_btn}>
        <button>{isLoading ? <Loader /> : 'Verify'}</button>
      </div>
    </form>
  );
};

const getOtp = () => {
  ResendOtp()
    .then((res) => {
      toast.success(res.data.data?.message);
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message);
    });
};

const Index = () => {
  return (
    <div className={style.container}>
      <div className={style.content_wrapper}>
        <div className={style.login_wrapper}>
          <h2 className={style.header}>Enter Otp Code</h2>
          <p>Enter the 4 digit code sent to your email</p>
          <VerificationInput />
          <div className={style.reset_link}>
            <span>Didn't Receive Code ? </span>
            <span onClick={() => getOtp()}>Resend </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
