import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface EmbeddedVideoProps {
    writtenLink: string;
}

const EmbeddedVideo = ({ writtenLink }: EmbeddedVideoProps): JSX.Element => {

    const [embeddingLink, setEmbeddingLink] = useState('');

    useEffect(() => {
        getEmbeddedLink(writtenLink);
    }, [writtenLink]);

    const getEmbeddedLink = async (link: string) => {
        const embeddedUrlApi = `https://www.youtube.com/oembed?url=${link}&format=json`;
        const { data } = await axios.get(embeddedUrlApi);
        const parser = new DOMParser();
        const iframe = parser.parseFromString(data.html, 'text/html')

        // console.log({ iframe: iframe.body.childNodes[0].src });
        //@ts-ignore
        setEmbeddingLink(iframe.body.childNodes[0].src)
    }

    return (
        <div className="embed-responsive embed-responsive-16by9">
            <iframe title="Profile Video" className="embed-responsive-item" src={embeddingLink} allowFullScreen></iframe>
        </div>
    )
}

export default EmbeddedVideo