import React from "react";
import EmbeddedVideo from "./EmbeddedVideo";
import { Rating } from "@mui/material";

const About: React.FC<any> = ({ data, currency }) => {
  let quoteCurrency = "";
  if (currency === "NG") {
    quoteCurrency = "₦";
  } else if (currency === "US") {
    quoteCurrency = "$";
  } else if (currency === "CA") {
    quoteCurrency = "$";
  } else if (currency === "GB") {
    quoteCurrency = "£";
  }
  // const videoLink = data?.profilePages?.profileMediaPage?.profileVideoAttachedLink || data?.profilePages?.profileMediaPage?.profileVideoWrittenLink;
  const profileVideoWrittenLink: string =
    data?.profilePages?.profileMediaPage?.profileVideoWrittenLink;
  const profileVideoAttachedLink: string =
    data?.profilePages?.profileMediaPage?.profileVideoAttachedLink;

  return (
    <>
      <div className="border-bottom pb-3">
        <div>
          <h5>About me</h5>
          <p className="font-weight-lighter">
            {data.profilePages?.professionalInformationPage
              ?.professionalSummary || "N/A"}
          </p>
        </div>
        <div className="my-5">
          <h5>Video Summary</h5>
          {profileVideoAttachedLink ? (
            <video controls width="100%">
              <source src={profileVideoAttachedLink} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          ) : profileVideoWrittenLink ? (
            // <a href={profileVideoWrittenLink} target="_blank" rel="noreferrer">
            //   View Video
            // </a>
            <EmbeddedVideo writtenLink={profileVideoWrittenLink} />
          ) : (
            "N/A"
          )}
        </div>
      </div>
      <div>
        <div className="my-5">
          <h5>Industry</h5>
          <p className="font-weight-lighter">
            {data.profilePages?.professionalInformationPage?.industryName ||
              "N/A"}
          </p>
        </div>
        {/* <div>
                    <h5>Socials</h5>
                    <p className='font-weight-lighter'>{data.profilePages?.professionalInformationPage?.linkedInProfileUrl || "N/A"}</p>
                </div> */}
      </div>
      <div className="">
        <h5>Rating</h5>
        <div className="d-flex g-2">
          <Rating name="read-only" value={data.rating} readOnly />(
          <span>{data.rating}</span>)
        </div>
      </div>
      <div>
        <div className="my-5">
          <h5>Session Pricing</h5>
          <p className="font-weight-lighter">
            1:1 session: price/session (A session is 30 minutes) :{" "}
            <span className="text-primary">
              {" "}
              {`${currency?.toUpperCase()}`} ({quoteCurrency}
              {data?.profilePages?.availabilityPage?.hourlyRate})/session
            </span>
          </p>
          {/* <p className='font-weight-lighter'>1:1 Multiple sessions : <span className="text-primary"> ${data?.profilePages?.availabilityPage?.hourlyRate}/session</span></p> */}
        </div>
      </div>
    </>
  );
};

export default About;
