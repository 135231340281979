import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { BuildOut, ResearchLab, UpTech } from '../Icons';

const CanDo: React.FC<any> = () => {
  const [img, setImg] = useState<any>('1');
  useEffect(() => {
    let intv: any = null;
    intv = setInterval(() => {
      setImg((prev: string) => {
        return prev === '1' ? '2' : '1';
      });
    }, 5000);

    return () => clearInterval(intv);
  }, []);
  return (
    <Container id='can-do' className='my-5 py-5'>
      <Row>
        <Col md='6'>
          <h1 className='landing-large-header'>
            What coachmie can <br /> do for you?
          </h1>
          {[
            {
              icon: UpTech,
              header: 'Get into tech, grow faster',
              content:
                'Get ahead in your career with less pitfalls learning from experts around the world'
            },
            {
              icon: BuildOut,
              header: 'Build with Builders',
              content:
                'From ideation to launch, build your products from scratch with experts leadings'
            },
            {
              icon: ResearchLab,
              header: 'Get into research programs',
              content:
                'Get into research programs abroad, complete your research projects and more faster'
            }
          ].map(({ icon: Icon, header, content }) => (
            <div className='d-flex align-items-center my-5' key={header}>
              <div className='mr-3'>
                <Icon />
              </div>
              <div>
                <h5 className='m-0'>{header}</h5>
                <p className='small text-muted m-0'>{content}</p>
              </div>
            </div>
          ))}
        </Col>
        <Col md='6'>
          <img
            src={require(`../Images/IMG-${img}.png`)}
            alt=''
            style={{ maxWidth: '400px' }}
            className='img-fluid w-100'
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CanDo;
