import FormsWrapper, { showFieldError } from 'components/Forms/Formik';
import React from 'react';
import * as Yup from 'yup';
import { Col, Input, Label, Row } from 'reactstrap';
import Button from 'components/common/Button';
import { DomainsComponent } from 'components/common/Domains';
import { listTimeZones } from 'timezone-support';
import { useSelector } from 'react-redux';
import { fetchCountries } from 'redux/Actions/countryAction';
import { RootState } from 'redux/Reducers';
import PasswordInput from 'components/common/PasswordInput';
import { allTimeZones } from './time_zone';
interface formInterface {
  formControl: any;
  onSubmit?: any;
  onChange?: any;
  formName: any;
  updateStep?: any;
}
const Form: React.FC<formInterface | any> = ({
  formControl,
  onChange,
  onSubmit,
  formName,
  updateStep,
  ...props
}) => {
  const {
    loadingDomains,
    topDomains,
    userDomains,
    topDomainClick,
    onRemoveDomain,
    onDomainSubmit,
    formProfessional
  } = props;
  let obj: Record<any, any> = {};
  if (formControl.firstName === '') {
    obj.firstName = Yup.string().required('Required');
  }

  if (formControl.lastName === '') {
    obj.lastName = Yup.string().required('Required');
  }

  if (formControl.email === '') {
    obj.email = Yup.string().email().required('Required');
  }

  if (formControl.password === '' || formControl.password.length < 8) {
    obj.password = Yup.string()
      .required('Required')
      .min(8, 'Minimum of 8 characters');
  }

  if (formControl.country === '') {
    obj.country = Yup.string().required('Required');
  }
  if (formControl.timezone === '') {
    obj.country = Yup.string().required('Required');
  }
  if (
    formControl.confirmPassword === '' ||
    formControl.confirmPassword !== formControl.password
  ) {
    obj.confirmPassword = Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Password dont match');
  }

  const validation = Yup.object().shape(obj);
  const {
    countries: { data: countriesData }
  } = useSelector((state: RootState) => {
    return state;
  });

  return (
    <FormsWrapper
      values={formControl}
      handleSubmit={onSubmit}
      handleChange={onChange}
      validationSchema={validation}>
      {(props: {
        values: any;
        isValid: any;
        touched: any;
        errors: any;
        handleBlur: any;
        handleChange: any;
        handleSubmit: any;
        isSubmitting: any;
      }) => {
        const { touched, errors, handleChange, isSubmitting, handleSubmit } =
          props;
        return (
          <>
            <form name={formName} onChange={onChange} onSubmit={handleSubmit}>
              <Row>
                <Col md='6'>
                  <Label className='small text-muted mb-0 mt-3'>
                    First Name
                  </Label>
                  <Input
                    type='text'
                    name='firstName'
                    // onBlur={handleBlur}
                    value={formControl.firstName}
                    invalid={errors.firstName && touched.firstName}
                    onChange={handleChange}
                  />
                  {showFieldError('firstName', errors, touched)}
                </Col>
                <Col md='6'>
                  <Label className='small text-muted mb-0 mt-3'>
                    Last Name
                  </Label>
                  <Input
                    type='text'
                    name='lastName'
                    // onBlur={handleBlur}
                    value={formControl.lastName}
                    invalid={touched.lastName && errors.lastName}
                    onChange={handleChange}
                  />
                  {showFieldError('lastName', errors, touched)}
                </Col>
              </Row>
              <Row>
                <Col md='12'>
                  <Label className='small text-muted mb-0 mt-3'>
                    Email Address
                  </Label>
                  <Input
                    type='email'
                    name='email'
                    // onBlur={handleBlur}
                    value={formControl.email}
                    invalid={touched.email && errors.email}
                    onChange={handleChange}
                  />
                  {showFieldError('email', errors, touched)}
                </Col>
              </Row>

              <Row>
                <Col md='12'>
                  <Label className='small text-muted mb-0 mt-3'>Country</Label>
                  <Input
                    type='select'
                    name='country'
                    className='form-control'
                    // onBlur={handleBlur}
                    value={formControl.country}
                    invalid={errors.country && touched.country}
                    onChange={handleChange}>
                    <option>Select Country</option>
                    {countriesData.map((country: Record<any, any>) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Input>
                  {showFieldError('country', errors, touched)}
                </Col>
              </Row>

              <Row>
                <Col md='12'>
                  <Label className='small text-muted mb-0 mt-3'>
                    Time Zone
                  </Label>
                  <Input
                    type='select'
                    name='timezone'
                    className='form-control'
                    // onBlur={handleBlur}
                    value={formControl.timezone}
                    invalid={errors.timezone && touched.timezone}
                    onChange={handleChange}>
                    <option selected={true} value='undefined'></option>
                    {allTimeZones.map((timezone: any, id: any) => (
                      <option key={id} value={timezone}>
                        {timezone}
                      </option>
                    ))}
                  </Input>
                  {showFieldError('timezone', errors, touched)}
                </Col>
              </Row>

              <Row>
                <Col md='12'>
                  <Label className='small text-muted mb-0 mt-3'>Password</Label>
                  {/* <Input
                                            type="password"
                                            name="password"
                                            onBlur={handleBlur}
                                            value={formControl.password}
                                            invalid={errors.password && touched.password}
                                            onChange={handleChange} /> */}
                  <PasswordInput
                    name='password'
                    // onBlur={handleBlur}
                    value={formControl.password}
                    invalid={errors.password && touched.password}
                    onChange={handleChange}
                  />
                  {showFieldError('password', errors, touched)}
                </Col>
                <Col md='12'>
                  <Label className='small text-muted mb-0 mt-3'>
                    Confirm Password
                  </Label>
                  {/* <Input
                                            type="password"
                                            name="confirmPassword"
                                            // onBlur={handleBlur}
                                            value={formControl.confirmPassword}
                                            invalid={errors.confirmPassword && touched.confirmPassword}
                                            onChange={handleChange} /> */}

                  <PasswordInput
                    name='confirmPassword'
                    // onBlur={handleBlur}
                    value={formControl.confirmPassword}
                    invalid={errors.confirmPassword && touched.confirmPassword}
                    onChange={handleChange}
                  />

                  {showFieldError('confirmPassword', errors, touched)}
                </Col>
              </Row>
            </form>
            <p className='text-muted mt-4 mb-n2'>
              What tech niche do you want to learn or are you interested in?
              (Click the plus button to add into your preferred domain)
            </p>
            <DomainsComponent
              loading={loadingDomains}
              topDomains={topDomains}
              userDomains={userDomains}
              topDomainClick={topDomainClick}
              onRemoveDomain={onRemoveDomain}
              handleChange={onChange}
              onFormSubmit={onDomainSubmit}
              formControl={formProfessional}
            />
            <Row as='row'>
              <Col sm='12' className='my-3 d-flex'>
                <Button
                  color='transparent'
                  outline
                  onClick={() => updateStep(1)}
                  text='Back'
                />

                <Button
                  color='grayScale'
                  className='btn-floating small ml-auto'
                  text='Create Account'
                  type='submit'
                  loading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </Col>
            </Row>
            <p className='text-muted'>
              By requesting an invite, you agree to Coachmie's{' '}
              <a
                target='_blank'
                href={`${process.env.REACT_APP_EXPERT_APP_URL}privacy`}
                rel='noreferrer'>
                {' '}
                Privacy Policy{' '}
              </a>
              and{' '}
              <a
                target='_blank'
                href={`${process.env.REACT_APP_EXPERT_APP_URL}terms`}
                rel='noreferrer'>
                Service Terms
              </a>
              .
            </p>
          </>
        );
      }}
    </FormsWrapper>
  );
};

export default Form;
