import style from './Index.module.css';
import Input from '../../../components/common/Input/Index';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import Domain from './Component/Domain/Index';
import { Link } from 'react-router-dom';
import Logo from 'components/common/Logo';

type dataValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  country: string;
  timezone: string;
  domains: string[];
};

const schema = Joi.object({
  firstName: Joi.string().required().label('FirstName'),
  lastName: Joi.string().required().label('LastName'),
  country: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email address'),
  password: Joi.string().min(8).required(),
  confirmPassword: Joi.any()
    .equal(Joi.ref('password'))
    .required()
    .label('Confirm password')
    .messages({ 'any.only': '{{#label}} must match password' }),
  timezone: Joi.string().required(),
  domains: Joi.array().required()
});

const Index = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<dataValues>({
    resolver: joiResolver(schema)
  });

  const data = (data: dataValues) => {};
  return (
    <div className={style.container}>
      <div className={style.logo}>
        <Logo />
      </div>
      <div className={style.form_modal}>
        <h1>Join Coachmie Community</h1>
        <p>Just a few more information we need and you’ll be on your way</p>
        <form onSubmit={handleSubmit(data)}>
          <div className={style.input_group_row}>
            <div className={style.input_group_col}>
              <Input
                {...register('firstName')}
                type='text'
                labeltext='firstName'
              />
              {errors?.firstName?.message && (
                <small className={style.validation_message}>
                  {errors?.firstName?.message}
                </small>
              )}
            </div>
            <div className={style.input_group_col}>
              <Input
                {...register('lastName')}
                type='text'
                labeltext='lastName'
              />
              {errors?.lastName?.message && (
                <small className={style.validation_message}>
                  {errors?.lastName?.message}
                </small>
              )}
            </div>
          </div>
          <div className={style.input_group}>
            <Input {...register('email')} type='text' labeltext='last Name' />
            {errors?.email?.message && (
              <small className={style.validation_message}>
                {errors?.email?.message}
              </small>
            )}
          </div>
          <div className={style.input_group}>
            <Input type='dropdown' />
            {/* <Input {...register('country')} type='text' labeltext='Country' /> */}
          </div>
          <div className={style.input_group}>
            <Input
              {...register('timezone')}
              type='text'
              labeltext='Time Zone'
            />
          </div>
          <div className={style.input_group}>
            <Input
              {...register('timezone')}
              type='password-hidden'
              labeltext='Password'
            />
          </div>
          <div className={style.input_group}>
            <Input
              {...register('timezone')}
              type='password-hidden'
              labeltext='Confirm Password'
            />
          </div>
          <div className={style.domains}>
            <p>
              What tech niche do you want to learn or are you interested in?
            </p>
            <p>Top Domains</p>
            <div className={style.domains_category}>
              {[1, 2, 3].map((el, index) => (
                <Domain key={index} />
              ))}
            </div>
          </div>
          <div
            style={{
              marginTop: '0.6rem'
            }}>
            <p>Enter your Prefered domains and enter hit 'enter key'</p>
            <Input type='text-area' />
          </div>
          <div className={style.nav_btns}>
            <Link to='/'>Go Back</Link>
            <button className={style.create_account_btn}>Create Account</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
