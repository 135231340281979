import { apiErrorHandler, copyToClipboard } from 'Helpers/utility';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import CustomModal from 'components/common/CustomModal';
import SuccessModal from './Components/SuccessModal';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  enrollGroupSession,
  getSingleGroupSession
} from 'services/sessionService';
import Loader from 'components/common/Loader';
import moment from 'moment';
import Button from 'components/common/Button';
import Payment from 'components/Payment/Payment';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import PopoverComponent from 'components/common/PopoverComponent';
import { PaymentSelector } from 'components/Payment/PaymentSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducers';
import { async_status } from 'redux/Actions/types';
import { handleLogout } from 'services/authService';
import { ApiKeysContext } from 'Contexts/ApiKeysContext';
import { getExpertBankAccountInfo } from 'services/expertService';
import { formatMoney } from 'utils/format_money';

const SessionDetails = () => {
  const {
    keys: { data: rate }
  } = useContext(ApiKeysContext);
  const { id } = useParams();
  const copyRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state);
  const handleGoBack = useCallback(() => navigate(-1), [navigate]);
  const loggedInUser = user.status === async_status.__LOADED__;
  const [state, setState] = useState<any>({
    showShare: false,
    showModal: false,
    showPaymentModal: false,
    loading: true,
    submitting: false,
    data: {},
    amountToPay: undefined,
    currency: '',
    payment_intent_client_secret: '',
    copyButtonText: 'Copy'
  });
  const onCopy = () => {
    copyToClipboard(copyRef);
    setState((prev: any) => ({
      ...prev,
      copyButtonText: 'Copied'
    }));

    setTimeout(() => {
      setState((prev: any) => ({
        ...prev,
        copyButtonText: 'Copy'
      }));
    }, 1000);
    toast.success('Copied to clipboard');
  };

  const [currency, setCurrency] = useState<any>({
    Canada: {
      paymentType: '',
      currency: ''
    },
    Usa: {
      paymentType: '',
      currency: ''
    },
    Nigeria: {
      paymentType: '',
      currency: ''
    }
  });
  const [foreignCurrencyBufferVal, setForeignCurrencyBuffVal] = useState(0);
  const [countryName, setCountryName] = useState('');

  const toggleModal = (param = 'showModal') => {
    if (param === 'hidePaymentModal') {
      setState((prev: any) => ({
        ...prev,
        showPaymentModal: false
      }));
    }
    if (param === 'showShare') {
      setState((prev: any) => ({
        ...prev,
        showShare: true,
        showPaymentModal: false,
        showModal: false
      }));
    }
    if (param === 'showPaymentModal') {
      setStep(1);
      setState((prev: any) => {
        return {
          ...prev,
          showPaymentModal: true
        };
      });
    }
    if (param === 'hideSuccessMsg') {
      setState((prev: any) => ({
        ...prev,
        showModal: false,
        showPaymentModal: false
      }));
    }
    setCurrency({
      Canada: {
        paymentType: '',
        currency: ''
      },
      Usa: {
        paymentType: '',
        currency: ''
      },
      Nigeria: {
        paymentType: '',
        currency: ''
      }
    });
    // setPaymentType("");
  };
  // const fetchDetails = useCallback(async () => {
  //     try {
  //         let { data: { data } } = await getSingleGroupSession(id);
  //         const d= await getSingleGroupSession(id);
  //         console.log(d,"getSingleSession")
  //         setState((prev: any) => ({
  //             ...prev,
  //             loading: false,
  //             data: data
  //         }))
  //     } catch (error) {
  //         apiErrorHandler(error)
  //         // handleGoBack()
  //     }
  // }, [id])
  const [accountInfo, setAccountInfo] = useState<any>({
    isAccountLinked: false,
    currency: ''
  });
  const [step, setStep] = useState(0);
  const [Fee, setFee] = useState<any>(undefined);
  const [sum, totalSum] = useState('');
  const [expertId, setExpertId] = useState('');
  // paymentIntent: string | undefined = undefined,
  // callBackFn?: () => void,
  // type: string
  const [loading, setIsLoading] = useState(false);
  console.log(step, 'Setep');
  const doEnrollFree = async () => {
    const dataToSend = {
      title: state.data?.title,
      fromDate: state.data?.fromDate,
      // fee: state.data.fee,
      toDate: state.data?.toDate,
      expertId: state.data?.expertId,
      callbackUrl: `${window.location.origin}${window.location.pathname}`
    };
    if (!loggedInUser) {
      handleLogout(`/auth/login?callback=${window.location.pathname}`);
    }
    try {
      setIsLoading(true);
      let { data } = await enrollGroupSession(id, dataToSend);
      setState((prev: any) => ({
        ...prev,
        showModal: true
      }));
      if (data.status === 'success') {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'error');
      setIsLoading(false);
      apiErrorHandler(err);
    }
  };
  let quoteCurrency;
  if (accountInfo.currency === 'NGN') {
    quoteCurrency = '₦';
  } else if (accountInfo.currency === 'usd') {
    quoteCurrency = '$';
  } else if (accountInfo.currency === 'cad') {
    quoteCurrency = '$';
  }
  const doEnroll = useCallback(
    async (
      paymentType: string,
      paymentIntent: string | undefined = undefined,
      Currency: string,
      name: string,
      callBackFn?: () => void
    ) => {
      console.log(Currency, 'Currency');
      if (!loggedInUser) {
        handleLogout(`/auth/login?callback=${window.location.pathname}`);
      }
      if (
        state.payment_intent_client_secret === '' &&
        paymentType === 'stripe'
      ) {
        setStep(2);
      }
      setCurrency({
        [name]: {
          currency: Currency,
          paymentType
        }
      });
      setState((prev: any) => ({
        ...prev,
        submitting: true
      }));
      let dataToSend: any = {
        paymentType,
        currency: Currency
      };
      if (paymentIntent !== undefined) {
        dataToSend.transactionId = paymentIntent;
      }

      try {
        let { data } = await enrollGroupSession(id, dataToSend);
        setState((prev: any) => {
          let updateObj = {
            ...prev,
            submitting: false
          };
          if (data?.data?.payment_intent_client_secret) {
            setCurrency({
              [name]: {
                currency: Currency,
                paymentType
              }
            });
            setCountryName(name);
            updateObj.payment_intent_client_secret =
              data?.data?.payment_intent_client_secret;
            updateObj.showPaymentModal = true;
            return updateObj;
          } else {
            updateObj.showModal = true;
            updateObj.showPaymentModal = false;
            return updateObj;
          }
        });
      } catch (error) {
        console.log(error, 'error');
        apiErrorHandler(error);
        setState((prev: any) => ({
          ...prev,
          submitting: false
        }));
      }

      // finally {
      //   if (callBackFn) {
      //     callBackFn();
      //   }
      // }
    },
    [id, loggedInUser, state.payment_intent_client_secret]
  );

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        let {
          data: { data }
        } = await getSingleGroupSession(id);
        setExpertId(data.expertId);
        setState((prev: any) => ({
          ...prev,
          loading: false,
          rate,
          data: data
        }));
        totalSum(data.fee);
      } catch (error) {
        apiErrorHandler(error);
        // handleGoBack()
      }
    };
    fetchDetails();
  }, [id, rate]);
  useEffect(() => {
    const paymentCurrency = currency[countryName]?.currency;
    if (accountInfo.currency === 'NGN' && paymentCurrency === 'cad') {
      const totalSessionFee =
        +state.data.fee / +rate.cadRate + +rate.bufferValue;
      setFee(totalSessionFee.toFixed(2));
    }
    if (accountInfo.currency === 'NGN' && paymentCurrency === 'usd') {
      const totalSessionFee =
        +state.data.fee / +rate.usdRate + +rate.bufferValue;
      setFee(totalSessionFee.toFixed(2));
    }
    if (accountInfo.currency === 'NGN' && paymentCurrency === 'gbp') {
      const totalSessionFee =
        +state.data.fee / +rate.gbpRate + +rate.bufferValue;
      setFee(totalSessionFee.toFixed(2));
    }
    if (accountInfo === 'usd' && paymentCurrency === 'usd') {
      const totalSessionFee = +state.data.fee;
      setFee(totalSessionFee.toFixed(2));
    }
    if (accountInfo === 'gbp' && paymentCurrency === 'gbp') {
      const totalSessionFee = +state.data.fee;
      setFee(totalSessionFee.toFixed(2));
    }
    if (accountInfo.currency === 'cad' && paymentCurrency === 'cad') {
      const totalSessionFee = +state.data.fee;
      setFee(totalSessionFee.toFixed(2));
    }
    if (accountInfo.currency === 'cad' && paymentCurrency === 'gbp') {
      const totalSessionFee = +state.data.fee;
      setFee(totalSessionFee.toFixed(2));
    }
  }, [
    state,
    currency,
    countryName,
    accountInfo,
    rate.bufferValue,
    rate.cadRate,
    rate.gbpRate,
    rate.usdRate,
    Fee,
    doEnroll
  ]);
  console.log(state, 'state');
  useEffect(() => {
    const fetchBankAccountInfo = async (id: string | undefined) => {
      try {
        const { data } = await getExpertBankAccountInfo(id);
        if (typeof data === 'object') {
          setAccountInfo({
            isAccountLinked: data?.data?.isAccountLinked,
            currency: data.data?.currency
          });
        }
      } catch (err: any) {
        setAccountInfo({
          isAccountLinked: false,
          currency: ''
        });
      }
    };
    fetchBankAccountInfo(expertId);
    // return () => {
    //   mounted = false;
    // };
  }, [expertId]);
  const [searchParams] = useSearchParams();
  const paymentCurrency = currency[countryName]?.currency;

  let paymentCurrencySymbol;
  if (paymentCurrency === 'usd') {
    paymentCurrencySymbol = '$';
  } else if (paymentCurrency === 'cad') {
    paymentCurrencySymbol = '$';
  } else if (paymentCurrency === 'gbp') {
    paymentCurrencySymbol = '£';
  }

  const paymentStatus = searchParams.get('status');
  const transactionId = searchParams.get('transaction_id');

  const callOnSuccess = useCallback(async () => {
    const dataToSend = {
      transactionId: transactionId,
      paymentType: 'flutterwave',
      currency: 'NGN'
    };
    try {
      await enrollGroupSession(id, dataToSend);
      setState((prev: any) => {
        return {
          ...prev,
          submitting: false,
          showModal: true,
          showPaymentModal: false
        };
        // updateObj.showModal = true;
        // updateObj.showPaymentModal = false;
      });
      // setState((prev: any) => {
      //   let updateObj = {
      //     ...prev,
      //     submitting: false,
      //   };
      //   updateObj.showModal = true;
      //   updateObj.showPaymentModal = false;
      //   window.open(`/group-sessions/${id}`);
      //   return updateObj;
      // });
    } catch (error) {
      setState((prev: any) => ({
        ...prev,
        submitting: false
      }));
    }
  }, [id, transactionId]);
  useEffect(() => {
    if (
      paymentStatus &&
      (paymentStatus === 'successful' || paymentStatus === 'completed')
    ) {
      callOnSuccess();
      // setTimeout(() => {
      //   window.open(`/group-sessions/${id}`);
      // }, 1000);
    }
  }, [callOnSuccess, paymentStatus]);

  return (
    <div id='sessions'>
      {loggedInUser && (
        <Container>
          <Row>
            <Col md='6'>
              <h1 className='page-header'>Session details</h1>
            </Col>
            <Col md='6' className='text-left text-md-right'>
              <Button
                color='white'
                className=' py-2 px-4'
                onClick={handleGoBack}
                text='< Back'
              />
            </Col>
          </Row>
        </Container>
      )}
      <Card className='my-3'>
        <CardBody>
          {state.loading ? (
            <div style={{ height: '50vh' }}>
              {' '}
              <Loader />{' '}
            </div>
          ) : (
            <Container>
              <Row className='border-bottom pb-2'>
                <Col md='6'>
                  <h2>{state.data?.title}</h2>
                </Col>
                <Col md='6' className='text-left text-md-right'>
                  <p
                    ref={copyRef}
                    className='mb-0  d-none w-100 overflow small text-grayScale'>
                    {window.location.origin}/sessions/{id}
                  </p>
                  <Button
                    color='primary'
                    className=' py-2 px-4 mr-2'
                    disabled={state.copyButtonText === 'Copied' ? 1 : 0}
                    onClick={onCopy}
                    text={state.copyButtonText}
                  />
                  <Button
                    color='darkBlue'
                    id='share'
                    className=' py-2 px-3'
                    text='Share'
                  />
                  <div className='d-flex'>
                    <PopoverComponent
                      isOpen={state.showShare}
                      id='share'
                      toggle={() => toggleModal('showShare')}>
                      <TwitterShareButton
                        url={`${window.location.origin}/sessions/{id}`}
                        title={'Checkout this session'}>
                        <TwitterIcon />
                      </TwitterShareButton>
                      <WhatsappShareButton
                        url={`${window.location.origin}/sessions/{id}`}
                        title={'Checkout this session'}>
                        <WhatsappIcon />
                      </WhatsappShareButton>
                      <FacebookShareButton
                        url={`${window.location.origin}/sessions/{id}`}
                        title={'Checkout this session'}>
                        <FacebookIcon />
                      </FacebookShareButton>
                    </PopoverComponent>
                  </div>
                </Col>
              </Row>
              <Row className='my-4'>
                <Col md='6'>
                  <div className='img-div'>
                    <img
                      src={
                        state?.data?.bannerUrl
                          ? `${process.env.REACT_APP_BASE_URL}/${state?.data?.bannerUrl}`
                          : require('assets/images/svg/pages/sessions/session-detail-dummy-image.svg')
                              .default
                      }
                      alt=''
                      className='img-fluid w-100'
                      style={{ maxHeight: '350px' }}
                    />
                    <p className='text-muted small mt-4'>
                      {state?.data?.about}
                    </p>
                  </div>
                </Col>
                <Col md='6'>
                  <div className='information-div p-3'>
                    <div className='d-flex align-items-center mb-3'>
                      <img
                        src={
                          require('assets/images/svg/icons/ic_calendar.svg')
                            .default
                        }
                        className='mr-3'
                        alt=''
                      />
                      <div>
                        <p className='text-muted mb-0'>
                          {moment(state?.data?.fromDate)
                            .local()
                            .format('MMMM Do YYYY, h:mm a')}{' '}
                          <br />
                          {moment(state?.data?.toDate)
                            .local()
                            .format('MMMM Do YYYY, h:mm a')}
                        </p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <img
                        src={
                          require('assets/images/svg/icons/ic_watch.svg')
                            .default
                        }
                        className='mr-3'
                        alt=''
                      />
                      <div>
                        <p className='text-muted mb-0'>Group Session</p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <img
                        src={
                          require('assets/images/svg/icons/ic_tickets_two.svg')
                            .default
                        }
                        className='mr-3'
                        alt=''
                      />
                      <div>
                        <p className='text-muted mb-0'>
                          Ticket : {quoteCurrency}
                          {formatMoney(state?.data?.fee)}
                          <br />
                          {/* Service Charge : ${((Number(state.data?.fee) * (serviceCharge ? Number(serviceCharge) : 0.1)).toFixed(2))} */}
                          <br />
                          Total: {quoteCurrency}
                          {formatMoney(state?.data?.fee)}
                        </p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <img
                        src={
                          require('assets/images/svg/icons/ic_seats.svg')
                            .default
                        }
                        className='mr-3'
                        alt=''
                      />
                      <div>
                        <p className='text-muted mb-0'>
                          Seats : {state?.data?.reserved}/{state?.data?.seats}
                        </p>
                      </div>
                    </div>
                  </div>
                  {!state?.data?.registered && state?.data?.fee !== '0' && (
                    <Button
                      color='lightBlue'
                      block
                      className='my-3'
                      onClick={() => {
                        toggleModal('showPaymentModal');
                      }}
                      disabled={state?.data?.reserved >= state?.data?.seats}
                      // loading={state.submitting}
                      text='Enroll'
                    />
                  )}
                  {state?.data?.fee === '0' && (
                    <Button
                      color='lightBlue'
                      block
                      className='my-3'
                      onClick={doEnrollFree}
                      text={loading ? 'Please Wait...' : 'Enroll'}
                      disabled={state?.data?.reserved >= state?.data?.seats}
                    />
                  )}
                  {/* <div>
                                        <p className='small text-muted mb-0'>Spread the word</p>
                                        <div className="copy-div d-flex p-3">
                                            <p
                                                ref={copyRef}
                                                className='mb-0 w-100 overflow small text-grayScale'>{window.location.origin}/group-sessions/{id}
                                            </p>
                                            <button onClick={onCopy} className="btn btn-primary">
                                                <img src={require('assets/images/svg/icons/ic_copy_2.svg').default} className="ml-auto cusor-pointer" alt="" />
                                            </button>
                                        </div>
                                    </div> */}
                  {state?.data?.registered &&
                    moment().isBetween(
                      moment(state?.data?.fromDate)
                        .local()
                        .subtract(10, 'minute'),
                      moment(state?.data?.toDate).local()
                    ) && (
                      <div>
                        <Link
                          to={`/meet/${id}`}
                          target='_blank'
                          className='btn btn-lightBlue my-3'>
                          Join Meeting
                        </Link>
                      </div>
                    )}
                  <div
                    className='mt-2 d-flex pt-3 cursor-pointer'
                    onClick={() =>
                      navigate(`/expert/${state?.data?.expert?.userId}`)
                    }>
                    <div>
                      <img
                        width={40}
                        src={
                          state?.data?.expert?.profilePages?.profileMediaPage
                            ?.profilePicture ||
                          require(`assets/images/svg/dummy-image.svg`).default
                        }
                        className='mr-2'
                        alt=''
                      />
                    </div>
                    <div>
                      <p className='m-0 small'>
                        <b>Hosted by </b>
                        {state?.data?.expert?.profilePages?.registrationPage
                          ?.firstname ||
                          state?.data?.expert?.profilePages?.registrationPage
                            ?.firstName}{' '}
                        {state?.data?.expert?.profilePages?.registrationPage
                          ?.lastname ||
                          state?.data?.expert?.profilePages?.registrationPage
                            ?.lastName}
                      </p>
                      {state?.data?.expert?.profilePages
                        ?.professionalInformationPage?.jobTitle &&
                        state?.data?.expert?.profilePages
                          ?.professionalInformationPage?.companyName && (
                          <p className='m-0 small text-muted'>
                            {
                              state?.data?.expert?.profilePages
                                ?.professionalInformationPage?.jobTitle
                            }{' '}
                            at{' '}
                            {
                              state?.data?.expert?.profilePages
                                ?.professionalInformationPage?.companyName
                            }
                          </p>
                        )}
                    </div>
                  </div>
                  <p className='small my-3'>
                    {' '}
                    <b> Interest Areas:</b>{' '}
                    <span className='text-muted'> {state?.data?.interest}</span>
                  </p>
                </Col>
              </Row>
            </Container>
          )}
        </CardBody>
      </Card>
      <CustomModal
        isOpen={state.showModal}
        // isOpen={state?.showModal}
        toggle={() => {
          toggleModal('hideSuccessMsg');
          window.location.reload();

          window.open();
        }}
        backdrop={false}
        keyboard={false}
        classProp='modal-lg'
        header="Congrats you've got a seat">
        <SuccessModal id={id} data={state?.data} />
      </CustomModal>

      <CustomModal
        isOpen={state?.showPaymentModal}
        toggle={() => toggleModal('hidePaymentModal')}
        backdrop={false}
        keyboard={false}
        classProp='modal-md'
        header='Confirm Session Payment'>
        <Container fluid>
          <Row>
            <Col md='12'></Col>
            <Col md='12'>
              {step === 1 && (
                <>
                  <div className='d-flex align-content-center'>
                    <p className='font-weight-bold'>Select Payment Type</p>
                    <p
                      className='ml-auto mb-0 font-weight-bolder text-primary cursor-pointer'
                      onClick={() => {
                        if (currency[countryName]?.paymentType !== '') {
                          setCurrency({
                            Canada: {
                              paymentType: '',
                              currency: ''
                            },
                            Usa: {
                              paymentType: '',
                              currency: ''
                            },
                            Nigeria: {
                              paymentType: '',
                              currency: ''
                            }
                          });
                        }
                        setState((prev: any) => {
                          return {
                            ...prev,
                            showPaymentModal: false
                          };
                        });
                      }}>
                      Go Back
                    </p>
                  </div>
                  <PaymentSelector
                    setPaymentType={setCurrency}
                    setStep={setStep}
                    accountInfo={accountInfo}
                    setForeignBuffer={setForeignCurrencyBuffVal}
                    sessionType='group'
                    setCountryName={setCountryName}
                    handleProceed={(type: any, Currency: any, name: any) => {
                      if (
                        accountInfo.currency === 'NGN' &&
                        Currency === 'cad'
                      ) {
                        const totalSessionFee =
                          +state?.data?.fee / +rate?.cadRate +
                          +rate.bufferValue;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'NGN' &&
                        Currency === 'usd'
                      ) {
                        const totalSessionFee =
                          +state?.data?.fee / +rate.usdRate + +rate.bufferValue;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'NGN' &&
                        Currency === 'gbp'
                      ) {
                        const totalSessionFee =
                          +state?.data?.fee / +rate?.gbpRate +
                          +rate?.bufferValue;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '£',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'usd' &&
                        paymentCurrency === 'usd'
                      ) {
                        const totalSessionFee = +state?.data?.fee;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'gbp' &&
                        Currency === 'gbp'
                      ) {
                        const totalSessionFee = +state?.data?.fee;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            amountToPay: totalSessionFee.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'cad' &&
                        Currency === 'cad'
                      ) {
                        const totalSessionFee = +state?.data?.fee;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'cad' &&
                        Currency === 'usd'
                      ) {
                        const totalSessionFee =
                          +state?.data?.fee + foreignCurrencyBufferVal;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee.toFixed(2)
                          };
                        });
                        doEnroll(type, undefined, Currency, name);
                      } else if (
                        accountInfo.currency === 'cad' &&
                        Currency === 'gbp'
                      ) {
                        const totalSessionFee =
                          +state?.data?.fee + foreignCurrencyBufferVal;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        doEnroll(type, undefined, Currency, name);
                        // if (type === 'stripe') {
                        // }
                      } else if (
                        accountInfo.currency === 'usd' &&
                        Currency === 'gbp'
                      ) {
                        // + rate.gpbRate + +rate.bufferValue
                        const totalSessionFee =
                          +state?.data?.fee + foreignCurrencyBufferVal;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'usd' &&
                        Currency === 'cad'
                      ) {
                        // + rate.gpbRate + +rate.bufferValue
                        const totalSessionFee =
                          +state?.data?.fee + foreignCurrencyBufferVal;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '$',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        if (type === 'stripe') {
                          doEnroll(type, undefined, Currency, name);
                        }
                      } else if (
                        accountInfo.currency === 'NGN' &&
                        Currency === 'NGN'
                      ) {
                        const totalSessionFee = +state?.data?.fee;
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '#',
                            amountToPay: totalSessionFee?.toFixed(2)
                          };
                        });
                        // doEnroll(type, undefined, Currency, name);
                      }
                    }}
                    amount={state?.data?.fee * 1}
                    submitData={{
                      title: state?.data?.title,
                      fromDate: state?.data?.fromDate,
                      toDate: state?.data?.toDate,
                      expertId: state?.data?.expertId,
                      sessionId: id
                    }}
                  />
                </>
              )}
              {step === 2 && (
                <>
                  <div className='d-flex align-content-center'>
                    <p className='font-weight-bold'>
                      Confirm Payment of
                      <span className='text-primary'>
                        {' '}
                        {state?.currency}
                        {state?.amountToPay}
                      </span>
                    </p>
                    <p
                      className='ml-auto mb-0 font-weight-bolder text-primary cursor-pointer'
                      onClick={() => {
                        setStep((prev: any) => prev - 1);
                        setState((prev: any) => {
                          return {
                            ...prev,
                            currency: '',
                            amountToPay: undefined,
                            payment_intent_client_secret: ''
                          };
                        });
                        if (currency[countryName]?.paymentType !== '') {
                          setCurrency({
                            Canada: {
                              paymentType: '',
                              currency: ''
                            },
                            Uk: {
                              paymentType: '',
                              currency: ''
                            },

                            Usa: {
                              paymentType: '',
                              currency: ''
                            },
                            Nigeria: {
                              paymentType: '',
                              currency: ''
                            }
                          });
                        }
                      }}>
                      Go Back
                    </p>
                  </div>
                  <Payment
                    intent={state?.payment_intent_client_secret}
                    countryName={countryName}
                    expertAccountCurrency={accountInfo.currency}
                    currency={currency[countryName]?.currency}
                    paymentType={currency[countryName]?.paymentType}
                    callBackFn={doEnroll}
                    fee={`${state.currency}${Fee}`}
                    sessionType='group'
                    submitData={{
                      title: state?.data?.title,
                      fromDate: state?.data?.fromDate,
                      toDate: state?.data?.toDate,
                      expertId: state?.data?.expertId,
                      sessionId: id,
                      paymentType: currency[countryName]?.paymentType
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </CustomModal>
    </div>
  );
};

export default SessionDetails;
