import GroupCards from 'components/common/GroupCards';
import Loader from 'components/common/Loader';
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { fetchGroupSessions } from 'redux/Actions/sessionAction';
import { async_status } from 'redux/Actions/types';
import { RootState } from 'redux/Reducers';

const GroupSession = () => {
    const { sessions } = useSelector((state: RootState) => state)
    const dispatch = useDispatch();


    useEffect(() => {
        if (sessions.status === async_status.__DEFAULT__ || sessions.status === async_status.__FAILED__) {
            dispatch(fetchGroupSessions())
        }
    }, [dispatch, sessions.status])

    var settings = useMemo(() => {
        return {
            draggable: true,
            dots: false,
            autoplay: false, /* this is the new line */
            autoplaySpeed: 500,
            infinite: sessions.data.length > 3,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            centerMode: false,
            // touchThreshold: 1000,
        }
    }, [sessions.data.length]);

    return (
        <Container className='mt-4'>
            <Row>
                <Col sm="12">
                    <div className='d-flex'>
                        <h3 className='text-grayScale expert-group-head'>Join expert group sessions</h3>
                        <Link to="/bookings/explore?tab=group" className='ml-auto expert-group-head'>See All</Link>
                    </div>
                </Col>

                <Col sm="12">
                    {sessions.status === async_status.__LOADING__ ? <Loader /> :
                        <GroupCards
                            sessionInfo={sessions.data}
                            settings={settings} />
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default GroupSession