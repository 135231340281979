import ComingSoonBadge from 'components/common/ComingSoon';
import CalendarIcon from 'Pages/Profile/Components/Icons/CalendarIcon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row
} from 'reactstrap';
import MessageIcon from './Icons/MessageIcon';
import RightCaretIcon from './Icons/RightCaretIcon';
import CompletedBadge from 'components/common/Completed_Badge';
import OngoingBadge from 'components/common/Ongoing_Badge';
import { getExpertBankAccountInfo } from '../../../services/expertService';
import { getTranxRecords } from 'services/transactionService';
const BookingsCard: React.FC<any> = ({
  data: {
    Session,
    expert: {
      profilePages: {
        registrationPage,
        professionalInformationPage,
        profileMediaPage,
        availabilityPage
      }
    }
  },
  hideCancel
}) => {
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState('');
  const [paymentCurrency, setPaymentCurrency] = useState('');
  const currentDateTime = DateTime.now().setZone(Session.learnerZone).toISO();
  const sessionFromDate = DateTime.fromISO(Session.fromDate, {
    zone: Session.learnerZone
  }).toString();
  const sessionToDate = DateTime.fromISO(Session.toDate, {
    zone: Session.learnerZone
  }).toString();
  // const {
  //   user: {
  //     data: { data },
  //   },
  // } = useSelector((state: RootState) => state);
  // useEffect(() => {
  //   fetchUser();
  // }, []);
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const fetchTranx = async () => {
      try {
        const {
          data: { data }
        } = await getTranxRecords(Session.sessionId, token);
        setPaymentCurrency(data?.currency);
      } catch (err) {
        setPaymentCurrency('');
      }
    };
    fetchTranx();
  }, [Session.sessionId, token]);
  const currentDate = new Date().toISOString().replace(/\.\d+Z$/, '.000Z');
  const toggleOpen = () => setOpen((prev) => !prev);
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const { data } = await getExpertBankAccountInfo(Session.expertId);
        setCurrency(data.data.currency);
      } catch (err) {
        setCurrency('');
      }
    };
    fetchInfo();
  }, [Session.expertId]);
  let Currency = '';
  if (paymentCurrency === 'NGN') {
    Currency = '₦';
  } else if (paymentCurrency === 'usd') {
    Currency = '$';
  } else if (paymentCurrency === 'cad') {
    Currency = '$';
  }
  return (
    <Card className='my-3 booking-card'>
      <CardBody>
        <div className='d-flex align-items-center'>
          <h4 className='text-deepBlue'>
            {/* 1 on 1 session with */}
            <span className='text-primary'>{Session.title}</span>
          </h4>
          <span
            onClick={toggleOpen}
            className='cursor-pointer text-grayScale ml-auto details-link'>
            Details <RightCaretIcon open={open} />
          </span>
        </div>
        <div className='d-flex align-items-center'>
          <p className='d-flex align-items-center mr-3 small mb-0'>
            <CalendarIcon />{' '}
            <span className='ml-2 font-weight-lighter'>
              {' '}
              {DateTime.fromISO(Session.fromDate).toFormat('MMMM d, yyyy')}
            </span>
          </p>
          <p className='d-flex align-items-center small mb-0'>
            <CalendarIcon />{' '}
            <span className='ml-2 font-weight-lighter'>
              {DateTime.fromISO(Session.fromDate, {
                zone: Session.learnerZone
              }).toFormat('hh:mm a')}{' '}
              -{' '}
              {DateTime.fromISO(Session.toDate, {
                zone: Session.learnerZone
              })
                .plus({ minutes: 1 })
                .toFormat('hh:mm a')}
            </span>{' '}
          </p>
          <p className='d-flex align-items-center small mb-0'>
            <span className='ml-4'>
              {currentDateTime >= sessionFromDate &&
              currentDateTime < sessionToDate ? (
                <OngoingBadge />
              ) : null}
            </span>
          </p>
        </div>
        <Collapse isOpen={open}>
          <div className='my-3 border-top border-bottom py-3'>
            <Container fluid>
              <Row>
                <Col md='5'>
                  <div className='d-flex'>
                    <div>
                      <img
                        src={
                          profileMediaPage?.profilePicture ||
                          require(`assets/images/svg/dummy-image.svg`).default
                        }
                        className='mr-2 review-img'
                        alt=''
                      />
                    </div>
                    <div>
                      <p className='m-0 '>
                        <b>
                          {registrationPage?.firstName}{' '}
                          {registrationPage?.lastName}
                        </b>
                      </p>
                      <p className='m-0 text-muted font-weight-lighter'>
                        {professionalInformationPage?.jobTitle} at{' '}
                        {professionalInformationPage?.companyName}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md='3'>
                  <p className='font-weight-light my-0'>fee :</p>
                  <p className='text-primary my-0'>
                    {`${Currency}${Session.fee.toLocaleString()}`}
                    {/* ${Number(Session.fee).toFixed(2)}/session */}
                  </p>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md='4'>
                  <p className='text-muted font-weight-lighter my-0 mb-2'>
                    Location of meeting:
                  </p>
                  <p className='text-primary my-0'>
                    <Link
                      to={`/meet/${Session.sessionId}`}
                      target='_blank'
                      rel='noreferrer'
                      className='mr-3'>
                      Meeting Link
                    </Link>{' '}
                  </p>
                </Col>
                <Col md='4'>
                  <p className='text-muted font-weight-lighter my-0 mb-2'>
                    Expert Timezone: {availabilityPage?.timeZone}
                  </p>
                </Col>
                <Col md='4'>
                  <p className='text-muted font-weight-lighter my-0 mb-2'>
                    I am interested in:
                  </p>
                  <p className='font-weight-bolder'>{Session.interest}</p>
                </Col>
              </Row>
            </Container>
          </div>
        </Collapse>
        <div className='buttons mt-3'>
          {!hideCancel && (
            <Link
              to={`/meet/${Session.sessionId}`}
              target='_blank'
              rel='noreferrer'
              className={`mr-3 btn btn-primary ${
                currentDateTime >= sessionFromDate &&
                currentDateTime <= sessionToDate
                  ? ''
                  : 'disabled-Link'
              }`}>
              Join Session
            </Link>
          )}
          <Button className='mr-3 btn-recall'>
            Reschedule call
            {currentDateTime >= sessionFromDate &&
            currentDateTime < sessionToDate ? (
              <OngoingBadge />
            ) : null}
            {currentDateTime < sessionFromDate ? <ComingSoonBadge /> : null}
            {currentDate > sessionToDate ? <CompletedBadge /> : null}
          </Button>
          {/* <Link to={`/bookings/${Session.sessionId}`} className='mr-3 btn btn-message'><MessageIcon />  Message Expert <ComingSoonBadge /></Link> */}
          <Button className='mr-3 btn btn-message'>
            <MessageIcon /> Message Expert
            {currentDateTime >= sessionFromDate &&
            currentDateTime < sessionToDate ? (
              <OngoingBadge />
            ) : null}
            {currentDateTime < sessionFromDate ? <ComingSoonBadge /> : null}
            {currentDateTime > sessionToDate ? <CompletedBadge /> : null}
          </Button>
          {!hideCancel && (
            <Button color='transparent' className='text-danger'>
              Cancel
              {currentDateTime > sessionFromDate &&
              currentDateTime < sessionToDate ? (
                <OngoingBadge />
              ) : null}
              {currentDateTime < sessionFromDate ? <ComingSoonBadge /> : null}
              {currentDateTime > sessionToDate ? <CompletedBadge /> : null}
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default BookingsCard;
