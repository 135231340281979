import React from "react";
import EmptyReview from "./EmptyReview";
import Loader from "components/common/Loader";
import moment from "moment";
import InfiniteScroll from "components/common/InfiniteScroll";

const Reviews: React.FC<any> = React.memo(
  ({ data, loading, hasMore, loadMore, ...props }) => {
    return (
      <div id="review-content">
        {loading && (
          <div className="h-100">
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            {data.length === 0 ? (
              <div className="text-center">
                <EmptyReview />
                <h3 className="text-grayScalePlaceholder">
                  No reviews at this time.
                </h3>
              </div>
            ) : (
              <InfiniteScroll
                scrollableTarget="review-content"
                dataLength={data.length}
                hasMore={hasMore}
                next={loadMore}
              >
                <div className="content-area">
                  {data.map((review: Record<any, any>, i: number) => (
                    <div key={i} className="px-4 py-4">
                      <h5>
                        {moment(review.createdAt)
                          .local()
                          .format("ddd MMMM Do, YYYY, hh:mm A")}
                      </h5>
                      <p className="font-weight-lighter">{review.review}</p>
                      <div className="border-bottom mt-4 d-flex pb-3">
                        <div className="ml-auto">
                          {/* <img width={40}  src={require(`assets/images/svg/dummy-image.svg`).default} className="mr-2" alt="" /> */}
                          <img
                            width={40}
                            src={
                              review?.user?.profilePages?.profileMediaPage
                                ?.profilePicture ||
                              require(`assets/images/svg/dummy-image.svg`)
                                .default
                            }
                            className="mr-2"
                            alt=""
                          />
                        </div>
                        <div>
                          <p className="m-0 small">
                            <b>Posted by </b>
                            <span className="text-capitalize">
                              {review.user?.firstname} {review.user?.lastname}
                            </span>
                          </p>
                          {/* {console.log(review?.user, "review?.user")} */}
                          {review?.user?.professionalInformationPage
                            ?.jobTitle &&
                            review?.user?.professionalInformationPage
                              ?.companyName && (
                              <p className="m-0 small text-muted">
                                {
                                  review?.user?.professionalInformationPage
                                    ?.jobTitle
                                }{" "}
                                at{" "}
                                {
                                  review?.user?.professionalInformationPage
                                    ?.companyName
                                }
                              </p>
                            )}
                          <p className="m-0 small text-muted">
                            {review?.user?.professionalInformationPage
                              ?.professionalSummary || "Coachmie Learner"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    );
  }
);

export default Reviews;
