import Loader from 'components/common/Loader'
import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

const Description: React.FC<any> = ({ state }) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {state.loading ? <div> <Loader /> </div> :
                                <p className='small'>{state?.data?.about}</p>
                            }
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Container>
    )
}

export default Description