import style from './Index.module.css';

const Index = () => {
  return (
    <div className={style.spinner}>
      <div className={style.split}></div>
      <div className={style.split}></div>
      <div className={style.split}></div>
      <div className={style.split}></div>
    </div>
  );
};

export default Index;
