import React from 'react';
import style from './Index.module.css';
import ExpertImg from '../../assets/images/svg/pages/signup/expert.svg';
import UserImg from '../../assets/user.svg';
import { ArrowRight } from 'react-feather';
const Index = ({
  setUserType,
  userType,
  setComponent,
  headerText,
  Page = 'Login'
}: {
  setUserType: any;
  Page?: any;
  userType: any;
  setComponent: any;
  headerText?: any;
}) => {
  const tabs = [
    {
      img: UserImg,
      header: 'Learner',
      description:
        "You'll be able to book learning sessions with experts, write reviews and more",
      user: 'learner'
    },
    {
      img: ExpertImg,
      header: 'Expert',
      description:
        "You'll be able to host learning sessions with learners across the world and more",
      user: 'expert'
    }
  ];

  return (
    <div className={style.container}>
      <div className={style.modal}>
        <h1>{headerText ? headerText : 'How do you want to Sign In'}</h1>
        <div className={style.login_type}>
          {tabs.map((tab) => (
            <div
              onClick={() => {
                setUserType(tab.user);
              }}
              className={
                userType === tab.user ? style.login_nav_active : style.login_nav
              }>
              <div className={style.image_wrapper}>
                <img src={tab.img} alt='' />
              </div>
              <h2>{tab.header}</h2>
              <p>{tab.description}</p>
            </div>
          ))}
        </div>
        <div className={style.nav_button}>
          <button
            onClick={() => {
              if (userType === 'learner' && Page === 'Login') {
                setComponent(userType);
              }
              if (userType === 'learner' && Page === 'SignUp') {
                setComponent(userType);
              }
              if (userType === 'expert' && Page === 'SignUp') {
                window.location.href = `${process.env.REACT_APP_EXPERT_APP_URL}/auth/coach-request-invite`;
              }
              if (userType === 'expert' && Page === 'Login') {
                window.location.href = `${process.env.REACT_APP_EXPERT_APP_URL}/auth/log-in`;
              }
            }}>
            Continue
            <ArrowRight
              style={{
                marginLeft: '0.8rem'
              }}
              size={16}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Index;
