import React from "react";

function GlobeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.5 17a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM1 10h15"
      ></path>
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 2a11.475 11.475 0 013 7.5A11.475 11.475 0 019 17a11.475 11.475 0 01-3-7.5A11.475 11.475 0 019 2v0z"
      ></path>
    </svg>
  );
}

export default GlobeIcon;