import Loader from 'components/common/Loader';
import { apiErrorHandler } from 'Helpers/utility';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleGroupSession } from 'services/sessionService';
import MeetingView from './MeetingView';

export const Meeting = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [state, setState] = useState<any>({
        loading: true,
        data: {}
    })

    const handleGoBack = useCallback(() => navigate(-1), [navigate]);

    const fetchDetails = useCallback(async () => {
        try {
            let { data: { data } } = await getSingleGroupSession(id);
            if (!data.registered) {
                handleGoBack()
            } else {
                setState((prev: any) => ({
                    ...prev,
                    loading: false,
                    data: data
                }))
            }
        } catch (error) {
            apiErrorHandler(error)
            handleGoBack()
        }
    }, [id, handleGoBack])

    useEffect(() => {
        fetchDetails()
    }, [fetchDetails])

    const dateEvaluation = useMemo(() => {
        if (state.data.fromDate && state.data.toDate) {
            const from = moment(state.data.fromDate).local().subtract(10, "minute").format();
            const to = moment(state.data.toDate).local().format();
            const now = moment();

            const fromDateBeforeNow = moment(from).isSameOrBefore(now);
            const toDateAfterNow = moment(to).isAfter(now);

            const evaluation = now.isBetween(from, to);

            return { evaluation, fromDateBeforeNow, toDateAfterNow };
        }
        return false;

    }, [state.data])
    return (
        <div>
            {state.loading ?
                <Loader /> :
                <>
                    {dateEvaluation !== false && <MeetingView
                        dateEvaluation={dateEvaluation}
                        data={state.data}
                        id={id}
                    />}
                </>
            }
        </div>
    )
}
