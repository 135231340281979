import { ExpertCard } from 'components/common/ExpertCards'
import InfiniteScroll from 'components/common/InfiniteScroll'
import Loader from 'components/common/Loader'
import { apiErrorHandler } from 'Helpers/utility'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { fetchUserWishlist } from 'services/userService'

const WishList = () => {
    const [state, setState] = useState({
        experts: [],
        pageNo: 0,
        pageSize: 20,
        hasMore: false,
        totalRecord: 0,
        loading: false
    })


    const toggleLoading = () => {
        setState((prev) => ({
            ...prev,
            loading: !prev.loading
        }))
    }

    const loadExperts = useCallback(async (pageNo = 1) => {
        pageNo === 1 && toggleLoading()
        try {
            let { data: {data} } = await fetchUserWishlist(state.pageSize, pageNo);
            setState((prev) => ({
                ...prev,
                experts: pageNo > 1 ? prev.experts.concat(data.wishes) : data.wishes,
                pageNo: pageNo,
                totalRecord: data.count,
                hasMore: ((pageNo * state.pageSize) < data.count),
                loading: false
            }))
        } catch (error) {
            apiErrorHandler(error);
            pageNo === 1 && toggleLoading();
        }
    }, [state.pageSize])

    useEffect(() => {
        loadExperts(1);
    }, [loadExperts])

    return (
        <Container>
            {state.loading && <Loader />}
            {!state.loading && <>
                {state.experts.length > 0 ?
                    <InfiniteScroll
                        dataLength={state.experts.length}
                        next={() => loadExperts(state.pageNo + 1)}
                        hasMore={state.hasMore}
                    >

                        <Row>
                            {state.experts.map((info: any, i) => (
                                <Col md="6" lg="4" key={info.expert?.userId} className="expert-group-cards">
                                    <ExpertCard allLiked={true} likeCallBack={()=> loadExperts(1)} data={info.expert} index={i} />
                                </Col>
                            ))}
                        </Row>
                    </InfiniteScroll>
                    :
                    <Row>
                        <Col>
                            <div>
                                <p className="text-muted">You currently have no mentors saved to your wishlist. <br />
                                    Add an expert; you might see them here!</p>

                                <Link to="/bookings/explore" className='btn btn-sm px-4 py-2 border-4 btn-darkBlue small' >Explore Experts</Link>
                            </div>
                        </Col>
                    </Row>}
            </>}
        </Container>
    )
}

export default WishList