import React from "react";

function UnReadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="9"
      fill="none"
      viewBox="0 0 11 9"
    >
      <path
        stroke="#8ACD85"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M1 4l3 3 6-6"
      ></path>
    </svg>
  );
}

export default UnReadIcon;