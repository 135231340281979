import React from "react";
import { Badge } from "reactstrap";

const OngoingBadge = () => {
  return (
    <Badge color="yellow" className="small mx-1 text-white">
      {" "}
      Ongoing
    </Badge>
  );
};

export default OngoingBadge;
