import React from "react";

function EmptyReview() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="124"
      fill="none"
      viewBox="0 0 124 124"
    >
      <path
        fill="#D9DBE9"
        d="M36.166 63.292a3.875 3.875 0 117.75 0 3.875 3.875 0 01-7.75 0zm3.875 11.625a3.875 3.875 0 100 7.75 3.875 3.875 0 000-7.75zm-3.875 19.375a3.875 3.875 0 117.75 0 3.875 3.875 0 01-7.75 0zm-15.5-68.148l-9.197-9.197a3.874 3.874 0 115.477-5.477l95.584 95.584a3.874 3.874 0 01-5.477 5.476l-4.733-4.727a10.339 10.339 0 01-9.32 5.864H31a10.333 10.333 0 01-10.334-10.333v-77.19zm74.917 74.921l-7.926-7.925a3.876 3.876 0 01-3.7 5.027H55.542a3.875 3.875 0 110-7.75h28.417c.403 0 .785.062 1.152.175l-7.926-7.925H55.541a3.875 3.875 0 110-7.75H69.44l-7.75-7.75h-6.148a3.875 3.875 0 01-1.359-7.507L28.416 33.899v69.435A2.581 2.581 0 0031 105.917h62a2.581 2.581 0 002.583-2.583v-2.269zM61.999 41.334c0 2.541.92 4.866 2.444 6.664l1.22 1.225a10.304 10.304 0 006.67 2.444h23.25v27.476l7.75 7.75V50.778a10.335 10.335 0 00-3.028-7.306l-30.116-30.11a2.533 2.533 0 00-.253-.207 3.234 3.234 0 01-.187-.155 10.697 10.697 0 00-1.131-.93 3.372 3.372 0 00-.413-.228l-.248-.124-.259-.15c-.279-.16-.563-.325-.857-.45a10.217 10.217 0 00-3.224-.712 2.872 2.872 0 01-.305-.036 3.116 3.116 0 00-.424-.036H31c-1.261 0-2.47.227-3.586.64l7.11 7.11h27.475v23.25zm7.75-17.459l20.037 20.042H72.333a2.583 2.583 0 01-2.584-2.584V23.876zm14.209 35.542h-8.102l7.75 7.75h.352a3.875 3.875 0 100-7.75z"
      ></path>
    </svg>
  );
}

export default EmptyReview;
