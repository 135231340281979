import React from "react";

function MessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <path
        stroke="#258AFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 10a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.379 8.379 0 01-3.8-.9L1 19.5l1.9-5.7A8.38 8.38 0 012 10a8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"
      ></path>
    </svg>
  );
}

export default MessageIcon;