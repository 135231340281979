import React from 'react'
import PotentialComponent from './SubComponents/PotentialComponent'
import TopComponent from './SubComponents/TopComponent'

const About = () => {
    return (
        <div id='about'>
            <TopComponent />
            <PotentialComponent />
            {/* <ContactComponent /> */}
        </div>
    )
}

export default About