import ComingSoonBadge from 'components/common/ComingSoon';
import Logo from 'components/common/Logo';
import React, { Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { RootState } from 'redux/Reducers';
import routes from 'routes';

const SidebarItem: React.FC<any> = ({
  name,
  classProp,
  badgeColor,
  badgeText,
  icon: Icon,
  to,
  onClick,
  image,
  isComingSoon
}) => {
  const location = useLocation();
  const getSidebarItemClass = (path: String) => {
    return location?.pathname === path ? 'active' : '';
  };

  return (
    <Fragment>
      {!isComingSoon && (
        <li className={'sidebar-item ' + getSidebarItemClass(to)}>
          <NavLink
            to={to}
            onClick={onClick}
            className={`${classProp} px-3 sidebar-link d-flex align-items-center`}>
            {image !== null ? (
              <>{image}</>
            ) : Icon ? (
              <Icon size={18} className='align-middle mr-3' />
            ) : null}
            {name} {isComingSoon && <ComingSoonBadge />}
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className='sidebar-badge'>
                {badgeText}
              </Badge>
            ) : null}
          </NavLink>
        </li>
      )}
    </Fragment>
  );
};

// const propsEqual = (prevProps:any, nextProps:any)=>{
//   console.log({prevProps, nextProps})
//   return true
// }

const Sidebar: React.FC<any> = React.memo(({ toggled, toggleSidebar }) => {
  const {
    user: {
      data: { data: user }
    }
  } = useSelector((state: RootState) => state);
  // useEffect(() => {
  //   toggleSidebar();
  // }, [])

  return (
    <div id='sidebar' className={`${toggled && 'toggled'} px-2`}>
      <div className='float-right'>
        {toggled && (
          <div
            id='hamburger-icon'
            onClick={toggleSidebar}
            className={toggled ? 'open' : ''}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </div>
      <div className='my-5 text-center'>
        <Link to='/'>
          {' '}
          <Logo width='150' />
        </Link>
      </div>
      <div className='user-panel py-3 px-4'>
        <div className='d-flex align-items-center'>
          {/* <UserAvatar /> */}
          <div className='ml-2'>
            <h5 className='mb-0 text-capitalize'>
              {user.firstname} {user.lastname}
            </h5>
            {/* <p className="text-muted m-0">{handleTextLength(user.username, 15)}</p> */}
          </div>
        </div>
      </div>
      <PerfectScrollbar>
        <ul className='sidebar-nav'>
          {routes.map((category, index) => {
            return (
              <React.Fragment key={index}>
                <SidebarItem
                  name={category.name}
                  to={category.path}
                  image={category?.image || null}
                  icon={category.icon}
                  classProp={category?.classProp}
                  badgeColor={category.badgeColor}
                  badgeText={category.badgeText}
                  isComingSoon={category.isComingSoon}
                />
              </React.Fragment>
            );
          })}
        </ul>
      </PerfectScrollbar>
    </div>
  );
});

export default Sidebar;
