import React from 'react';


interface formInterface {
    formControl: any,
    onSubmit?: any,
    onChange?: any,
    formName: any,
}
const Form: React.FC<formInterface> = (props) => {
    let { formControl, onChange, onSubmit, formName } = props
    return (
        <form onSubmit={(e) => {
            e.preventDefault()
            onSubmit(e)
        }} name={formName} className="h-100">
            <input type="text" name="domain" className="h-100 w-100" value={formControl.domain} onChange={onChange} />
        </form>
    )
}


export default Form