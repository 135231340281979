import React from "react";

function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        stroke="#A0A3BD"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.25 3.5H3.75A1.5 1.5 0 002.25 5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V5a1.5 1.5 0 00-1.5-1.5zM12 2v3M6 2v3M2.25 8h13.5"
      ></path>
    </svg>
  );
}

export default CalendarIcon;