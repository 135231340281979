import Loader from 'components/common/Loader';
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const Handover = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    useEffect(() => {
        const code = searchParams.get("code")

        if (!code) {
            navigate(-1)
        }

        localStorage.setItem("authToken", code as string)
        window.location.href = '/overview'
    }, [searchParams, navigate])
    return (
        <div className="vh-100">
            <Loader />
        </div>
    )
}
