import React from 'react'

export const UpTech = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="89"
            height="70"
            fill="none"
            viewBox="0 0 89 89"
        >
            <rect
                width="87.879"
                height="87.879"
                x="0.536"
                y="0.581"
                fill="#fff"
                stroke="#E2E4E8"
                strokeWidth="1.072"
                rx="10.181"
            ></rect>
            <path
                fill="#fff"
                stroke="#258AFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M32 56.5a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h32a.5.5 0 00.5-.5V33a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v7.5H48a.5.5 0 00-.5.5v7.5H40a.5.5 0 00-.5.5v7.5H32z"
            ></path>
            <mask
                id="path-3-outside-1_9888_23933"
                width="31"
                height="31"
                x="20"
                y="21"
                fill="#000"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#fff" d="M20 21H51V52H20z"></path>
                <path d="M38 34l9-9-9 9zm3-9h6v6l-6-6zM30 48h-6v-6l6 6zm3-9l-9 9z"></path>
            </mask>
            <path
                fill="#3D96FC"
                d="M38 34l9-9-9 9zm3-9h6v6l-6-6zM30 48h-6v-6l6 6zm3-9l-9 9z"
            ></path>
            <path
                fill="#fff"
                d="M35.172 31.172a4 4 0 105.656 5.656l-5.656-5.656zm14.656-3.344a4 4 0 10-5.656-5.656l5.656 5.656zM41 25v-4a4 4 0 00-2.828 6.828L41 25zm6 0h4a4 4 0 00-4-4v4zm0 6l-2.828 2.828A4 4 0 0051 31h-4zM30 48v4a4 4 0 002.828-6.828L30 48zm-6 0h-4a4 4 0 004 4v-4zm0-6l2.828-2.828A4 4 0 0020 42h4zm11.828-.172a4 4 0 10-5.656-5.656l5.656 5.656zm-14.656 3.344a4 4 0 105.656 5.656l-5.656-5.656zm19.656-8.344l9-9-5.656-5.656-9 9 5.656 5.656zm3.344-14.656l-9 9 5.656 5.656 9-9-5.656-5.656zM41 29h6v-8h-6v8zm2-4v6h8v-6h-8zm6.828 3.172l-6-6-5.656 5.656 6 6 5.656-5.656zM30 44h-6v8h6v-8zm-2 4v-6h-8v6h8zm-6.828-3.172l6 6 5.656-5.656-6-6-5.656 5.656zm9-8.656l-9 9 5.656 5.656 9-9-5.656-5.656z"
                mask="url(#path-3-outside-1_9888_23933)"
            ></path>
        </svg>
    );
}

export const BuildOut = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="89"
            height="70"
            fill="none"
            viewBox="0 0 89 90"
        >
            <rect
                width="87.879"
                height="87.879"
                x="0.536"
                y="1.404"
                fill="#fff"
                stroke="#E2E4E8"
                strokeWidth="1.072"
                rx="10.181"
            ></rect>
            <path
                stroke="#258AFF"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M64 35L44 25 24 35v20l20 10 20-10V35z"
            ></path>
            <path
                stroke="#258AFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M24 35l20 10M44 65V45M64 35L44 45M54 30L34 40"
            ></path>
        </svg>
    );
}

export const ResearchLab = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="89"
            height="70"
            fill="none"
            viewBox="0 0 89 90"
        >
            <rect
                width="87.879"
                height="87.879"
                x="0.536"
                y="1.227"
                fill="#fff"
                stroke="#E2E4E8"
                strokeWidth="1.072"
                rx="10.181"
            ></rect>
            <path
                fill="#258AFF"
                fillRule="evenodd"
                d="M58.15 54c-4.499-11.236-9.75-14.197-9.75-19.8v-6.6h1.1a1.1 1.1 0 001.1-1.1v-2.2a1.1 1.1 0 00-1.1-1.1h-11a1.1 1.1 0 00-1.1 1.1v2.2a1.1 1.1 0 001.1 1.1h1.1v6.6c0 5.603-5.252 8.564-9.75 19.8-1.433 3.575-5.111 8.8 14.15 8.8 19.26 0 15.582-5.225 14.15-8.8zm-3.557 3.852c-1.545.462-4.618.988-10.593.988s-9.049-.526-10.593-.988c-.548-.163-.761-.799-.568-1.382.484-1.474 1.397-4.021 3.104-6.866 4.172-6.95 8.499 1.093 12.1 1.093 3.602 0 2.614-3.434 4.013-1.093a33.778 33.778 0 013.104 6.866c.194.583-.02 1.217-.567 1.382z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
