import React from 'react';
import style from './Index.module.css';
import Logo from 'components/common/Logo';
import { BsEmojiSmile as SmileEmoji } from 'react-icons/bs';

const Index = () => {
  return (
    <>
      <div className={style.container}>
        <Logo className={style.img} />
        <div className={style.box}>
          <h1 className={style.header}>
            Verify your mail
            {/* Thanks for Signing Up <SmileEmoji className={style.icon} /> */}
          </h1>
          <span>Kindly check your mail for verification</span>
        </div>
      </div>
    </>
  );
};

export default Index;
