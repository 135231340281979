import React, { useState } from 'react';
import style from './Accordion.module.css';
import { RiArrowDropDownLine as DropdownIcon } from 'react-icons/ri';

const Accordion = () => {
  const questions = [
    {
      idx: 0,
      question: 'Demand the highest standards and outputs',
      answer:
        'We hold one another accountable for stellar interactions, dealings and executions. We hire for demonstrated potential, aptitude and outstanding character. We deliver fast but exceptionally',
      isOpened: false
    },
    {
      idx: 1,
      question: 'Show obsession for the success of the community',
      answer:
        'Experts, learners & partners. We exist to serve the community stakeholders satisfactorily. We design and build with the community in mind – innovating for them',
      isOpened: false
    },
    {
      idx: 2,
      question: 'Develop a growth mindset',
      answer:
        'We are always looking for opportunities to learn. We experiment often. We are intentional about learning and research',
      isOpened: false
    },
    {
      idx: 3,
      question: 'Be human',
      answer:
        "At Coachmie, we believe that it’s human to be kind, empathetic, respectful, helpful, and generous. We expect these and even more. We also believe that success means rallying to get things done. Success is never a solo effort; it's a community initiative",
      isOpened: false
    }
  ];

  type propsType = {
    idx: number;
    question: string;
    answer: string;
    isOpened: boolean;
  };

  const [state, setState] = useState<propsType[]>(questions);
  const handleClick = (index: number) => {
    const updatedQuestions = state.map((el: propsType, idx: number) => {
      if (idx === index) {
        return { ...el, isOpened: !el.isOpened };
      } else {
        return { ...el, isOpened: false };
      }
    });
    setState(updatedQuestions);
  };

  return (
    <div className={style.container}>
      <div className={style.accordion}>
        {questions.map((question: any, key: any) => (
          <div key={key} className={style.accordion_content}>
            <div
              className={style.accordion_header}
              onClick={() => handleClick(key)}>
              <h2
                onClick={() => {
                  handleClick(key);
                }}
                className={style.question_text}>
                {question.question}
              </h2>
              <DropdownIcon
                className={
                  state[key].isOpened
                    ? style.dropdown_active
                    : style.dropdown_icon
                }
                onClick={() => {
                  handleClick(key);
                }}
              />
            </div>
            {state[key].isOpened && (
              <span onClick={() => handleClick(key)} className={style.answer}>
                {question.answer}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
