import React from "react";

function SessionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#258AFF"
        fillRule="evenodd"
        d="M5.36 2.812l.737.754A8.257 8.257 0 0112 1.066c2.214 0 4.337.9 5.903 2.5l.738-.754A9.289 9.289 0 0012 0a9.289 9.289 0 00-6.642 2.812zM12 4.267a5.144 5.144 0 00-3.69 1.562l.738.754A4.128 4.128 0 0112 5.333c1.107 0 2.168.45 2.951 1.25l.738-.754a5.144 5.144 0 00-3.69-1.562z"
        clipRule="evenodd"
        opacity="0.5"
      ></path>
      <path
        fill="#258AFF"
        fillRule="evenodd"
        d="M15.652 19.2V17.45l-1.208-.412a.526.526 0 01-.31-.289.544.544 0 01-.003-.428l1.521-3.59v-.999a6.969 6.969 0 012.57-5.445A6.648 6.648 0 0124 4.993V24h-4.174v-2.667h-2.087c-1.152 0-2.087-.955-2.087-2.133zM8.348 19.2v-1.749l1.208-.412a.526.526 0 00.31-.288.544.544 0 00.003-.43l-1.521-3.589v-.998c0-3.83-3.037-6.934-6.783-6.934A6.65 6.65 0 000 4.993V24h4.174v-2.666H6.26c1.152 0 2.087-.956 2.087-2.134z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#258AFF"
        fillRule="evenodd"
        d="M12 10.666c.576 0 1.043-.477 1.043-1.066 0-.59-.467-1.067-1.043-1.067-.576 0-1.043.478-1.043 1.067s.467 1.066 1.043 1.066z"
        clipRule="evenodd"
        opacity="0.5"
      ></path>
    </svg>
  );
}

export default SessionIcon;