import React, { useEffect } from 'react';
import AppRoutes from 'routes/Routes';
import 'assets/scss/main.scss';
import { Provider } from "react-redux";
import store from "redux/store"
import { ToastContainer } from "react-toastify";
import { ApiKeysProvider } from 'Contexts/ApiKeysContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    })
  }, [])
  return (
    <Provider store={store}>
      <ApiKeysProvider>
        <AppRoutes />
        <ToastContainer />
      </ApiKeysProvider>
    </Provider>)
}

export default App;
