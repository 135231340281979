import CustomModal from "components/common/CustomModal";
import SuccessIcon from "Pages/GroupSession/SessionDetails/Components/SuccessIcon";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import SuccessView from "Pages/GroupSession/SessionDetails/Components/SuccessModal";

const SuccessModal: React.FC<any> = ({ isOpen, toggleModal, data }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggleModal}
      classProp="modal-lg"
      header="Payment Successful"
    >
      <Container>
        <Row>
          <Col md="12">
            <SuccessIcon />
          </Col>
        </Row>
      </Container>
      <SuccessView data={data} id={data.sessionId} />
    </CustomModal>
  );
};

export default SuccessModal;
