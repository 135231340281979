import CustomModal from 'components/common/CustomModal';
import { apiErrorHandler, onChange } from 'Helpers/utility';
// import TimeAvailabilityModal from '../../../components/common/Modal/Date_Selection/Index';
// import ConfirmBookingModal from '../../../components/common/Modal/Confim_Booking/Index';
// import TimeZoneDifferenceModal from '../../../components/common/Modal/Difference_Notice/Index';
// import PaymentMethodModal from '../../../components/common/Modal/Payment_Method/Index';
// import PaymentGatewayModal from '../../../components/common/Modal/StripeModal/Index';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { DayPicker } from 'react-day-picker';
import { DateTime, IANAZone, Duration } from 'luxon';
import 'react-day-picker/dist/style.css';
import { createSession, getExpetBookedDays } from 'services/sessionService';
import moment from 'moment';
import { timeList } from 'Helpers/constants';
import { Col, Container, Input, Row } from 'reactstrap';
import Button from 'components/common/Button';
import ConfirmForm from './ConfirmForm';
import { toast } from 'react-toastify';
import { getTime, getSpecificDaysOfWeekInRange } from './Top';
import Payment from '../../../components/Payment/Payment';
import { PaymentSelector } from 'components/Payment/PaymentSelector';
import { ApiKeysContext } from 'Contexts/ApiKeysContext';
import { fetchUserData } from 'services/userService';
const token = localStorage.getItem('authToken');

export const getWeekDay = (day: any) => {
  switch (day) {
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    case 0:
      return 'sunday';
    default:
      return 'sunday';
  }
};

export const getTimeRange = (
  hour: any,
  from: any,
  to: any,
  listParam = 'value'
) => {
  const compareFromValue =
    listParam === 'value' ? String(hour[from]?.toFixed(1)) : String(hour[from]);
  const compareToValue =
    listParam === 'value' ? String(hour[to]?.toFixed(1)) : String(hour[to]);
  const fromIndex = timeList.findIndex(
    (time: any) => time[listParam] === compareFromValue
  );
  const toIndex = timeList.findIndex(
    (time: any) => time[listParam] === compareToValue
  );
  let hoursList: any = [];
  if (fromIndex > toIndex) {
    const slicedArray = [];
    for (let i = fromIndex; i < timeList.length; i++) {
      slicedArray.push(timeList[i]);
    }
    for (let i = 0; i <= toIndex - 1; i++) {
      slicedArray.push(timeList[i]);
    }
    hoursList = slicedArray.map((list: any) => list.time);
  }
  if (fromIndex < toIndex) {
    hoursList = timeList
      .slice(fromIndex, toIndex)
      .map((list: any) => list.time);
  }
  return hoursList;
};

export const getAvailableTime = (date: any, availability: any) => {
  let day = getWeekDay(date);
  const searchData = availability?.serviceScheduling[day];
  if (searchData?.active) {
    let newHours = getTimeRange(searchData.hours[0], 'from', 'to');
    return {
      day: day,
      hours: newHours
    };
  }
};

export const getNextAvailableTime: any = (date: any, availability: any) => {
  const availabilityData = getAvailableTime(date, availability);
  if (availabilityData) {
    return availabilityData;
  } else {
    let newDate = date + 1;
    if (newDate > 6) {
      newDate = 0;
    }
    return getNextAvailableTime(newDate, availability);
  }
};

export const generateFullDate = (date: any, time: any) => {
  return moment(
    new Date(`${moment(date).format('YYYY-MM-D')} ${time}`)
  ).local();
};
export const BookModal: React.FC<any> = ({
  isOpen,
  toggleModal,
  id,
  availability,
  expertInfo,
  accountInfo,
  userData
}) => {
  const {
    keys: { data }
  } = useContext(ApiKeysContext);
  const title = `1:1 session with ${
    expertInfo?.firstname || expertInfo?.firstName || ''
  } ${expertInfo?.lastname || expertInfo?.lastName || ''}`;
  const expertTimeZone = availability?.timeZone;
  const startDate = availability?.startDate;
  const endDate = availability?.endDate;
  const schedules = availability?.serviceScheduling;
  const [timeZone, setZone] = useState('');
  const [Schedules, SetSchedules] = useState<any>({
    monday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    tuesday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    wednesday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    thursday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    friday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    },
    saturday: {
      dates: [],
      hrs: [],
      active: false,
      expertSt: '',
      expertEt: '',
      expertTimeZone,
      learnerSt: '',
      learnerEt: '',
      learnerTimeZone: ''
    }
  });

  const utcDateTimeString = (date: any, timeString: any, zone: string) => {
    const dateUTC = `${date} ${timeString}`;
    const luxonDateTime = DateTime.fromFormat(dateUTC, 'yyyy-MM-dd hh:mm a', {
      zone,
      setZone: true
    });
    const isoString = luxonDateTime.toISO();
    return isoString;
  };

  const DateByZone = (date: any, timeString: any, zone: any) => {
    const utcDateTime = utcDateTimeString(date, timeString, zone);
    return utcDateTime;
  };

  const generateDate = (dateTime: any, timeString: any, timeZone: any) => {
    const targetDate = DateTime.fromISO(dateTime, { zone: timeZone });
    const time = DateTime.fromFormat(timeString, 'h:mm a');
    const combinedDateTime = targetDate.set({
      hour: time.hour,
      minute: time.minute,
      second: time.second,
      millisecond: time.millisecond
    });
    return combinedDateTime.toString();
  };

  const [Token, setToken] = useState<any>(null);
  useEffect(() => {
    setToken(token);
  }, []);

  useEffect(() => {
    let mounted = true;
    const fetchAccountData = async () => {
      fetchUserData(token)
        .then((res: any) => {
          if (mounted) {
            setZone(res?.data?.data?.timezone);
          }
        })
        .catch((err) => {
          throw new Error(err?.response?.data?.message);
        });
    };
    if (Token !== null) {
      fetchAccountData();
    }
    return () => {
      mounted = false;
    };
  }, [Token]);
  const learnerTimeZone = timeZone;

  const initialState = useMemo(() => {
    return {
      step: 1,
      formBook: {
        // date: undefined,
        time: [],
        nextAvailable: '',
        date: undefined,
        startTime: '',
        endTime: '',
        questions: '',
        topic: ''
      },
      disabledDates: [],
      groupedBookedTimeValues: {},
      groupedBookedTime: {},
      availability: []
    };
  }, []);
  const [state, setState] = useState<any>(initialState);

  /** MY_logic */
  useEffect(() => {
    if (schedules?.sunday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        7,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.sunday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.sunday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.sunday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules?.sunday?.hours[0]?.from?.toFixed(1);
      const schedulesLength = schedules?.sunday?.hours?.length;
      const to = schedules?.sunday?.hours[schedulesLength - 1].to?.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );

      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          sunday: {
            dates: [...dates],
            active: schedules.sunday.active,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            hrs: [...slots],
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.monday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        1,
        expertTimeZone
      );
      const timeToExclude: any = [];
      schedules.monday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.monday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      const slots: any = [];
      schedules.monday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex + 1);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules.monday.hours[0].from.toFixed(1);
      const schedulesLength = schedules.monday.hours.length;
      const to = schedules.monday.hours[schedulesLength - 1].to.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          monday: {
            dates: [...dates],
            active: schedules.monday.active,
            expertSt: fromInAMPM,
            hrs: [
              ...slots.filter((el: any) => !timeToExclude.includes(el.time))
            ],
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.tuesday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        2,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.tuesday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.tuesday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.tuesday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules.tuesday.hours[0].from.toFixed(1);
      const schedulesLength = schedules.tuesday.hours.length;
      const to = schedules.tuesday.hours[schedulesLength - 1].to.toFixed(1);

      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          tuesday: {
            dates: [...dates],
            hrs: [
              ...slots.filter((el: any) => !timeToExclude.includes(el.time))
            ],
            active: schedules.tuesday.active,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.wednesday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        3,
        expertTimeZone
      );

      const slots: any = [];
      const timeToExclude: any = [];
      schedules.wednesday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.wednesday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.wednesday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules.wednesday.hours[0].from.toFixed(1);
      const schedulesLength = schedules.wednesday.hours.length;
      const to = schedules.wednesday.hours[schedulesLength - 1].to.toFixed(1);

      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          wednesday: {
            dates: [...dates],
            hrs: [
              ...slots.filter((el: any) => !timeToExclude.includes(el.time))
            ],
            active: schedules.wednesday.active,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.thursday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        4,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.thursday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.thursday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.thursday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules.thursday.hours[0].from.toFixed(1);
      const schedulesLength = schedules.thursday.hours.length;
      const to = schedules.thursday.hours[schedulesLength - 1].to.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);

      SetSchedules((prev: any) => {
        return {
          ...prev,
          thursday: {
            dates: [...dates],
            hrs: [
              ...slots.filter((el: any) => !timeToExclude.includes(el.time))
            ],
            active: schedules.wednesday.active,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.friday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        5,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.friday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.friday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.friday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules.friday.hours[0].from.toFixed(1);
      const schedulesLength = schedules.friday.hours.length;
      const to = schedules.friday.hours[schedulesLength - 1].to.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          friday: {
            dates: [...dates],
            hrs: [
              ...slots.filter((el: any) => !timeToExclude.includes(el.time))
            ],
            active: schedules.friday.active,
            expertSt: fromInAMPM,
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
    if (schedules?.saturday?.active === true) {
      const dates = getSpecificDaysOfWeekInRange(
        startDate,
        endDate,
        6,
        expertTimeZone
      );
      const slots: any = [];
      const timeToExclude: any = [];
      schedules.saturday.hours.forEach((schedule: any, i: any) => {
        const to = schedules?.saturday?.hours[i].to.toFixed(1);
        const sch = timeList.find((el) => el.value === to);
        timeToExclude.push(sch?.time);
        return timeToExclude;
      });
      schedules.saturday.hours.forEach((schedule: any) => {
        const to = timeList.find(
          (time) => time.value === schedule.to.toFixed(1)
        )?.value;
        const from = timeList.find(
          (time) => time.value === schedule.from.toFixed(1)
        )?.value;
        const startIndex = timeList.findIndex((time) => time.value === from);
        const endIndex = timeList.findIndex((time) => time.value === to);
        const listOfTime = timeList.slice(startIndex, endIndex);
        listOfTime.forEach((el) => {
          slots.push(el);
        });
        return slots;
      });
      const from = schedules.saturday.hours[0].from.toFixed(1);
      const schedulesLength = schedules.saturday.hours.length;
      const to = schedules.saturday.hours[schedulesLength - 1].to.toFixed(1);
      const fromInAMPM = String(
        timeList.find((time) => time.value === from)?.time
      );
      const toInAMPM = String(timeList.find((time) => time.value === to)?.time);
      const lrnsFrom = getTime(fromInAMPM, expertTimeZone, learnerTimeZone);
      const lrnsTo = getTime(toInAMPM, expertTimeZone, learnerTimeZone);
      SetSchedules((prev: any) => {
        return {
          ...prev,
          saturday: {
            dates: [...dates],
            active: schedules.saturday.active,
            expertSt: fromInAMPM,
            hrs: [
              ...slots.filter((el: any) => !timeToExclude.includes(el.time))
            ],
            expertEt: toInAMPM,
            learnerSt: timeZone ? lrnsFrom.toString() : '',
            learnerEt: timeZone ? lrnsTo.toString() : '',
            expertTimeZone,
            learnerTimeZone: timeZone ? timeZone : ''
          }
        };
      });
    }
  }, [
    expertTimeZone,
    timeZone,
    schedules,
    startDate,
    endDate,
    learnerTimeZone
  ]);
  const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } =
    Schedules;
  /** My Logic */
  const paymentIntialState = {
    Canada: {
      paymentType: '',
      currency: ''
    },
    Usa: {
      paymentType: '',
      currency: ''
    },
    Nigeria: {
      paymentType: '',
      currency: ''
    },
    Uk: {
      paymentType: '',
      currency: ''
    }
  };
  const [currency, setCurrency] = useState<any>(paymentIntialState);
  let quoteCurrency = '';
  if (accountInfo.currency === 'NGN') {
    quoteCurrency = '₦';
  } else if (accountInfo.currency === 'usd') {
    quoteCurrency = '$';
  } else if (accountInfo.currency === 'cad') {
    quoteCurrency = '$';
  } else if (accountInfo.currency === 'gbp') {
    quoteCurrency = '£';
  }
  const [countryName, setCountryName] = useState('');
  const paymentCurrency = currency[countryName]?.currency;
  const [totalFee, setTotalFee] = useState<any>(null);
  const [symbol, setSymbol] = useState('');
  const [foreignBufferVal, setForeignBufferVal] = useState(0);
  const [convRate, setConvRate] = useState<any>(null);
  const getTotalSessionFee = () => {
    let totalSessionFee;
    if (accountInfo.currency === 'NGN' && paymentCurrency === 'cad') {
      totalSessionFee =
        (state.formBook.time.length * availability?.hourlyRate) /
          +data.cadRate +
        +data.bufferValue;
      setTotalFee(totalSessionFee);
      setConvRate(+data.bufferValue);
      setSymbol('$');
    } else if (accountInfo.currency === 'NGN' && paymentCurrency === 'usd') {
      totalSessionFee =
        (state.formBook.time.length * availability?.hourlyRate) /
          +data.usdRate +
        +data.bufferValue;
      setTotalFee(totalSessionFee);
      setConvRate(+data.bufferValue);
      setSymbol('$');
    } else if (accountInfo.currency === 'usd' && paymentCurrency === 'usd') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee);
      setSymbol('$');
    } else if (accountInfo.currency === 'usd' && paymentCurrency === 'cad') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee);
      setSymbol('$');
    } else if (accountInfo.currency === 'usd' && paymentCurrency === 'gbp') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee);
      setSymbol('$');
    } else if (accountInfo.currency === 'cad' && paymentCurrency === 'cad') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee?.toFixed(2));
      setSymbol('$');
    } else if (accountInfo.currency === 'cad' && paymentCurrency === 'usd') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee?.toFixed(2));
      setSymbol('$');
    } else if (accountInfo.currency === 'cad' && paymentCurrency === 'gbp') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;

      setTotalFee(totalSessionFee?.toFixed(2));
      setSymbol('$');
    } else if (accountInfo.currency === 'NGN' && paymentCurrency === 'gbp') {
      totalSessionFee =
        (state.formBook.time.length * availability?.hourlyRate) /
          +data.gbpRate +
        +data.bufferValue;
      setTotalFee(totalSessionFee.toFixed(2));
      setConvRate(+data.bufferValue);
      setSymbol('£');
    } else if (accountInfo.currency === 'gbp' && paymentCurrency === 'gbp') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee.toFixed(2));
      setSymbol('£');
    } else if (accountInfo.currency === 'gbp' && paymentCurrency === 'usd') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee?.toFixed(2));
      setSymbol('£');
    } else if (accountInfo.currency === 'gbp' && paymentCurrency === 'cad') {
      totalSessionFee =
        state.formBook.time.length * availability?.hourlyRate +
        foreignBufferVal;
      setTotalFee(totalSessionFee?.toFixed(2));
      setSymbol('£');
    }
  };
  const getTitle = (step: number) => {
    let title;
    switch (step) {
      case 1:
      default:
        title = 'Select a Date';
        break;
      case 2:
        title = 'Select available time';
        break;
      case 3:
        title = 'Timezone differences notice';
        break;
      case 4:
        title = 'Confirm your booking';
        break;
      case 5:
        title = 'Make Payment';
        break;
    }

    return title;
  };
  /** My Logic */

  const convertToLearnerDate = (value: any, timeZone: any) => {
    const currentDay = DateTime.fromJSDate(value).toISO();
    const dates = currentDay.slice(0, 23);
    const targetZone = IANAZone.create(timeZone);
    const currentDateTime = DateTime.now().setZone(targetZone);
    const offsetMinutes = currentDateTime.offset;
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
    const offsetString = `${offsetMinutes >= 0 ? '+' : '-'}${offsetHours
      .toString()
      .padStart(2, '0')}:${offsetMinutesRemainder.toString().padStart(2, '0')}`;
    const selectedDate = dates + '' + offsetString;
    return selectedDate;
  };
  const changeFormState = (param: string, value: any) => {
    // const currentDay = DateTime.fromJSDate(value).toISO();
    // const dates = currentDay.slice(0, 23);
    // const targetZone = IANAZone.create(timeZone);
    // const currentDateTime = DateTime.now().setZone(targetZone);
    // const offsetMinutes = currentDateTime.offset;
    // const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    // const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
    // const offsetString = `${offsetMinutes >= 0 ? "+" : "-"}${offsetHours
    //   .toString()
    //   .padStart(2, "0")}:${offsetMinutesRemainder.toString().padStart(2, "0")}`;
    // const selectedDate = dates + "" + offsetString;
    const selectedDate = convertToLearnerDate(value, timeZone);

    setState((prev: any) => {
      let objToUpdate = {
        ...prev,
        formBook: {
          ...prev.formBook,
          [param]: selectedDate
        }
      };
      if (param === 'date') {
        const date = selectedDate;
        const weekDay = DateTime.fromISO(selectedDate).weekday;
        if (weekDay === 1) {
          objToUpdate.availability = [];
          const mondaySlots: any = [];
          if (monday?.hrs?.length > 0) {
            for (let i = 0; i < monday?.hrs?.length; i++) {
              // const generatedDate = DateByZone(
              //   date.slice(0, 10),
              //   monday?.hrs[i].time,
              //   expertTimeZone
              // );

              const expertAvailability = DateByZone(
                date.slice(0, 10),
                monday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');

              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);
              // const { date: ExpertDate } = getConvertedTime(
              //   learnerDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();

              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate: learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        if (weekDay === 2) {
          objToUpdate.availability = [];
          const mondaySlots = [];
          if (tuesday?.hrs?.length) {
            for (let i = 0; i < tuesday?.hrs?.length; i++) {
              // const generatedDate = DateByZone(
              //   date.slice(0, 10),
              //   tuesday?.hrs[i].time,
              //   expertTimeZone
              // );
              const expertAvailability = DateByZone(
                date.slice(0, 10),
                tuesday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');

              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);
              // const { date: expertDate } = getConvertedTime(
              //   learnerDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();

              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate: learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        if (weekDay === 3) {
          objToUpdate.availability = [];
          const mondaySlots = [];
          if (wednesday?.hrs?.length) {
            for (let i = 0; i < wednesday?.hrs?.length; i++) {
              const expertAvailability = DateByZone(
                date.slice(0, 10),
                wednesday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');

              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);
              // const { date: expertDate } = getConvertedTime(
              //   learnerDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();
              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate: learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        if (weekDay === 4) {
          objToUpdate.availability = [];
          const mondaySlots = [];
          if (thursday?.hrs?.length) {
            for (let i = 0; i < thursday?.hrs?.length; i++) {
              const expertAvailability = DateByZone(
                date.slice(0, 10),
                thursday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');

              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);
              // const { date: expertDate } = getConvertedTime(
              //   learnerDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();

              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate: learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        if (weekDay === 5) {
          objToUpdate.availability = [];
          const mondaySlots = [];
          if (friday?.hrs?.length > 0) {
            for (let i = 0; i < friday?.hrs?.length; i++) {
              const expertAvailability = DateByZone(
                date.slice(0, 10),
                friday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');

              // console.log(generatedDate, 'date');
              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);
              // const { date: expertDate } = getConvertedTime(
              //   learnerDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();

              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate: learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        if (weekDay === 6) {
          objToUpdate.availability = [];
          const mondaySlots = [];
          if (saturday?.hrs?.length) {
            for (let i = 0; i < saturday?.hrs?.length; i++) {
              // const generatedDate = DateByZone(
              //   date.slice(0, 10),
              //   saturday?.hrs[i].time,
              //   expertTimeZone
              // );

              const expertAvailability = DateByZone(
                date.slice(0, 10),
                saturday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');
              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);
              // const { date: expertDate } = getConvertedTime(
              //   learnerDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();

              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        if (weekDay === 7) {
          objToUpdate.availability = [];
          const mondaySlots = [];
          if (sunday?.hrs?.length) {
            for (let i = 0; i < sunday?.hrs?.length; i++) {
              // const generatedDate = DateByZone(
              //   date.slice(0, 10),
              //   sunday?.hrs[i].time,
              //   expertTimeZone
              // );

              const expertAvailability = DateByZone(
                date.slice(0, 10),
                sunday?.hrs[i].time,
                expertTimeZone
              );

              const learnerDate = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toString();

              const learnerLocalTime = DateTime.fromISO(expertAvailability)
                .setZone(timeZone)
                .toFormat('hh:mm a');
              // const { date: learnerDate, localTime: learnerLocalTime } =
              //   getConvertedTime(generatedDate, expertTimeZone, timeZone);

              // const { date: expertDate } = getConvertedTime(
              //   generatedDate,
              //   timeZone,
              //   expertTimeZone
              // );
              const currentTime = DateTime.now().setZone(timeZone).toISO();

              if (learnerDate >= currentTime) {
                mondaySlots.push({
                  time: learnerLocalTime,
                  expertDate: expertAvailability,
                  learnerDate,
                  booked: false
                });
              }
              if (mondaySlots.length > 0) {
                objToUpdate.availability = [...mondaySlots];
              }
            }
          }
        }
        objToUpdate.step = 2;
      }
      return objToUpdate;
    });
  };

  const dateString = useCallback(
    (date: any, type = 'default') => {
      if (type === 'default') {
        return DateTime.fromISO(date, {
          zone: timeZone
        }).toFormat('hh:mm a');
      }
      if (type === 'add-minute') {
        return DateTime.fromISO(date, {
          zone: timeZone
        })
          .plus({ minutes: 1 })
          .toFormat('hh:mm a');
      }
    },
    [timeZone]
  );
  const actualAvailability =
    state?.availability?.length > 0
      ? state?.availability?.filter(
          (el: any) =>
            !state?.groupedBookedTime[
              state.formBook.date?.slice(0, 10)
            ]?.includes(el.time)
        )
      : [];
  const getSessions = useCallback(async () => {
    try {
      let {
        data: { data }
      } = await getExpetBookedDays(id);
      let values: any[] = [];
      let bookedTimeValues: any[] = [];
      if (data.length > 0) {
        data.forEach((element: any) => {
          let from = dateString(element.fromDate);
          let to = dateString(element.toDate, 'add-minute');
          let newElement = {
            from,
            to
          };
          let range = getTimeRange(newElement, 'from', 'to', 'time');
          let day = moment(element.fromDate).local().weekday();
          const date = DateTime.fromISO(element.fromDate)
            .setZone(timeZone)
            .toString();
          const weekDay = getWeekDay(day);
          bookedTimeValues = [
            ...bookedTimeValues,
            {
              day: weekDay,
              date: date.slice(0, 10),
              time: [...range]
            }
          ];
        });
      }
      let groupedBookedTimeValues = bookedTimeValues.reduce((r, a) => {
        const isoDate = a.date;
        if (!r[isoDate]) {
          r[isoDate] = [];
        }
        r[isoDate] = r[isoDate].concat(a.time);
        return r;
      }, {});
      setState((prev: any) => ({
        ...prev,
        disabledDates: values,
        groupedBookedTimeValues: groupedBookedTimeValues,
        groupedBookedTime: groupedBookedTimeValues
      }));
    } catch (error) {
      // apiErrorHandler(error)
    }
  }, [id, dateString, timeZone]);
  useEffect(() => {
    if (availability && isOpen) {
      getSessions();
    }
  }, [getSessions, isOpen, availability]);
  useEffect(() => {
    getTotalSessionFee();
  });
  const handleChange = (e: HTMLFormElement) => {
    onChange(e, setState);
  };

  const getNextTimeValue = (param: any, direction = 'next') => {
    const time = DateTime.fromFormat(param, 'h:mm a');
    const newTime = time.plus({ minutes: 30 });
    return newTime.toFormat('hh:mm a');
  };

  const handleChangeStep = (step = 1) => {
    if (step === 1) {
      setState((prev: any) => ({
        ...prev,
        formBook: {
          time: []
        },
        step: step
      }));
    }
    setState((prev: any) => ({
      ...prev,
      step: step
    }));
    setCurrency((prev: any) => ({
      ...prev
    }));
  };

  const sessionData = useCallback(() => {
    const fromDate = state.availability.find(
      (el: any) => el.time === state.formBook.time[0]
    );
    let eTime;
    const Index = state?.formBook?.time?.length - 1;
    const eDate = state.availability.find(
      (el: any) => el.time === state?.formBook?.time[Index]
    );
    if (state?.formBook?.time?.length > 0) {
      eTime = getNextTimeValue(
        state?.formBook?.time[state?.formBook?.time?.length - 1]
      );
    }
    const dateTime = DateTime.fromISO(eDate?.learnerDate, {
      zone: timeZone
    });
    // const dateTime = DateTime.fromISO(eDate?.expertDate, {
    //   zone: timeZone
    // });
    const datePart = dateTime.toFormat('yyyy-MM-dd');
    const updatedDateTime = DateTime.fromFormat(
      `${datePart} ${eTime}`,
      'yyyy-MM-dd h:mm a',
      { zone: timeZone }
    );

    const formattedDateTime = updatedDateTime.toISO({ extended: true });
    const oneSecond = Duration.fromObject({ seconds: 1 });
    const formattedDateTimeMinusOneSecond = DateTime.fromISO(
      formattedDateTime,
      { zone: timeZone }
    ).minus(oneSecond);
    const toDate = formattedDateTimeMinusOneSecond.toISO({ extended: true });
    return { fromDate, toDate };
  }, [state.availability, state.formBook.time, timeZone]);

  const { fromDate, toDate } = sessionData();
  console.log(fromDate, toDate, 'date');

  const onSubmit = async (
    paymentIntent = '',
    callBackFn?: () => void,
    type: string = currency[countryName].paymentType
  ) => {
    try {
      const fromDate = state.availability.find(
        (el: any) => el.time === state.formBook.time[0]
      );
      const Index = state?.formBook?.time?.length - 1;
      const eDate = state.availability.find(
        (el: any) => el.time === state?.formBook?.time[Index]
      );
      const eTime = getNextTimeValue(
        state?.formBook?.time[state?.formBook?.time?.length - 1]
      );
      const dateTime = DateTime.fromISO(eDate.learnerDate, {
        zone: timeZone
      });
      const datePart = dateTime.toFormat('yyyy-MM-dd');
      const updatedDateTime = DateTime.fromFormat(
        `${datePart} ${eTime}`,
        'yyyy-MM-dd h:mm a',
        { zone: timeZone }
      );

      const formattedDateTime = updatedDateTime.toISO({ extended: true });
      const oneSecond = Duration.fromObject({ seconds: 1 });
      const formattedDateTimeMinusOneSecond = DateTime.fromISO(
        formattedDateTime,
        { zone: timeZone }
      ).minus(oneSecond);
      const toDate = formattedDateTimeMinusOneSecond.toISO({ extended: true });
      // const endDate = state.availability.find((el: any) => el.time === eTime);
      const dataToSend = {
        transactionId: paymentIntent,
        paymentType: type,
        title: title,
        expertId: id,
        currency: currency[countryName].currency,
        interest: userData?.domains,
        about: state.formBook.questions,
        topic: state.formBook.topic,
        seats: '1',
        fromDate: fromDate?.learnerDate.toString(),
        toDate: `${toDate.toString()}`
      };
      const { data } = await createSession(dataToSend);
      toast.success(
        `${data.message}. An Email about your session has been sent to you `
      );
      toggleModal('successModalOpen');
      setState((prev: any) => {
        return {
          ...prev,
          formBook: {
            ...initialState.formBook
          },
          step: 1
        };
      });
    } catch (error) {
      if (error) {
        apiErrorHandler(error);
      }
    } finally {
      if (callBackFn) {
        callBackFn();
      }
    }
  };

  const onDayClick = (date: any, e: any) => {
    if (state?.groupedBookedTime?.[state.formBook.date]?.includes(date)) {
      return;
    }
    if (e.target.checked === false) {
      const Index = state.availability.find((el: any) => el.time === date);
      setState((prev: any) => {
        return {
          ...prev,
          formBook: {
            ...prev.formBook,
            time: [...prev.formBook.time.filter((el: any) => el !== Index.time)]
          }
        };
      });
    }
    const {
      formBook: { time }
    } = state;
    if (time?.includes(date)) {
    } else {
      setState((prev: any) => {
        return {
          ...prev,
          formBook: {
            ...prev.formBook,
            time: [...prev.formBook.time, date]
          }
        };
      });
    }
  };

  const getConvertedTime = (
    sourceTime: any,
    sourceZone: any,
    targetZone: any
  ) => {
    // "2023-08-16T12:00:00"
    // "America/New_York"
    const originalDateTime = DateTime.fromISO(sourceTime, {
      zone: sourceZone
    });

    const targetTimeZone = targetZone;
    const convertedDateTime = originalDateTime.setZone(targetTimeZone);
    // console.log(convertedDateTime.toString(), "TimeZone");
    // return convertedDateTime.toFormat("hh:mm a");
    return {
      date: convertedDateTime.toString(),
      localTime: convertedDateTime.toFormat('hh:mm a')
    };
  };

  return (
    <>
      <CustomModal
        keyboard={false}
        backdrop='false'
        header={getTitle(state.step)}
        isOpen={isOpen}
        toggle={toggleModal}>
        <div className='book-modal-wrapper'>
          {state.step === 1 && (
            <div>
              <DayPicker
                mode='single'
                selected={state.formBook.date}
                onSelect={(e) => {
                  changeFormState('date', e);
                }}
              />
            </div>
          )}
          {state.step === 2 && (
            <div>
              <div className=''>
                We have converted the Expert Time from {expertTimeZone} to your
                Local Time
              </div>
              <div className='d-flex px-2'>
                {/* <p>{moment(state.formBook.date).local().format("ddd, MMM Do")}</p> */}
                {/* <p>
                {DateTime.fromISO(state.formBook.date)
                  .setZone(timeZone)
                  .toFormat('EEE, MMM d')}
              </p> */}
                <p
                  className='ml-auto font-weight-bolder text-primary cursor-pointer'
                  onClick={() => handleChangeStep()}>
                  Change Date
                </p>
              </div>
              <Container>
                <Row>
                  {state.availability.length === 0 && (
                    <Col md='12'>
                      <p>No Available Time</p>
                    </Col>
                  )}

                  {actualAvailability?.map((availability: any, index: any) => (
                    <Col md='12' key={index} className='my-3'>
                      <label
                        htmlFor={availability.learnerDate}
                        style={{
                          padding: '0.55rem 0.5rem'
                        }}
                        className={`availability-box 
                      ${
                        state?.groupedBookedTime[state.formBook.date]?.includes(
                          availability.time
                        )
                          ? 'disabled'
                          : ''
                      } 
                      ${
                        state?.formBook?.time?.includes(availability.time)
                          ? 'selected'
                          : ''
                      }`}>
                        <div>
                          {availability.time} -{' '}
                          {getNextTimeValue(availability.time)}
                        </div>
                        <div className='ml-3'>
                          (<span>{availability.learnerDate.slice(0, 10)}</span>)
                        </div>
                        <div className='ml-auto'>
                          {/* {state?.groupedBookedTime[
                          DateTime.fromJSDate(state?.formBook?.date).toISO()
                        ]?.includes(availability?.time) ? (
                          <p className="my-0">Booked</p>
                        ) : ( */}
                          <Input
                            checked={state?.formBook?.time?.includes(
                              availability.time
                            )}
                            id={availability.learnerDate}
                            type='checkbox'
                            name={`${availability.learnerDate}`}
                            // checked={}
                            onChange={(e) => onDayClick(availability.time, e)}

                            // onChange={(e) => onDayClick(availability.time, e)}
                          />
                          {/* )} */}
                        </div>
                      </label>
                    </Col>
                  ))}
                  <Col sm='12'>
                    <Button
                      onClick={() => handleChangeStep(state.step + 1)}
                      disabled={
                        state.availability.length === 0 ||
                        state?.formBook?.time?.length === 0
                      }
                      text='Continue'
                      color='primary'
                      block
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {state.step === 3 && (
            <div className='px-3'>
              <div>
                <p>
                  Due to the difference in timezones, this meeting will happen
                  (in your local timezone)
                </p>
                {[
                  {
                    text: 'Session Time',
                    value: `${DateTime.fromISO(
                      state.availability[
                        state?.availability.findIndex(
                          (el: any) => el.time === state.formBook.time[0]
                        )
                      ]?.learnerDate
                    ).toFormat('ccc, MMM d')} at ${
                      state.formBook.time[0]
                    } - ${getNextTimeValue(
                      state.formBook.time[state.formBook.time.length - 1]
                    )}`
                  },

                  {
                    text: `${accountInfo.currency.toUpperCase()} (${quoteCurrency}${
                      availability?.hourlyRate + foreignBufferVal
                    }) x ${state.formBook.time.length}-Session(s)`,
                    value: `${quoteCurrency}${
                      state.formBook.time.length * availability?.hourlyRate +
                      foreignBufferVal
                    }`
                  },
                  {
                    text: 'Total amount',
                    value: `${accountInfo.currency.toUpperCase()} (${quoteCurrency}${
                      state.formBook.time.length * availability?.hourlyRate +
                      foreignBufferVal
                    })`
                  }
                ].map((data, i) => (
                  <div key={i}>
                    {data.text.includes('Total') && <hr className='w-100' />}
                    <div className='d-flex align-items-center jusify-content-center'>
                      <h6 className='font-weight-bold mb-0'>{data.text}</h6>
                      <p
                        className={`mb-0 ml-auto ${
                          data.text.includes('Total') && 'font-weight-bold'
                        }`}>
                        {data.value}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className='d-flex align-items-center justify-content-center mt-5'>
                <p
                  className='ml-auto mb-0 font-weight-bolder text-primary cursor-pointer'
                  onClick={() => handleChangeStep(state.step - 1)}>
                  Go Back
                </p>
                <Button
                  onClick={() => handleChangeStep(state.step + 1)}
                  text='Confirm'
                  color='primary'
                  className='ml-4'
                />
              </div>
            </div>
          )}
          {state.step === 4 && (
            <div>
              <Container>
                <Row>
                  <Col md='12'>
                    <div>
                      <p className='font-weight-bold'>
                        {title} at
                        <span className='text-primary'>
                          {' '}
                          {accountInfo.currency.toUpperCase()} ({quoteCurrency}
                          {availability?.hourlyRate + foreignBufferVal}
                          )/session
                        </span>{' '}
                        (A session is 30-minutes).
                        {/* Total amount for session(s) is ${state.formBook.time.length * availability?.hourlyRate} ({state.formBook.time.length} session(s), ${availability?.hourlyRate} each) */}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
              <ConfirmForm
                formControl={state.formBook}
                zone={{ expertTimeZone, learnerTimeZone }}
                formName='formBook'
                onChange={handleChange}
                getNextTimeValue={getNextTimeValue}
                goBack={() => handleChangeStep(state.step - 1)}
                onSubmit={() => handleChangeStep(state.step + 1)}
              />
            </div>
          )}
          {state.step === 5 && (
            <div>
              <Container fluid>
                <Row>
                  <Col md='12'>
                    {countryName === '' ? (
                      <>
                        <div className='d-flex align-content-center'>
                          <p className='font-weight-bold'>
                            Select Payment Method
                          </p>
                          <p
                            className='ml-auto mb-0 font-weight-bolder text-primary cursor-pointer'
                            onClick={() => {
                              handleChangeStep(state.step - 1);
                              setCurrency(paymentIntialState);
                            }}>
                            Go Back
                          </p>
                        </div>
                        <PaymentSelector
                          setPaymentType={setCurrency}
                          setCountryName={setCountryName}
                          accountInfo={accountInfo}
                          setForeignBuffer={setForeignBufferVal}
                          sessionType='one-to-one'
                          amount={
                            state.formBook.time.length *
                              availability?.hourlyRate +
                            foreignBufferVal
                          }
                          submitData={{
                            expertId: id,
                            title: title,
                            interest: userData?.domains,
                            about: state.formBook.questions,
                            seats: '1',
                            fromDate: fromDate.learnerDate,
                            toDate
                            // fromDate: generateFullDate(
                            //   DateTime.fromISO(
                            //     state.availability[
                            //       state.availability.findIndex(
                            //         (el: any) =>
                            //           el.time === state.formBook.time[0]
                            //       )
                            //     ].learnerDate
                            //   ).toJSDate(),
                            //   state.formBook.time[0]
                            // ),
                            // toDate: generateFullDate(
                            //   DateTime.fromISO(
                            //     state.availability[
                            //       state.availability.findIndex(
                            //         (el: any) =>
                            //           el.time === state.formBook.time[0]
                            //       )
                            //     ].learnerDate
                            //   ).toJSDate(),
                            //   getNextTimeValue(
                            //     state.formBook.time[
                            //       state.formBook.time.length - 1
                            //     ]
                            //   )
                            // )
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <div className='d-flex align-content-center'>
                          <p className='font-weight-bold'>
                            Confirm Payment of
                            <span className='text-primary'>
                              {' '}
                              {symbol}
                              {totalFee}
                            </span>{' '}
                          </p>
                          <p
                            className='ml-auto mb-0 font-weight-bolder text-primary cursor-pointer'
                            onClick={() => {
                              if (currency[countryName].paymentType !== '') {
                                setCurrency({
                                  Canada: {
                                    paymentType: '',
                                    currency: ''
                                  },
                                  Usa: {
                                    paymentType: '',
                                    currency: ''
                                  },
                                  Nigeria: {
                                    paymentType: '',
                                    currency: ''
                                  },
                                  Uk: {
                                    paymentType: '',
                                    currency: ''
                                  }
                                });
                                setCountryName('');
                              } else {
                                handleChangeStep(state.step - 1);
                              }
                            }}>
                            Go Back
                          </p>
                        </div>
                        <Payment
                          callBackFn={onSubmit}
                          currency={currency[countryName].currency}
                          paymentType={currency[countryName].paymentType}
                          countryName={countryName}
                          sessionType='one-to-one'
                          fee={
                            accountInfo.currency === 'NGN' &&
                            paymentCurrency !== 'NGN'
                              ? `${symbol}${convRate}`
                              : ` ${quoteCurrency}${data.bufferValue}`
                          }
                          expertAccountCurrency={accountInfo.currency}
                          submitData={{
                            expertId: id,
                            title: title,
                            interest: userData?.domains,
                            about: state.formBook.questions,
                            seats: '1',
                            fromDate: fromDate.learnerDate,
                            toDate,
                            topic: state.formBook.topic
                          }}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </div>
      </CustomModal>
      {/* <PaymentModal /> */}
      {/* <TimeZoneDifferenceModal /> */}
      {/* <PaymentGatewayModal /> */}
      {/* <ConfirmBookingModal /> */}
      {/* <TimeAvailabilityModal /> */}
    </>
  );
};
