import InfiniteScroll from 'components/common/InfiniteScroll'
import Loader from 'components/common/Loader'
import moment from 'moment'
import React from 'react'
import { Card, CardBody, Table } from 'reactstrap'

const TransactionsTable: React.FC<any> = ({ tableData, loadMore, hasMore, loading }) => {
    return (
        <Card className='table-card'>
            <CardBody>
                {loading? <Loader />: 
                <InfiniteScroll
                    dataLength={tableData.length}
                    next={loadMore}
                    hasMore={hasMore}
                >
                    <Table responsive striped={false}>
                        <thead>
                            <tr className='border-bottom'>
                                {["Date", "Status", "Amount", "Session Type"
                                // , "Fees", "Total"
                            ].map((head) => (
                                    <td className='text-grayScale'>{head}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data: Record<string, string>) => (
                                <tr className='py-2'>
                                    <td className='text-muted'>
                                    {moment(data.createdAt).local().format('MMMM Do, YYYY')}
                                    </td>
                                    <td>
                                        <div className={`status-${data?.status?.toLocaleLowerCase()}  text-capitalize`}>
                                            {data.status}
                                        </div>
                                    </td>
                                    <td className='text-muted'>
                                        {data.amount}
                                    </td>
                                    <td className='text-muted text-capitalize'>
                                        {data.type}
                                    </td>
                                    {/* <td className='text-muted'>
                                        {data.fees}
                                    </td>
                                    <td className='text-muted'>
                                        {data.total}
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </InfiniteScroll>
                }
            </CardBody>
        </Card>
    )
}

export default TransactionsTable