import React from 'react'

const ExploreTabs: React.FC<any> = ({ tabs, activeTab, updateTab }) => {
    return (
        <div className='d-flex'>
            {tabs.map((tab: string, i: any) => (
                <div className={`explore-tab mr-3 mb-2 ${activeTab === tab && 'active'}`} key={i} onClick={() => updateTab(tab)}>{tab}</div>
            ))}
        </div>
    )
}

export default ExploreTabs