import React, { useCallback, useEffect, useState } from 'react';
import { NoBookings } from './Components/NoBookings';
import Tabs from '../../components/common/Tabs';
import BookingsCard from './Components/BookingsCard';
import { Col, Container, Row } from 'reactstrap';
import { apiErrorHandler } from 'Helpers/utility';
import { getLearnerOneOnOneSessions } from 'services/sessionService';
import InfiniteScroll from 'components/common/InfiniteScroll';
import Loader from 'components/common/Loader';
const Bookings: React.FC<any> = () => {
  const [state, setState] = useState<any>({
    tabs: ['upcoming', 'pending', 'ongoing', 'past'],
    activeTab: 'upcoming',
    data: [],
    pageNo: 1,
    pageSize: 20,
    hasMore: false,
    totalRecord: 0,
    loading: true
  });

  const toggleTab = (param: String) => {
    if (state.activeTab !== param) {
      setState((prev: Object) => ({
        ...prev,
        activeTab: param,
        data: [],
        pageNo: 1,
        hasMore: false,
        totalRecord: 0,
        loading: true
      }));
    }
  };

  const fetchBookings = useCallback(
    async (pageNo = 1) => {
      try {
        let {
          data: { data }
        } = await getLearnerOneOnOneSessions(
          state.pageSize,
          pageNo,
          state.activeTab === 'pending' ? null : state.activeTab,
          state.activeTab === 'pending' ? true : false
        );
        setState((prev: any) => ({
          ...prev,
          data: pageNo > 1 ? prev.data.concat(data.session) : data.session,
          pageNo: data.pageNo,
          totalRecord: data.count,
          hasMore: pageNo * state.pageSize < data.count,
          loading: false
        }));
      } catch (error) {
        apiErrorHandler(error);
        setState((prev: any) => ({
          ...prev,
          loading: false
        }));
      }
    },
    [state.pageSize, state.activeTab]
  );
  useEffect(() => {
    fetchBookings(1);
  }, [fetchBookings]);
  return (
    <Container>
      <Row>
        <Col md='12'>
          <Tabs
            tabs={state.tabs}
            activeTab={state.activeTab}
            onClickTab={toggleTab}
          />
        </Col>
      </Row>
      {state.loading && <Loader />}
      {!state.loading && (
        <>
          <InfiniteScroll
            dataLength={state.data.length}
            next={() => fetchBookings(state.pageNo + 1)}
            hasMore={state.hasMore}>
            <Row>
              {state?.data?.map((data: Record<any, any>, i: number) => (
                <Col lg='10' key={i}>
                  <BookingsCard
                    data={data}
                    hideCancel={state.activeTab === 'past'}
                  />
                </Col>
              ))}
            </Row>
          </InfiniteScroll>
          {state?.data?.length < 1 && (
            <Row>
              <Col sm='12'>
                <NoBookings activeTab={state.activeTab} />
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Bookings;
