import React, { useState } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchSubDomains } from 'redux/Actions/domainAction';
import { RootState } from 'redux/Reducers';
import { async_status } from 'redux/Actions/types';
import Loader from 'components/common/Loader';
const LearningComponent = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { domains } = useSelector((state: RootState) => {
    return state;
  });
  useEffect(() => {
    if (domains.subDomainStatus !== async_status.__LOADED__) {
      dispatch(fetchSubDomains());
      setIsLoading(false);
    }
  }, [dispatch, domains.subDomainStatus]);

  return (
    <Container fluid>
      <Row className='px-md-5 px-0 mb-1 mt-5'>
        <Col sm='12'>
          <Row>
            <Col sm='8'>
              <h1 className='landing-mid-header'>
                Learning
                <br />
                specializations{' '}
              </h1>
            </Col>
            <Col sm='4' className='text-left text-md-right'>
              <p className='text-grayScale'>
                Learn the most exciting tech niches from all around the world
                and grow your career fast with experts.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>{domains.isLoading && <Loader />} </Col>
      </Row>
      {!domains.isLoading && (
        <Row className='px-md-5 px-0 mb-1'>
          {domains.subDomains?.map((specialization: any) => (
            <Col md='4' lg='3' sm='6' xs='6' key={specialization.id}>
              <Card className='mr-3 my-3 px-2 text-center specilization-card'>
                <CardBody className='align-items-center d-flex'>
                  <p className='text-grayScaleLabel m-0 p-0 w-100'>
                    {specialization.name}
                  </p>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default LearningComponent;
