import React, { useCallback, useEffect, useState } from 'react';
import { NoSession } from './Components/NoSession';
import Tabs from '../../components/common/Tabs';
import SessionViewTable from 'components/common/SessionViewTable';
import { Col, Container, Row } from 'reactstrap';
import { getLearnerGroupSessions } from 'services/sessionService';
import { apiErrorHandler } from 'Helpers/utility';
import Loader from 'components/common/Loader';

const GroupSessions: React.FC<any> = () => {
  const [state, setState] = useState<any>({
    tabs: ['upcoming', 'past', 'ongoing'],
    activeTab: 'upcoming',
    data: [],
    pageNo: 1,
    pageSize: 20,
    hasMore: false,
    totalRecord: 0,
    loading: true
  });

  const toggleTab = (param: String) => {
    if (state.activeTab !== param) {
      setState((prev: Object) => ({
        ...prev,
        activeTab: param,
        data: [],
        pageNo: 1,
        hasMore: false,
        totalRecord: 0,
        loading: true
      }));
    }
  };

  const fetchBookings = useCallback(
    async (pageNo = 1) => {
      try {
        let {
          data: { data }
        } = await getLearnerGroupSessions(
          state.pageSize,
          pageNo,
          state.activeTab
        );
        setState((prev: any) => ({
          ...prev,
          data: pageNo > 1 ? prev.data.concat(data.session) : data.session,
          pageNo: data.currentPageCount,
          totalRecord: data.totalRecord,
          hasMore: pageNo * state.pageSize < data.totalRecord,
          loading: false
        }));
      } catch (error) {
        apiErrorHandler(error);
        setState((prev: any) => ({
          ...prev,
          loading: false
        }));
      }
    },
    [state.pageSize, state.activeTab]
  );

  useEffect(() => {
    fetchBookings(1);
  }, [fetchBookings]);

  return (
    <Container>
      <Row>
        <Col sm='12'>
          <Tabs
            tabs={state.tabs}
            activeTab={state.activeTab}
            onClickTab={state.loading ? () => {} : toggleTab}
          />
        </Col>
      </Row>
      {state.loading && <Loader />}
      {!state.loading && (
        <>
          {state.data.length > 0 ? (
            <Col lg='10' className='mt-4'>
              <SessionViewTable
                tableData={state.data}
                state={state}
                loadMore={() => fetchBookings(state.pageNo + 1)}
                header='Upcoming Sessions'
              />
            </Col>
          ) : (
            <Col md='12'>
              <NoSession activeTab={state.activeTab} />
            </Col>
          )}
        </>
      )}
    </Container>
  );
};

export default GroupSessions;
