import axios, { AuthAxiosInstance, serviceNoAuth } from './apiAdapter';

// export const login = (data: any) => AuthAxiosInstance.post('/Auth/Login', data);
export const login = (data: any) => axios.post('/user/login', data);

export const VerifyOtp = (data: any) =>
  serviceNoAuth.post('/user/verify-otp', data);

export const ResendOtp = () => serviceNoAuth.post('/user/resend-otp');

export const register = (data: any) =>
  AuthAxiosInstance.post('/register/protegee', data);

export const changePassword = (data: any) =>
  AuthAxiosInstance.post('/change-password', data);

export const forgotPassword = (data: any) =>
  AuthAxiosInstance.post('/forgot-password/initial-request', data);

export const fetchUserDetails = () => axios.get(`/user`);

export const handleLogout = async (href = '/auth/login') => {
  localStorage.clear();
  localStorage.clear();
  window.location.href = href;
};

export const verifyMail = (data: any) =>
  axios.get(`/verifyMail?token=${data.mailToken}`, {
    headers: {
      'x-auth-token': data.authToken
    }
  });
