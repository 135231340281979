import React from "react";

function RatingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M20.783 21.976a1 1 0 001.193-1.193l-1.097-4.94A10.959 10.959 0 0022 11c0-6.075-4.925-11-11-11S0 4.925 0 11s4.925 11 11 11c1.702 0 3.35-.388 4.844-1.121l4.94 1.097zm-1.84-6.739a1 1 0 00-.095.689l.835 3.757-3.757-.835a1 1 0 00-.689.094A8.955 8.955 0 0111 20a9 9 0 119-9c0 1.5-.366 2.945-1.058 4.237z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M8 13a2 2 0 100-4 2 2 0 000 4zM14 13a2 2 0 100-4 2 2 0 000 4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default RatingIcon;