import { combineReducers } from "redux";
import userReducer from "./userReducer"
import countryReducer from "./countryReducer"
import domainReducer from "./domainReducer"
import expertReducer from "./expertReducer"
import sessionReducer from "./sessionReducer"
import wishlistReducer from "./wishlistReducer"
import sidebarReducer from "./sidebarReducer";

const rootReducer = combineReducers({
  user: userReducer,
  countries: countryReducer,
  domains: domainReducer,
  experts: expertReducer,
  sessions: sessionReducer,
  wishlist: wishlistReducer,
  sidebar: sidebarReducer
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;