import React from "react";

function TransactionsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M9.94 19.493l-1.351-1.09v2.445c-.001.016.008.039.039.068.094.09.267.113.4.064l.913-1.487zm1.57 1.266l-.895 1.458a.998.998 0 01-.211.244c-.91.761-2.302.72-3.158-.099-.45-.43-.692-1.025-.655-1.575l-.002-3.849-4.4-3.578a3.22 3.22 0 01.873-5.5l17.572-6.788a.965.965 0 01.906.091 1.002 1.002 0 01.421 1.12L17.17 19.543c-.473 1.703-2.246 2.701-3.958 2.236a3.232 3.232 0 01-1.182-.602l-.52-.42zm-2.395-4.5l4.17 3.362c.132.106.286.184.45.229a1.226 1.226 0 001.507-.84l3.7-13.327-9.827 10.576zm-1.636-1.176L17.317 4.5 3.783 9.726a1.219 1.219 0 00-.332 2.082l4.028 3.275z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TransactionsIcon;