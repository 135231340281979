import React from 'react'
import { Link } from 'react-router-dom'

export const NoBookings:React.FC<any> = ({activeTab}) => {
  return (
    <div className='my-5'>
        <p className="text-muted">You have no {activeTab} bookings - start sharing a conversation with an expert</p>
        <Link to="/bookings/explore" className='btn btn-sm px-4 py-2 border-4 btn-darkBlue small' >Explore Experts</Link>
    </div>
  )
}
