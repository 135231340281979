import React from 'react';
import style from './Index.module.css';
import { useState } from 'react';
import { forgotPassword } from '../../../services/authService';
import Loader from '../../../components/Spinner/Index';
import { toast } from 'react-toastify';
import Input from '../../../components/common/Input/Index';
import Logo from 'components/common/Logo';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
type dataValues = {
  email: string;
};

export const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email address')
});

const VerificationInput = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<dataValues>({
    defaultValues: {
      email: ''
    },
    resolver: joiResolver(schema)
  });
  const [isLoading, setIsLoading] = useState(false);
  const submitHandler = (data: any) => {
    setIsLoading(true);
    forgotPassword({ email: data.email })
      .then((res) => {
        toast.success(
          res?.data?.data?.message ||
            'Password Reset Mail has been sent to your email'
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className={style.input_group}>
        <Input
          labeltext='Email address'
          placeholder='Enter your Email address'
          type='text'
          {...register('email')}
        />
        {errors?.email?.message && (
          <small className={style.validation_message}>
            {errors?.email?.message}
          </small>
        )}
      </div>
      <div className={style.login_btn}>
        <button>{isLoading ? <Loader /> : 'Submit'}</button>
      </div>
    </form>
  );
};

const Index = () => {
  return (
    <div className={style.container}>
      <div className={style.logo_wrapper}>
        <Logo />
      </div>
      <div className={style.content_wrapper}>
        <div className={style.login_wrapper}>
          <h2 className={style.header}>Forgot Password</h2>
          <p>Enter Email associated with your account</p>
          <VerificationInput />
        </div>
      </div>
    </div>
  );
};

export default Index;
