import React from "react";
import { Badge } from "reactstrap";

const CompletedBadge = () => {
  return (
    <Badge color="yellow" className="small mx-1 text-white">
      {" "}
      Completed
    </Badge>
  );
};

export default CompletedBadge;
