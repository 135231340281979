import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "components/common/ScrollToTop";
import {
  auth,
  dashboard,
  expert,
  landing,
  routeInterface,
  privacy,
  faq,
  terms
} from "routes";
import BaseLayout from "layout/BaseLayout";
import { Layout } from "layout/Layout";
import { DashboardLayout } from "layout/DashboardLayout";

const childRoutes = (Layout: any, routes: Array<any>) =>
  routes.map(
    (
      {
        children,
        path,
        component: Component,
        name,
        header,
        hideFooter,
      }: routeInterface,
      index: number
    ) =>
      children ? (
        // Route item with children
        children.map(
          (
            {
              path,
              component: Component,
              name,
              header,
              hideFooter,
            }: routeInterface,
            index: number
          ) => (
            <Route
              key={index}
              path={path}
              element={
                <Layout header={header} hideFooter={hideFooter}>
                  <ScrollToTop>
                    <Component ComponentName={name} />
                  </ScrollToTop>
                </Layout>
              }
            />
          )
        )
      ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          element={
            <Layout header={header} hideFooter={hideFooter}>
              <ScrollToTop>
                <Component ComponentName={name} />
              </ScrollToTop>
            </Layout>
          }
        />
      )
  );

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {childRoutes(BaseLayout, landing)}
        {childRoutes(BaseLayout, expert)}
        {childRoutes(Layout, auth)}
        {childRoutes(DashboardLayout, dashboard)}
        {childRoutes(BaseLayout, privacy)}
        {childRoutes(BaseLayout, faq)}
        {childRoutes(BaseLayout, terms)}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
