import BookingsIcon from 'components/common/SidebarIcons/BookingsIcon';
import GroupSessionIcon from 'components/common/SidebarIcons/GroupSessionIcon';
import InviteIcon from 'components/common/SidebarIcons/InviteIcon';
import NotificationsIcon from 'components/common/SidebarIcons/NotificationsIcon';
import OverviewIcon from 'components/common/SidebarIcons/OverviewIcon';
import ProfileIcon from 'components/common/SidebarIcons/ProfileIcon';
import RatingIcon from 'components/common/SidebarIcons/RatingIcon';
import TransactionsIcon from 'components/common/SidebarIcons/TransactionsIcon';
import WishListIcon from 'components/common/SidebarIcons/WishListIcon';
import About from 'Pages/About';
// import ForgotPassword from 'Pages/Auth/Forgot';
import ForgotPass from 'Pages/Auth/Forgot_Password/Index';
import { Handover } from 'Pages/Auth/Handover';
// import Login from 'Pages/Auth/Login';
import Onboarding from 'Pages/Auth/Onboarding';
import Bookings from 'Pages/Bookings';
import Privacy from 'Pages/Privacy';
import Terms from 'Pages/Terms';
import BookingsDetails from 'Pages/Bookings/BookingDetails';
import Explore from 'Pages/Bookings/Explore';
import Dashboard from 'Pages/Dashboard';
import Expert from 'Pages/Expert';
import Questions from 'Pages/Faq';
import GroupSessions from 'Pages/GroupSession';
import SessionDetails from 'Pages/GroupSession/SessionDetails';
import Invite from 'Pages/Invite';
import Landing from 'Pages/Landing';
import Search from 'Pages/Landing/Search';
import { Meeting } from 'Pages/Meeting';
import Notifications from 'Pages/Notifications';
import Profile from 'Pages/Profile';
import Reviews from 'Pages/Reviews';
import Transactions from 'Pages/Transactions';
import WishList from 'Pages/WishList';
import Verify from 'Pages/Auth/Verify_Mail/index';
import AfterSignUp from 'Pages/Auth/Verify_Mail/Message';
import NewLogin from 'Pages/Auth/NewLogin';
import VerifyOtp from 'Pages/Auth/Verify_Otp/Index';
import NewOnboardingPage from 'Pages/Auth/Signup/Index';
import ForgotNewPassword from 'Pages/Auth/NewLogin/forgot_password';
export interface routeInterface {
  path: string;
  name: string;
  header?: string;
  exact?: boolean;
  component?: any;
  image?: any;
  badgeColor?: any;
  badgeText?: any;
  icon?: any;
  classProp?: string;
  hideFooter?: boolean;
  children?: Array<routeInterface>;
  isComingSoon?: boolean;
  hideSideBar?: boolean;
}

const landingRoutes: routeInterface = {
  path: '/',
  name: 'Landing',
  header: 'Landing',
  children: [
    {
      path: '/',
      name: 'Landing',
      header: 'Landing',
      component: Landing
    },
    {
      path: '/about',
      name: 'About',
      header: 'About',
      component: About
    },
    {
      path: '/search',
      name: 'Search',
      header: 'Search',
      hideFooter: false,
      component: Search
    }
  ]
};

const authRoutes: routeInterface = {
  path: '/auth',
  name: 'Auth',
  header: 'Auth',
  children: [
    {
      path: '/auth/signup',
      name: 'Onboarding',
      header: 'Onboarding',
      component: Onboarding
    },
    {
      path: '/auth/login',
      name: 'Login',
      header: 'Login',
      // component: Login
      component: NewLogin
    },

    {
      path: '/auth/handover',
      name: 'Handover',
      header: 'Handover',
      component: Handover
    },
    {
      path: '/auth/forgot-password',
      name: 'Forgot Password',
      header: 'Forgot Password',
      component: ForgotPass
      // component: ForgotPassword
    },
    {
      path: '/auth/verify',
      name: 'Verify Mail',
      header: 'Verify Mail',
      component: Verify
    },
    {
      path: '/auth/verify-otp',
      name: 'Verify Otp',
      header: 'Verify',
      component: VerifyOtp
    },
    {
      path: '/auth/new-signup',
      name: 'Verify Otp',
      header: 'Verify',
      component: NewOnboardingPage
    },
    {
      path: '/auth/message',
      name: 'Notification',
      component: AfterSignUp
    }
  ]
};

const dashboardRoute: routeInterface = {
  path: '/overview',
  name: 'Overview',
  icon: OverviewIcon,
  component: Dashboard
};

const bookingsRoute: routeInterface = {
  path: '/bookings',
  name: 'My Bookings',
  header: 'My Bookings',
  icon: BookingsIcon,
  // component: Bookings
  children: [
    {
      path: '/bookings/explore',
      name: 'Bookings',
      component: Explore,
      hideFooter: false
    },
    {
      path: '/bookings/:id',
      name: 'My Booking Details',
      component: BookingsDetails
    },
    {
      path: '/bookings',
      name: 'Bookings',
      header: 'My Bookings',
      component: Bookings
    }
  ]
};

const groupSessionRoute: routeInterface = {
  path: '/group-sessions',
  name: 'My Group Sessions',
  header: 'My Group Sessions',
  icon: GroupSessionIcon,
  children: [
    {
      path: '/group-sessions/:id',
      name: 'Session Details',
      component: SessionDetails
    },
    {
      path: '/group-sessions',
      name: 'Group Sessions',
      header: 'My Group Sessions',
      component: GroupSessions
    }
  ]
};
const FaqRoute: routeInterface = {
  path: '/faq',
  name: 'Faq',
  header: 'Questions and Answers',
  icon: TransactionsIcon,
  component: Questions
};

const transactionsRoute: routeInterface = {
  path: '/transactions',
  name: 'Transactions',
  header: 'Transaction History',
  icon: TransactionsIcon,
  component: Transactions
};

const privacyRoute: routeInterface = {
  path: '/privacy',
  name: 'Privacy',
  header: 'Privacy History',
  icon: TransactionsIcon,
  component: Privacy
};

const termsRoute: routeInterface = {
  path: '/terms',
  name: 'Terms',
  header: 'Terms of Use Agreement',
  icon: TransactionsIcon,
  component: Terms
};

const wishlistRoute: routeInterface = {
  // path: '/wishlist',
  path: '#',
  name: 'Wishlist',
  header: 'Wishlist',
  icon: WishListIcon,
  isComingSoon: true,
  component: WishList
};

const profileRoute: routeInterface = {
  path: '/profile',
  name: 'Profile',
  header: 'Profile',
  classProp: 'sidebar-top-divider',
  icon: ProfileIcon,
  component: Profile
};

const inviteRoute: routeInterface = {
  // path: '/invite',
  path: '#',
  name: 'Invite',
  isComingSoon: true,
  header: 'Invite',
  icon: InviteIcon,
  component: Invite
};

const reviewRoute: routeInterface = {
  path: '/review',
  name: 'Review',
  header: 'Reviews',
  icon: RatingIcon,
  component: Reviews
};

const notificationsRoute: routeInterface = {
  // path: '/notifications',
  path: '#',
  name: 'Notifications',
  isComingSoon: true,
  header: 'Notifications',
  icon: NotificationsIcon,
  component: Notifications
};

const expertRoute: routeInterface = {
  path: '/expert/:id',
  name: 'Expert',
  children: [
    {
      path: '/sessions/:id',
      name: 'Session Details',
      hideFooter: false,
      component: () => {
        return (
          <div className='external-session-details'>
            {' '}
            <SessionDetails />
          </div>
        );
      }
    },
    {
      path: '/expert/:id',
      name: 'Expert',
      hideFooter: false,
      component: Expert
    }
  ]
};

const meetingRoute: routeInterface = {
  path: '/meet/:id',
  name: 'Meeting',
  component: Meeting,
  hideSideBar: true
};

export const landing: Array<routeInterface> = [landingRoutes];
export const auth: Array<routeInterface> = [authRoutes];
export const expert: Array<routeInterface> = [expertRoute];
export const privacy: Array<routeInterface> = [privacyRoute];
export const faq: Array<routeInterface> = [FaqRoute];
export const terms: Array<routeInterface> = [termsRoute];

export const dashboard: Array<routeInterface> = [
  dashboardRoute,
  bookingsRoute,
  groupSessionRoute,
  wishlistRoute,
  transactionsRoute,
  notificationsRoute,
  profileRoute,
  reviewRoute,
  inviteRoute,
  meetingRoute
];
const routes: Array<routeInterface> = [
  dashboardRoute,
  bookingsRoute,
  groupSessionRoute,
  wishlistRoute,
  profileRoute,
  transactionsRoute,
  notificationsRoute,
  reviewRoute,
  inviteRoute
];

export default routes;
