import * as types from "../Actions/types";

const initialState = {
  status: types.async_status.__DEFAULT__,
  data: [],
  subDomainStatus: types.async_status.__DEFAULT__,
  subDomains: [],
  isLoading: false,
};

const domainReducer = (state = initialState, action: types.Actonsnterface) => {
  switch (action.type) {
    case types.DOMAIN_DATA_LOADING:
      return {
        ...state,
        status: types.async_status.__LOADING__,
        isLoading: true,
      };
    case types.DOMAIN_DATA_SUCCESS:
      return {
        ...state,
        status: types.async_status.__LOADED__,
        data: action.payload.data,
        isLoading: false,
      };
    case types.DOMAIN_DATA_FAILED:
      return {
        ...state,
        status: types.async_status.__FAILED__,
        data: [],
        isLoading: false,
      };
    case types.SUB_DOMAIN_DATA_LOADING:
      return {
        ...state,
        subDomainStatus: types.async_status.__LOADING__,
        isLoading: true,
      };
    case types.SUB_DOMAIN_DATA_SUCCESS:
      return {
        ...state,
        subDomainStatus: types.async_status.__LOADED__,
        subDomains: action.payload.data,
        isLoading: false,
      };
    case types.SUB_DOMAIN_DATA_FAILED:
      return {
        ...state,
        subDomainStatus: types.async_status.__FAILED__,
        subDomains: [],
        isLoading: false,
      };

    default:
      return state;
  }
};

export default domainReducer;
