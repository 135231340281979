import axios from "./apiAdapter";

export const createSession = (data: any) => axios.post(`/session`, data);

export const getAllGroupSessions = (
  PageSize = 20,
  PageNumber?: any,
  search?: any
) =>
  axios.get(
    `/session/group?perPage=${PageSize}&pageNo=${PageNumber}${
      search ? `&Search=${search}` : ""
    }`
  );

export const getSingleGroupSession = (id: any) => axios.get(`/session/${id}`);

export const enrollGroupSession = (id: any, data: any) =>
  axios.post(`/session/${id}/attend`, data);

export const getLearnerGroupSessions = (
  PageSize = 20,
  PageNumber?: any,
  tab?: any
) =>
  axios.get(
    `/session/group/learner?perPage=${PageSize}&pageNo=${PageNumber}${
      tab ? `&status=${tab}` : ""
    }`
  );

export const getLearnerOneOnOneSessions = (
  PageSize = 20,
  PageNumber?: any,
  tab?: any,
  acceptStatus?: any
) =>
  axios.get(
    `/session/single/learner?perPage=${PageSize}&pageNo=${PageNumber}${
      tab ? `&status=${tab}` : ""
    }${acceptStatus ? `&approveStatus=0` : ""}`
  );

// export const getExpetGroupSessions = (expertId: any, PageSize = 20, PageNumber: any) => axios.get(`/session/booked/${expertId}?perPage=${PageSize}&pageNo=${PageNumber}`);

export const getExpetGroupSessions = (
  expertId: any,
  PageSize = 20,
  PageNumber: any,
  status: string
) =>
  axios.get(
    `/session/group?perPage=${PageSize}&pageNo=${PageNumber}&expertId=${expertId}&status=${status.toLowerCase()}`
  );

export const getExpetBookedDays = (expertId: any) =>
  axios.get(`/session/booked/${expertId}`);

export const createSessionPayment = (data: any) =>
  axios.post(`/session/payment/create`, data);
export const getApiKeys = () => axios.get(`keys`);

export const getAttendance = (id: any) => axios.get(`session/${id}/attend`);
