import React from "react";

function RightCaretIcon({ open }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={open? "14": "8"}
      height={open? "9": "12"}
      fill="none"
      viewBox={open? "0 0 14 9" : "0 0 8 15"}

    >
      {open ? <path
        stroke="#6E7191"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1.5l6 6 6-6"
      ></path> :
        <path
          stroke="#6E7191"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 13.5l6-6-6-6"
        ></path>
      }
    </svg>
  );
}

export default RightCaretIcon;