import Experts from 'Pages/Bookings/Explore/Components/Experts';
import ExploreTabs from 'Pages/Bookings/Explore/Components/ExploreTabs';
import SearchComponent from 'Pages/Bookings/Explore/Components/SearchComponent';
import Sessions from 'Pages/Bookings/Explore/Components/Sessions';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const MapSubComponents: React.FC<{ activeTab: string; search: string }> = ({
  activeTab,
  ...props
}) => {
  let componnents: Record<string, any> = {
    [`Experts`]: <Experts col={{ md: 6, lg: 3 }} {...props} />,
    [`Group Sessions`]: <Sessions col={{ md: 6, lg: 3 }} {...props} />
  };

  return componnents[activeTab];
};

const Search: React.FC<any> = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const search = searchParams.get('search');
  const [state, setState] = useState({
    tabs: ['Experts', 'Group Sessions'],
    activeTab: 'Experts',
    search: search || '',
    searchSent: '',
    loaded: false
  });

  const updateTab = (param: string) => {
    setState((prev) => ({
      ...prev,
      activeTab: param
      // search: ""
    }));
  };

  const handleChange = (e: any) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      searchSent: prev.search
    }));
  };
  useEffect(() => {
    if (tab && tab === 'group') {
      updateTab('Group Sessions');
    }
    const searchVal = search ? search : '';
    setState((prev: any) => {
      return {
        ...prev,
        search: searchVal,
        searchSent: search,
        loaded: true
      };
    });
  }, [tab, search]);

  const resetSearchField = () => {
    setState({
      ...state,
      search: '',
      searchSent: ''
    });
  };
  if (!state.loaded) return <></>;
  return (
    <div className='py-5 mt-5'>
      <Container>
        <Row>
          <Col sm='12'>
            <ExploreTabs
              tabs={state.tabs}
              activeTab={state.activeTab}
              updateTab={updateTab}
            />
          </Col>
          <Col md='7'>
            <SearchComponent
              name='search'
              handleSearch={handleSearch}
              handleChange={handleChange}
              search={state.search}
              resetfield={resetSearchField}
            />
          </Col>
        </Row>
      </Container>
      <MapSubComponents activeTab={state.activeTab} search={state.searchSent} />
    </div>
  );
};

export default Search;
