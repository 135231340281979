import Button from 'components/common/Button';
import { onChange, apiErrorHandler } from 'Helpers/utility';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { async_status } from 'redux/Actions/types';
import { RootState } from 'redux/Reducers';
import { addUser } from 'services/userService';
import Form from './Form';
import UserType from '../../../../UserType/Index';

interface stateInterface {
  formProfessional?: any;
  formRegister?: any;
  topDomains: string[];
  userDomains: string[];
  viewStep: number;
  selectedCardIndex?: string;
}

export const Community: React.FC<any> = ({
  updateTab,
  userDetails,
  ...props
}) => {
  const { domains } = useSelector((state: RootState) => state);

  const [state, setState] = useState<stateInterface>({
    formRegister: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      timezone: '',
      password: '',
      confirmPassword: ''
    },
    formProfessional: {
      domain: ''
    },
    topDomains: [],
    userDomains: [],
    viewStep: 1,
    selectedCardIndex: '0'
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      formRegister: {
        ...prev.formRegister,
        ...userDetails
      }
    }));
  }, [userDetails]);

  useEffect(() => {
    if (domains.subDomainStatus === async_status.__LOADED__) {
      const newDomains = domains.subDomains.map((a: any) => a.name);
      setState((prev) => ({
        ...prev,
        topDomains: newDomains
      }));
    }
  }, [domains]);

  const handleChange = (e: HTMLInputElement) => {
    onChange(e, setState);
  };
  const [showMessage, setMessage] = useState(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onSubmit = async () => {
    if (userTimezone !== state.formRegister.timezone) {
      toast.error(` Note: Kindly ensure your computer or mobile device's timezone
      is the same as ${userTimezone}`);
      setMessage(true);
    } else {
      try {
        setMessage(false);
        setLoading(true);
        // let { data } = await register(
        //   {
        //     ...state.formRegister,
        //     "username": state.formRegister.email,
        //     domains: state.userDomains
        //   }
        // )

        let {
          data: { data }
        } = await addUser({
          ...state.formRegister,
          username: state.formRegister.email,
          domains: state.userDomains
        });
        localStorage.setItem('authToken', data.authToken);

        // dispatch({
        //   type: USER_DATA_SUCCESS,
        //   payload: { data: data }
        // })

        return (window.location.href = '/auth/message');

        // dispatch(fetchUser())
        // updateTab("personal", state.formRegister)
      } catch (error: any) {
        apiErrorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  };

  // const onContinue = async () => {
  //   if (state.selectedCardIndex === '') {
  //     toast.error('Select User Type');
  //     return;
  //   }
  //   if (state.selectedCardIndex === '1') {
  //     window.open(
  //       `${process.env.REACT_APP_EXPERT_APP_URL}/auth/coach-request-invite`,
  //       '_self'
  //     );
  //     return;
  //   }
  //   updateStep(2);
  // };

  const updateStep = (step: number | undefined = 1) =>
    setState((prev) => ({
      ...prev,
      viewStep: step
    }));

  // const setSelectedCard = (index: any) => {
  //   setState((prev) => ({
  //     ...prev,
  //     selectedCardIndex: index
  //   }));
  // };

  const onDomainSubmit = (e: any) => {
    e.preventDefault();
    let selected = state.formProfessional.domain.trim();
    if (!state.userDomains?.includes(selected)) {
      setState((prev: any) => {
        return {
          ...prev,
          formProfessional: {
            ...prev.formProfessional,
            domain: ''
          },
          userDomains: [...prev.userDomains, selected]
        };
      });
    }
  };

  const onRemoveDomain = (index: number) => {
    let domains = state.userDomains;
    domains.splice(index, 1);

    setState((prev) => ({
      ...prev,
      userDomains: domains
    }));
  };

  const topDomainClick = (index: number) => {
    let selected = state.topDomains[index];
    if (!state.userDomains?.includes(selected)) {
      setState((prev: any) => {
        return {
          ...prev,
          userDomains: [...prev.userDomains, selected]
        };
      });
    }
  };

  const [userType, setUserType] = useState('');
  const [component, setComponent] = useState('');

  useEffect(() => {
    setUserType('');
    setComponent('');
  }, []);

  return (
    <>
      {component === '' && (
        <>
          <UserType
            headerText={
              'We are glad you are here! How do you want to be part of this community?'
            }
            Page='SignUp'
            userType={userType}
            setComponent={setComponent}
            setUserType={setUserType}
          />
        </>
      )}
      {component === 'learner' && (
        <Card>
          <CardBody>
            <Container>
              <Row>
                <Col sm='12' className='text-center'>
                  <h2 className='text-grayScale'>Join Coachmie Community</h2>
                  <p className='text-muted'>
                    Just a few more information we need and you’ll be on your
                    way
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm='12'>
                  <Form
                    formName={'formRegister'}
                    formControl={state.formRegister}
                    onChange={handleChange}
                    updateStep={updateStep}
                    onSubmit={onSubmit}
                    localTimeZone={userTimezone}
                    loadingDomains={domains.status === async_status.__LOADING__}
                    topDomains={state.topDomains}
                    userDomains={state.userDomains}
                    topDomainClick={topDomainClick}
                    onRemoveDomain={onRemoveDomain}
                    handleChange={handleChange}
                    onDomainSubmit={onDomainSubmit}
                    formProfessional={state.formProfessional}
                  />
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
    </>
  );
};
