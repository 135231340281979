import React, { useCallback, useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/Reducers";
import moment from "moment";
import AddReview from "./AddReview";
import { toggleSidebar } from "redux/Actions/sidebarAction";
import { toast } from "react-toastify";

const MeetingView: React.FC<any> = ({ dateEvaluation, data, id }) => {
  const { evaluation, fromDateBeforeNow } = dateEvaluation;
  const {
    user: {
      data: { data: user },
    },
  } = useSelector((state: RootState) => state);
  const [meetingActive, setMeetingActive] = useState(true);
  const dispatch = useDispatch();

  const meetingTimer = useCallback(() => {
    // to end the meeting once end date is reached
    const now = moment().local().format();
    const to = moment(data.toDate).local().format();

    const toDateAfterNow = moment(now).isAfter(to);
    const timeReminder = moment(data.toDate).subtract(10, "minutes");
    const fiveMinsTimeReminder = moment(data.toDate).subtract(5, "minutes");
    const timeReminderAfterNow = moment(now).isSame(timeReminder);
    const fiveMinsTimeReminderAfterNow =
      moment(now).isSame(fiveMinsTimeReminder);
    if (timeReminderAfterNow) {
      toast.warning(
        `This session would expire in 10 minutes and you will be disconnected`,
        {
          autoClose: 300000, //5 mins
        }
      );
    }
    if (fiveMinsTimeReminderAfterNow) {
      toast.warning(
        `This session would expire in 5 minutes and you will be disconnected`,
        {
          autoClose: 300000, //5 mins
        }
      );
    }
    if (toDateAfterNow) {
      setMeetingActive(false);
    }
  }, [data.toDate]);

  const toggleActive = () => {
    setMeetingActive((prev) => !prev);
    dispatch(toggleSidebar(false));
  };

  useEffect(() => {
    if (evaluation) {
      dispatch(toggleSidebar(true));
    }
    return () => {
      dispatch(toggleSidebar(false));
    };
  }, [dispatch, evaluation]);

  useEffect(() => {
    const interval = setInterval(meetingTimer, 1000);

    return () => clearInterval(interval);
  }, [meetingTimer]);
  if (!meetingActive) {
    // return (<div>
    //     You've Exit the Meeting
    //     <div className='d-flex align-items-center'>
    //         <ButtonComponent color="primary" onClick={toggleActive} text="Rejoin" />
    //         <Link
    //             to={`/group-sessions/${id}`}
    //             className="ml-2 btn btn-danger"
    //         >Meeting Details</Link>
    //     </div>
    // </div>)

    return <AddReview meetingData={data} id={id} />;
  }

  return (
    <div>
      {evaluation ? (
        <>
          <JitsiMeeting
            jwt={data.token}
            //domain="coachmie-meet-u8485.vm.elestio.app"
            domain="8x8.vc"
            roomName={data.roomName}
            configOverwrite={{
              startWithAudioMuted: true,
              disableModeratorIndicator: true,
              startScreenSharing: true,
              enableEmailInStats: false,
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            }}
            userInfo={{
              displayName: `${user.firstname} ${user.lastname}`,
              email: user.username,
            }}
            onApiReady={(externalApi) => {
              // here you can attach custom event listeners to the Jitsi Meet External API
              // you can also store it locally to execute commands
            }}
            getIFrameRef={(node) => {
              node.style.height = "90vh";
              node.style.width = "100%";
            }}
            onReadyToClose={(param?: any) => {
              toggleActive();
            }}
          />
        </>
      ) : (
        <div>
          Meeting{" "}
          {!fromDateBeforeNow
            ? `starts at ${moment(data.fromDate)
                .local()
                .format("MMMM Do YYYY, h:mm a")}`
            : `ended at ${moment(data.toDate)
                .local()
                .format("MMMM Do YYYY, h:mm a")}`}
        </div>
      )}
    </div>
  );
};

export default MeetingView;
