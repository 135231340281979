import ExpertCards from "components/common/ExpertCards";
import Loader from "components/common/Loader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { RootState } from "redux/Reducers";
import { async_status } from "redux/Actions/types";
import { fetchExperts } from "redux/Actions/expertAction";

const ExpertsSession = () => {
  const { experts } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      experts.status === async_status.__DEFAULT__ ||
      experts.status === async_status.__FAILED__
    ) {
      dispatch(fetchExperts());
    }
  }, [dispatch, experts.status]);
  var settings = {
    draggable: true,
    dots: false,
    autoplay: false /* this is the new line */,
    autoplaySpeed: 500,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      // {
      //     breakpoint: 700,
      //     settings: {
      //         slidesToShow: 2,
      //         slidesToScroll: 1,
      //         initialSlide: 2
      //     }
      // },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // centerMode: true,
    // touchThreshold: 1000,
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col sm="12">
          <div className="d-flex">
            <h3 className="text-grayScale expert-group-head">
              Experts for you
            </h3>
            <Link to="/bookings/explore" className="ml-auto expert-group-head">
              See All
            </Link>
          </div>
        </Col>

        <Col sm="12">
          {experts.status === async_status.__LOADING__ ? (
            <Loader />
          ) : (
            <ExpertCards cardInfo={experts.data} settings={settings} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ExpertsSession;
