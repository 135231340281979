import Loader from 'components/common/Loader';
import React from 'react';
import EmptyReview from './EmptyReview';
import InfiniteScroll from 'components/common/InfiniteScroll';
import GroupSessionIcon from 'components/common/SidebarIcons/GroupSessionIcon';
import { Table } from 'reactstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const Sessions: React.FC<any> = ({
  sessionState: { data: mapData, pageSize, pageNo, hasMore, loading },
  fetchSessions
}) => {
  const navigate = useNavigate();

  return (
    <div className='text-center'>
      {loading && (
        <div className='h-100'>
          <Loader />
        </div>
      )}
      {!loading && (
        <>
          {mapData.length === 0 ? (
            <div className='text-center'>
              <EmptyReview />
              <h3 className='text-grayScalePlaceholder'>
                No sessions at this time.
              </h3>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={mapData.length}
              next={() => {
                fetchSessions(pageNo + 1);
              }}
              hasMore={hasMore}>
              <Table responsive striped={false}>
                <thead>
                  <tr>
                    {['Dates', 'Name', 'Time', 'Expert'].map((head) => (
                      <td className='text-grayScale'>{head}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {mapData.map((data: any) => (
                    <tr
                      key={data.sessionId}
                      onClick={() =>
                        navigate(`/group-sessions/${data.sessionId}`)
                      }
                      className='cursor-pointer'>
                      <td className='text-muted'>
                        {moment(data.fromDate).local().format('MMMM Do, YYYY')}
                      </td>
                      <td className='text-muted'>
                        <GroupSessionIcon />
                        {data.Session?.title}
                      </td>
                      <td className='text-muted'>
                        {moment(data.fromDate).local().format('h:mm a')}
                      </td>
                      <td className='text-muted'>
                        {data?.expert?.profilePages?.registrationPage
                          ?.firstname ||
                          data?.expert?.profilePages?.registrationPage
                            ?.firstName}{' '}
                        {data?.expert?.profilePages?.registrationPage
                          ?.lastname ||
                          data?.expert?.profilePages?.registrationPage
                            ?.lastName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          )}
        </>
      )}
    </div>
  );
};
