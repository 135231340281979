import LogoutIcon from 'components/common/LogoutIcon';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducers';
import { handleLogout } from 'services/authService';

const Navbar: React.FC<any> = ({ toggleSidebar, toggled, header }) => {
  const {
    sidebar: { hidden }
  } = useSelector((state: RootState) => state);

  return (
    <nav id='navbar'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            {!toggled && !hidden && (
              <div
                id='hamburger-icon'
                onClick={toggleSidebar}
                className={toggled ? 'open' : ''}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
          <div className='col-12'>
            <div className='d-flex align-items-center'>
              <h5 className='pt-3 pt-md-5 pb-2 px-0 page-header'>{header}</h5>

              {/* <i className="ml-auto fa-solid fa-power-off"></i> */}
              {/* <i className="fa-solid fa-power-off"></i> */}
              <div
                className='ml-auto cursor-pointer'
                onClick={() => handleLogout()}>
                <LogoutIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
