import FormsWrapper, { showFieldError } from 'components/Forms/Formik';
import React from 'react';
import * as Yup from 'yup';
import { Col, Container, Input, Row } from 'reactstrap';
import Button from 'components/common/Button';
import { DateTime } from 'luxon';

interface formInterface {
  formControl: any;
  onSubmit?: any;
  onChange?: any;
  formName: any;
  zone: any;
  getNextTimeValue: (e: any | undefined) => any;
  goBack: () => any;
}

const ConfirmForm: React.FC<formInterface> = React.memo((props) => {
  let {
    formControl,
    onChange,
    onSubmit,
    formName,
    getNextTimeValue,
    goBack,
    zone
  } = props;

  let validationObj: Record<any, any> = React.useMemo(
    () => {
      let obj: Record<any, any> = {};

      // if (formControl.topic === "") {
      obj.topic = Yup.string()
        .required('Required')
        .max(50, 'Maximum of 50 characters');
      // }

      // if (formControl.questions === "") {
      obj.questions = Yup.string()
        .required('Required')
        .max(500, 'Maximum of 500 characters');
      // }

      return obj;
    },
    // [formControl.topic, formControl.questions]
    []
  );

  const validation = Yup.object().shape(validationObj);

  return (
    <FormsWrapper
      values={formControl}
      handleSubmit={onSubmit}
      handleChange={onChange}
      validationSchema={validation}>
      {(props: {
        values: any;
        isValid: any;
        touched: any;
        errors: any;
        handleBlur: any;
        handleChange: any;
        handleSubmit: any;
        isSubmitting: any;
      }) => {
        const {
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        } = props;
        return (
          <form name={formName} onChange={onChange} onSubmit={handleSubmit}>
            <Container fluid>
              <Row>
                <Col md='6'>
                  <p
                    style={{
                      fontSize: '13.7px',
                      padding: '0',
                      marginBottom: '0.15rem'
                    }}>
                    Expert Session Date and Time
                  </p>
                  <div className='d-flex justify-content-start confirm-date'>
                    <p className='mb-0'>
                      {' '}
                      <img
                        src={
                          require(`assets/images/svg/icons/calendar.svg`)
                            .default
                        }
                        className='mr-2'
                        alt=''
                      />
                    </p>
                    <div>
                      <p className='ml-2 mb-0'>
                        {DateTime.fromISO(formControl.date, {
                          zone: zone.learnerTimeZone
                        })
                          .setZone(zone.expertTimeZone, { keepLocalTime: true })
                          .toFormat('EEEE LLLL dd')}
                      </p>
                      <div className='d-flex'>
                        <p
                          style={{
                            fontSize: '14px'
                          }}
                          className='ml-2 mb-0'>
                          {DateTime.fromFormat(formControl.time[0], 'hh:mm a', {
                            zone: zone.learnerTimeZone
                          })
                            .setZone(zone.expertTimeZone)
                            .toFormat('hh:mm a')}
                          -{' '}
                          {DateTime.fromFormat(
                            getNextTimeValue(
                              formControl.time[formControl.time.length - 1]
                            ),
                            'hh:mm a',
                            { zone: zone.learnerTimeZone }
                          )
                            .setZone(zone.expertTimeZone)
                            .toFormat('hh:mm a')}
                        </p>
                        {/* <p
                          className='ml-2 mb-0'
                          style={{
                            fontSize: '14px'
                          }}>
                          {DateTime.fromFormat(
                            getNextTimeValue(
                              formControl.time[formControl.time.length - 1]
                            ),
                            'hh:mm a',
                            { zone: zone.learnerTimeZone }
                          )
                            .setZone(zone.expertTimeZone)
                            .toFormat('hh:mm a')}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md='6'>
                  <p
                    style={{
                      fontSize: '13.7px',
                      padding: '0',
                      marginBottom: '0.15rem'
                    }}>
                    Learner Session Date and Time
                  </p>
                  <div className='d-flex justify-content-start confirm-date'>
                    <p className='mb-0'>
                      {' '}
                      <img
                        src={
                          require(`assets/images/svg/icons/calendar.svg`)
                            .default
                        }
                        className='mr-2'
                        alt=''
                      />
                    </p>
                    <div>
                      <p className='ml-2 mb-0'>
                        {DateTime.fromISO(formControl.date, {
                          zone: zone.learnerTimeZone
                        }).toFormat('EEEE LLLL dd')}
                      </p>

                      <p
                        style={{
                          fontSize: '14px'
                        }}
                        className='ml-2 mb-0'>
                        {formControl.time[0]} -{' '}
                        {getNextTimeValue(
                          formControl.time[formControl.time.length - 1]
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md='6'></Col>
                <Col md='6'></Col>
              </Row>
              <Row>
                <Col md='12'>
                  <label htmlFor='' className='small text-muted mt-4'>
                    Brief information on what to learn
                  </label>
                  <Input
                    type='text'
                    className='form-control'
                    name='topic'
                    onBlur={handleBlur}
                    value={formControl.topic}
                    invalid={touched.topic && errors.topic}
                    onChange={handleChange}
                  />
                  {showFieldError('topic', errors, touched)}
                </Col>
                <Col md='12'>
                  <label htmlFor='' className='small text-muted mt-4'>
                    More details{' '}
                    <span className='small'>(Maximum of 500 characters)</span>
                  </label>
                  <Input
                    type='textarea'
                    className='form-control'
                    name='questions'
                    rows='6'
                    onBlur={handleBlur}
                    value={formControl.questions}
                    invalid={touched.questions && errors.questions}
                    onChange={handleChange}
                  />
                  {showFieldError('questions', errors, touched)}
                </Col>
              </Row>
              <Row>
                <Col md='12' className='d-block mt-3'>
                  <Button
                    type='submit'
                    role='submit'
                    color='primary'
                    loading={isSubmitting}
                    className='ml-auto'
                    block
                    text={'Confirm payment'}
                  />
                  <p
                    className='text-primary mt-2 text-center cursor-pointer font-weight-bold'
                    onClick={goBack}>
                    Change Date and time
                  </p>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </FormsWrapper>
  );
});

export default ConfirmForm;
