import { getCountries } from 'services/countryService';
import {
  COUNTRY_DATA_FAILED,
  COUNTRY_DATA_SUCCESS,
  COUNTRY_DATA_LOADING
} from './types';

export const fetchCountries = () => async (dispatch: any) => {
  dispatch({
    type: COUNTRY_DATA_LOADING,
    payload: {}
  });
  try {
    let { data } = await getCountries();
    dispatch({
      type: COUNTRY_DATA_SUCCESS,
      payload: { data: data }
    });
  } catch (e) {
    dispatch({
      type: COUNTRY_DATA_FAILED,
      payload: {}
    });
  }
};
