import style from './Index.module.css';
import { EyeOff, Eye } from 'react-feather';
import { forwardRef } from 'react';

// type InputProps = {
//   type: string;
//   labeltext: string;
//   onChange?: () => void;
//   id: string;
//   register: any;
// };

const Index = forwardRef((props: any, ref: any) => {
  const { type, labeltext, id, dispatch } = props;

  let Input;
  switch (type) {
    case 'text':
      Input = (
        <>
          <label className={style.label} htmlFor={id}>
            {labeltext}
          </label>
          <input {...props} ref={ref} className={style.input} type={type} />
        </>
      );
      break;
    case 'password-hidden':
      Input = (
        <div className={style.input_wrapper}>
          <label>{labeltext}</label>
          <div className={style.password_wrapper}>
            <input {...props} type='password' ref={ref} />
            <div className={style.hidden_icon_wrapper}>
              <EyeOff
                onClick={() => dispatch({ type: 'password-visible' })}
                size={18}
                className={style.hidden_icon}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 'dropdown':
      Input = (
        <>
          <select>
            <option>Drop Down</option>
          </select>
        </>
      );
      break;
    case 'password-visible':
      Input = (
        <div className={style.input_wrapper}>
          <label>{labeltext}</label>
          <div className={style.password_wrapper}>
            <input ref={ref} {...props} type='text' />
            <div className={style.hidden_icon_wrapper}>
              <Eye
                onClick={() => dispatch({ type: 'password-hidden' })}
                size={18}
                className={style.hidden_icon}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 'checkbox':
      Input = (
        <div className={style.checkbox}>
          <label className={style.label_checkbox}>{labeltext}</label>
          <input type='checkbox' />
        </div>
      );
      break;
    case 'checkbox-method':
      Input = (
        <div className={style.checkbox_method}>
          <label className={style.label_checkbox}>{labeltext}</label>
          <input type='checkbox' />
        </div>
      );
      break;
    case 'text-area':
      Input = (
        <>
          <label className={style.label}>{labeltext}</label>
          <textarea {...props} className={style.input_text_area} />
        </>
      );

      break;
    case 'password':
      Input = (
        <>
          <label className={style.label}>{labeltext}</label>
          <input ref={ref} className={style.input} type={type} />;
        </>
      );
      break;
  }
  return <>{Input}</>;
});

export default Index;
