import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

type tabsTypes = {
  tabs: string[];
  activeTab: any;
  onClickTab: (param: string) => void;
  className?: string;
};

const Tabs: React.FC<tabsTypes> = ({
  tabs,
  activeTab,
  onClickTab,
  className
}) => {
  return (
    <div id='tabs'>
      <ButtonGroup className={className}>
        {tabs.map((tab, index) => (
          <Button
            key={index}
            onClick={() => onClickTab(tab)}
            color={`${tab === activeTab ? 'white' : 'transparent'}`}
            className={`${
              tab === activeTab ? 'text-lightBlue active' : 'text-grayScale'
            } text-capitalize px-4 mr-3`}>
            {tab}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Tabs;
