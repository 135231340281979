import React from "react";

function InviteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#A0A3BD"
        fillRule="evenodd"
        d="M14 4a4 4 0 11.707 2.272L7.768 9.654c.15.42.232.874.232 1.346 0 .44-.07.862-.202 1.258l6.9 3.484a4 4 0 11-.681 1.896l-7.312-3.691a4 4 0 11-.077-5.962l7.39-3.602A4.034 4.034 0 0114 4zm6 0a2 2 0 10-4 0 2 2 0 004 0zm0 14a2 2 0 10-4 0 2 2 0 004 0zM4 9a2 2 0 110 4 2 2 0 010-4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InviteIcon;