import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import StarRatings from 'react-star-ratings'
import { fetchReviews } from 'services/userService'
import { apiErrorHandler } from 'Helpers/utility'
import InfiniteScroll from 'components/common/InfiniteScroll'
import Loader from 'components/common/Loader'
import moment from "moment";

const Reviews = () => {
    const [state, setState] = useState({
        reviewData: [],
        pageSize: 20,
        pageNo: 1,
        hasMore: false,
        loading: true,
    })

    const toggleLoading = () => {
        setState((prev: any) => ({
            ...prev,
            loading: !prev.loading
        }))
    }

    const loadReviews = useCallback(async (pageNo = 1) => {
        // pageNo > 1 && toggleLoading();
        try {
            let { data: { data } } = await fetchReviews(state.pageSize, pageNo)
            setState((prev) => ({
                ...prev,
                reviewData: pageNo > 1 ? prev.reviewData.concat(data.reviews) : data.reviews,
                pageNo: pageNo,
                totalRecord: data.count,
                hasMore: ((pageNo * state.pageSize) < data.count),
                loading: false
            }))
        } catch (error) {
            apiErrorHandler(error);
            pageNo === 1 && toggleLoading();

        }
    }, [state.pageSize])

    useEffect(() => {
        loadReviews(1)
    }, [loadReviews])

    return (
        <Container>
            <Row>
                {state.loading && <Loader />}
                {!state.loading && <>
                    {state.reviewData.length === 0 && <Col>
                        <p>No Reviews Posted</p>
                    </Col>}
                    <Col md="12">
                        <InfiniteScroll
                            dataLength={state.reviewData.length}
                            hasMore={state.hasMore}
                            next={() => loadReviews((state.pageNo + 1))}
                        >
                            {state.reviewData.map((review: any, i) => (
                                <div key={i} className="mb-3">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h4>{moment(review.createdAt).local().format('MMMM Do, YYYY')}</h4>
                                        </div>
                                        <div className='ml-auto'>
                                            <StarRatings
                                                rating={Number(review.rating)}
                                                starRatedColor="#F7A32C"
                                                //   changeRating={this.changeRating}
                                                starSpacing='2'
                                                numberOfStars={6}
                                                name='rating'
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className='text-grayScale'>{review.review}</p>
                                    </div>
                                    <div className='d-flex pt-3'>
                                        <div className='ml-auto'>
                                            <img src={review?.expert?.profilePages?.profileMediaPage?.profilePicture || require(`assets/images/svg/dummy-image.svg`).default} className="mr-2 review-img" alt="" />

                                        </div>
                                        <div>
                                            <p className='m-0 small'>
                                                <b>Review for </b><span className="text-capitalize">{review?.expert?.profilePages?.registrationPage?.firstname || review?.expert?.profilePages?.registrationPage?.firstName} {review?.expert?.profilePages?.registrationPage?.lastname || review?.expert?.profilePages?.registrationPage?.lastName}</span>
                                            </p>
                                            {review?.expert?.profilePages?.professionalInformationPage?.jobTitle && review?.expert?.profilePages?.professionalInformationPage?.companyName &&

                                                <p className='m-0 small text-muted'>
                                                    {review?.expert?.profilePages?.professionalInformationPage?.jobTitle} at {review?.expert?.profilePages?.professionalInformationPage?.companyName}
                                                </p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </InfiniteScroll>
                    </Col>
                </>}
            </Row>
        </Container>
    )
}

export default Reviews