import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Container, Row } from 'reactstrap';
import SuccessIcon from './SuccessIcon';
import PopoverComponent from 'components/common/PopoverComponent';
import { getExpertBankAccountInfo } from '../../../../services/expertService';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import { formatMoney } from 'utils/format_money';
import { copyToClipboard } from 'Helpers/utility';
const SuccessModal: React.FC<any> = ({ id, data }) => {
  const copyRef = useRef(null);
  const [showShare, setShowShare] = useState(false);
  const [currency, setCurrency] = useState('');
  const onCopy = () => {
    copyToClipboard(copyRef);
    toast.success('Copied to clipboard');
  };
  const expertId = data.expertId;
  useEffect(() => {
    let isMounted = true;
    const fetchDetail = async () => {
      try {
        const { data } = await getExpertBankAccountInfo(expertId);
        if (isMounted) {
          setCurrency(data.data.currency);
        }
      } catch (err) {
        setCurrency('');
      }
    };
    fetchDetail();
    return () => {
      isMounted = false;
    };
  }, [data.expertId, expertId]);
  return (
    <Container>
      <Row>
        <Col md='6' className='d-flex align-items-center'>
          <SuccessIcon />
        </Col>
        <Col md='6'>
          <p className='small text-muted'>
            You have successfully Enrolled for {data?.titme} on{' '}
            {moment(data?.fromDate).local().format('MMMM Do, YYYY')} at{' '}
            {moment(data?.fromDate).local().format('h:mma')}
          </p>
          <h3 className='small'>
            Kindly look forward to expert acceptance for the booked session
          </h3>
          <div className='d-flex align-items-center mt-3'>
            <img
              src={
                require('assets/images/svg/icons/ic_tickets_two.svg').default
              }
              className='mr-3'
              alt=''
            />
            <div>
              <p className='text-muted mb-0'>
                Ticket :{' '}
                {currency === 'NGN'
                  ? `#${formatMoney(data?.fee)}`
                  : `$${data?.fee}`}
              </p>
            </div>
          </div>
          <div>
            <p className='font-weight-bold my-2'>Know anyone interested</p>
          </div>
          <div className='copy-div bg-light-grey d-flex p-3'>
            <img
              onClick={onCopy}
              src={require('assets/images/svg/icons/ic_copy_2.svg').default}
              className='mr-2 cusor-pointer'
              alt=''
            />
            <p ref={copyRef} className='mb-0 small text-grayScale'>
              {window.location.origin}/sessions/{id}
            </p>
          </div>
          <div className='d-flex'>
            {/* <Button color='lightBlue' className='my-3 py-2' block>Share on LinkedIn</Button> */}
            <Button color='lightBlue' id='share' className='my-3 py-2'>
              Share
            </Button>
            <Button
              onClick={onCopy}
              color='darkBlue'
              id='share'
              className='my-3 py-2 ml-2'>
              Copy
            </Button>
            {/* <Button color='lightBlue' className='py-2' outline block>Share on Twitter</Button> */}
          </div>
          <div className='d-flex'>
            <PopoverComponent
              isOpen={showShare}
              id='share'
              toggle={() => setShowShare((prev) => !prev)}>
              <TwitterShareButton
                url={`${window.location.origin}/sessions/${id}`}
                title={'Checkout this session'}>
                <TwitterIcon />
              </TwitterShareButton>
              <WhatsappShareButton
                url={`${window.location.origin}/sessions/${id}`}
                title={'Checkout this session'}>
                {/* <WhatsApp /> */}
                <WhatsappIcon />
              </WhatsappShareButton>
              <FacebookShareButton
                url={`${window.location.origin}/sessions/${id}`}
                title={'Checkout this session'}>
                <FacebookIcon />
              </FacebookShareButton>
            </PopoverComponent>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessModal;
