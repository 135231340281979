import React from "react";
import Lottie from "react-lottie";

const animationData = require("./lottiefile.json")

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const SuccessIcon = () => {
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={"100%"}
        width={"60%"}
      />
    </div>
  );
}

export default SuccessIcon;