import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { RootState } from 'redux/Reducers'

const UserInfo: React.FC<any> = () => {
    const { user: { data: { data: user } } } = useSelector((state: RootState) => state)
    return (
        <Container>
            <Row>
                <Col md="6">
                    <h2 className='page-header text-capitalize'>Hello, {user.firstname} {user.lastname} 👋🏽</h2>
                    <p className="text-muted">{user.username}</p>
                </Col>
                <Col md="6" className='text-left text-md-right d-none d-md-block'>
                    <Link to="/bookings/explore?tab=group" className='btn btn-darkBlue btn-sm py-2 px-3'>Book first session</Link>
                </Col>
            </Row>
        </Container>
    )
}

export default UserInfo