import React from "react";

function ReadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      fill="none"
      viewBox="0 0 18 12"
    >
      <path
        stroke="#8ACD85"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M2 6l3 3 6-6"
      ></path>
      <path
        fill="#8ACD85"
        d="M10 9l-.53.53.53.53.53-.53L10 9zm6.53-5.47a.75.75 0 00-1.06-1.06l1.06 1.06zm-8.06 5l1 1 1.06-1.06-1-1-1.06 1.06zm2.06 1l6-6-1.06-1.06-6 6 1.06 1.06z"
      ></path>
    </svg>
  );
}

export default ReadIcon;