export type arrayInterface = {
  header: string;
  img: string;
  content: string;
  background?: string;
  name?: string;
  company?: string;
  role?: string;
};

export type sessionInterface = {
  header: string;
  capacity: string;
  date: string;
  time?: string;
  name?: string;
  company?: string;
  role?: string;
};

export const cardInfo: Array<arrayInterface> = [
  {
    header: "Surveys",
    img: "1",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
  {
    header: "Time Tracking. Absence and Leave",
    img: "2",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
  {
    header: "Attendance Management",
    img: "3",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
  {
    header: "Careers Module",
    img: "4",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
  {
    header: "Surveys",
    img: "1",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
  {
    header: "Time Tracking. Absence and Leave",
    img: "2",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
  {
    header: "Attendance Management",
    img: "3",
    content: "Portfolio review, career advice, interview tips",
    name: "Benita",
    company: "facebook",
    role: "Lead designers",
  },
];

export let sessionInfo: Array<sessionInterface> = [
  {
    header: "Storytelling for product designers",
    capacity: "Full",
    date: "Tue, Sep 21, 2001",
    time: "Portfolio review, career advice, interview tips",
  },
  {
    header: "Project Manager at Shapers.",
    capacity: "Upcoming",
    date: "Tue, Sep 21, 2001",
    time: "8:00pm (GMt +1)",
  },
  {
    header: "Project Manager at Shapers.",
    capacity: "Upcoming",
    date: "Tue, Sep 21, 2001",
    time: "8:00pm (GMt +1)",
  },
  {
    header: "Storytelling for product designers",
    capacity: "Full",
    date: "Tue, Sep 21, 2001",
    time: "Portfolio review, career advice, interview tips",
  },
  {
    header: "Storytelling for product designers",
    capacity: "Full",
    date: "Tue, Sep 21, 2001",
    time: "Portfolio review, career advice, interview tips",
  },
  {
    header: "Project Manager at Shapers.",
    capacity: "Upcoming",
    date: "Tue, Sep 21, 2001",
    time: "8:00pm (GMt +1)",
  },
];

export const timeList = [
  { time: "12:00 AM", value: "0.0" },
  { time: "12:30 AM", value: "0.5" },
  { time: "01:00 AM", value: "1.0" },
  { time: "01:30 AM", value: "1.5" },
  { time: "02:00 AM", value: "2.0" },
  { time: "02:30 AM", value: "2.5" },
  { time: "03:00 AM", value: "3.0" },
  { time: "03:30 AM", value: "3.5" },
  { time: "04:00 AM", value: "4.0" },
  { time: "04:30 AM", value: "4.5" },
  { time: "05:00 AM", value: "5.0" },
  { time: "05:30 AM", value: "5.5" },
  { time: "06:00 AM", value: "6.0" },
  { time: "06:30 AM", value: "6.5" },
  { time: "07:00 AM", value: "7.0" },
  { time: "07:30 AM", value: "7.5" },
  { time: "08:00 AM", value: "8.0" },
  { time: "08:30 AM", value: "8.5" },
  { time: "09:00 AM", value: "9.0" },
  { time: "09:30 AM", value: "9.5" },
  { time: "10:00 AM", value: "10.0" },
  { time: "10:30 AM", value: "10.5" },
  { time: "11:00 AM", value: "11.0" },
  { time: "11:30 AM", value: "11.5" },
  { time: "12:00 PM", value: "12.0" },
  { time: "12:30 PM", value: "12.5" },
  { time: "01:00 PM", value: "13.0" },
  { time: "01:30 PM", value: "13.5" },
  { time: "02:00 PM", value: "14.0" },
  { time: "02:30 PM", value: "14.5" },
  { time: "03:00 PM", value: "15.0" },
  { time: "03:30 PM", value: "15.5" },
  { time: "04:00 PM", value: "16.0" },
  { time: "04:30 PM", value: "16.5" },
  { time: "05:00 PM", value: "17.0" },
  { time: "05:30 PM", value: "17.5" },
  { time: "06:00 PM", value: "18.0" },
  { time: "06:30 PM", value: "18.5" },
  { time: "07:00 PM", value: "19.0" },
  { time: "07:30 PM", value: "19.5" },
  { time: "08:00 PM", value: "20.0" },
  { time: "08:30 PM", value: "20.5" },
  { time: "09:00 PM", value: "21.0" },
  { time: "09:30 PM", value: "21.5" },
  { time: "10:00 PM", value: "22.0" },
  { time: "10:30 PM", value: "22.5" },
  { time: "11:00 PM", value: "23.0" },
  { time: "11:30 PM", value: "23.5" },
];

export const INCOMPLETE_PROFILE_MESSAGE = "Incomplete profile.";
export const timeLisst = [
  { time: "12:00 AM", value: "0.0" },
  { time: "12:30 AM", value: "0.5" },
  { time: "01:00 AM", value: "1.0" },
  { time: "01:30 AM", value: "1.5" },
  { time: "02:00 AM", value: "2.0" },
  { time: "02:00 PM", value: "14.0" },
  { time: "02:30 PM", value: "14.5" },
  { time: "03:00 PM", value: "15.0" },
  { time: "03:30 PM", value: "15.5" },
  { time: "04:00 PM", value: "16.0" },
  { time: "04:30 PM", value: "16.5" },
  { time: "05:00 PM", value: "17.0" },
  { time: "05:30 PM", value: "17.5" },
  { time: "06:00 PM", value: "18.0" },
  { time: "06:30 PM", value: "18.5" },
  { time: "07:00 PM", value: "19.0" },
  { time: "07:30 PM", value: "19.5" },
  { time: "08:00 PM", value: "20.0" },
  { time: "08:30 PM", value: "20.5" },
  { time: "09:00 PM", value: "21.0" },
  { time: "09:30 PM", value: "21.5" },
  { time: "10:00 PM", value: "22.0" },
  { time: "10:30 PM", value: "22.5" },
  { time: "11:00 PM", value: "23.0" },
  { time: "11:30 PM", value: "23.5" },
];