import React from 'react';
import ExpertsSession from './Components/ExpertsSession';
import GroupSession from './Components/GroupSession';
import UserInfo from './Components/UserInfo';

const Dashboard = () => {
  return (
    <>
      <UserInfo />
      <GroupSession />
      <ExpertsSession />
    </>
  );
};

export default Dashboard;
