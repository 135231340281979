import React from 'react';
import style from './index.module.css';
import Logo from 'components/common/Logo';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { login } from '../../../services/authService';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useReducer } from 'react';
import Input from '../../../components/common/Input/Index';
import UserType from '../../UserType/Index';
import { useEffect } from 'react';
import Verification from '../Verify_Otp/Index';
import Loader from '../../../components/Spinner/Index';
import { toast } from 'react-toastify';
type dataValues = {
  email: string;
  password: string;
};

export const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email address'),
  password: Joi.string().min(8).required().label('Password')
});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'password-visible':
      return { type: 'password-visible', visble: !state.visible };
    case 'password-hidden':
      return { type: 'password-hidden', visible: false };
    default:
      return state;
  }
};

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors }
  } = useForm<dataValues>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: joiResolver(schema)
  });

  const [statusCode, setStatusCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetForm = () => {
    resetField('email');
    resetField('password');
  };

  const submitHandler = (data: dataValues) => {
    setIsLoading(true);
    login({ username: data.email, password: data.password })
      .then((res: any) => {
        const {
          data: { data }
        } = res;
        setIsLoading(false);
        const role = data.userRoles;
        if (!role?.includes('protegee')) {
          window.location.href = `${process.env.REACT_APP_EXPERT_APP_URL}/auth/handover?code=${data.authToken}&id=${data.id}`;
          return;
        }
        window.location.href = '/overview';
        localStorage.setItem('authToken', data.authToken);
        resetForm();
      })
      .catch((err) => {
        setStatusCode(err?.response?.status.toString());
        if (err?.response?.status === 403) {
          const token = err?.response?.data?.data?.authToken;
          localStorage.setItem('authToken', token);
        } else {
          toast.error(err?.response?.data?.message);
        }
        setIsLoading(false);
      });
  };
  const [state, dispatch] = useReducer(reducer, {
    type: 'password-hidden',
    visible: false
  });
  return (
    <>
      {statusCode === '403' ? (
        <Verification />
      ) : (
        <div className={style.content_wrapper}>
          <div className={style.login_wrapper}>
            <h1 className={style.header}>Sign In</h1>
            <p className={style.user_exist}>
              Don't have an account yet?
              <Link to='/auth/signup'>Sign up</Link>
            </p>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className={style.input_group}>
                <Input
                  {...register('email')}
                  placeholder='Enter your Email address'
                  type='text'
                  labeltext='Email address'
                />
                <small className={style.validation_message}>
                  {errors?.email?.message}
                </small>
              </div>
              <div className={style.input_group}>
                <Input
                  {...register('password')}
                  placeholder='Enter your password'
                  type={state.type}
                  labeltext='password'
                  dispatch={dispatch}
                />
                <small className={style.validation_message}>
                  {errors?.password?.message}
                </small>
              </div>
              <div className={style.login_btn}>
                <button>{isLoading ? <Loader /> : 'Login'}</button>
              </div>
            </form>
            <Link className={style.forgot_link} to='/auth/forgot-password'>
              Forgot Password?
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

const Index = () => {
  const [userType, setUserType] = useState('');
  const [component, setComponent] = useState('');
  useEffect(() => {
    setUserType('');
    setComponent('');
  }, []);

  return (
    <>
      <div className={style.container}>
        <div className={style.logo_wrapper}>
          <Logo />
        </div>
        {component === '' && (
          <UserType
            setUserType={setUserType}
            setComponent={setComponent}
            userType={userType}
          />
        )}
        {component === 'learner' && <LoginForm />}
      </div>
    </>
  );
};

export default Index;
