import { arrayInterface } from 'Helpers/constants';
import { handleTextLength } from 'Helpers/utility';
import React, { useState } from 'react';
// import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
// import { async_status } from "redux/Actions/types";
// import { RootState } from "redux/Reducers";
// import { addUserWishlist } from "services/userService";
import { useEffect } from 'react';
type PropsType = {
  cardInfo: arrayInterface[];
  settings: any;
};

export const ExpertCard: React.FC<any> = ({
  data,
  index,
  allLiked,
  likeCallBack
}) => {
  const navigate = useNavigate();

  // const { user } = useSelector((state: RootState) => state);
  const [expertInfo, setExpertInfo] = useState({
    agreementPage: {
      agreeToTerms: false,
      consultationOutline: '',
      expertiseCommitment: ''
    },
    availabilityPage: {
      alwaysAvailable: false,
      endDate: '',
      hourlyRate: 0,
      startDate: '',
      timeZone: ''
    },
    professionalInformationPage: {
      cityName: '',
      coachDomains: [],
      companyName: '',
      countryId: 0,
      industryName: '',
      jobTitle: '',
      linkedInProfileUrl: '',
      pageIndex: 0,
      professionalSummary: ''
    },
    profileMediaPage: {
      pageIndex: 0,
      profilePicture: '',
      profileVideoAttachedLink: '',
      profileVideoWrittenLink: ''
    },
    registrationPage: {
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
      phoneNumber: '',
      userName: ''
    },
    userId: {}
  });
  useEffect(() => {
    setExpertInfo((prev) => {
      return {
        ...prev,
        ...data
      };
    });
  }, [data]);
  // const [loading, setLoading] = useState(false);

  // const loggedInUser = user.status === async_status.__LOADED__;

  // const addToWishlist = useMemo(
  //   () => async () => {
  //     setLoading(true);
  //     try {
  //       await addUserWishlist({
  //         expertId: data.userId,
  //       });
  //       if (likeCallBack) {
  //         likeCallBack();
  //       }
  //     } catch (error) {
  //       apiErrorHandler(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [data.userId, likeCallBack]
  // );
  // const location = useLocation();
  const ExpertCardHandler = () => {
    navigate(`/expert/${data.userId}`);

    // const authToken = localStorage.getItem('authToken');
    // if (!authToken) {
    //   setTimeout(() => {
    //     localStorage.setItem('expertUrl', `/expert/${data.userId}`);
    //     navigate('/auth/login');
    //   }, 1000);
    // } else {
    //   navigate(`/expert/${data.userId}`);
    // }
  };
  return (
    <div
      key={data.userId}
      className={`inner mr-md-3 m-1 cursor-pointer`}
      style={{
        backgroundSize: 'cover',
        backgroundImage: `url(${`${expertInfo.profileMediaPage?.profilePicture}`}`
      }}

      // ||
      // `/backgrounds/Pages/Landing/dummy-image.png`
    >
      <div className='h-100  text-white'>
        {/* {loggedInUser && <div className="px-4 py-2 img-div h-25 mt-3 text-right">
                    {loading ? <Spinner><></></Spinner> : <img src={require(`assets/images/svg/icons/${index === -2  || allLiked ? 'ic-love-checked' : 'ic-love'}.svg`)} className="mt-3" alt="like" onClick={addToWishlist} />}
                </div>} */}
        {/* () => navigate(`/expert/${data.userId}`) */}
        <div className='px-3 py-4 lower-half' onClick={ExpertCardHandler}>
          <div className='mt-5 mb-2'>
            <h5 className='mb-0 text-white'>
              {' '}
              {handleTextLength(
                `${
                  expertInfo.registrationPage?.firstName ||
                  expertInfo.registrationPage?.firstName
                } ${
                  expertInfo?.registrationPage?.lastName ||
                  expertInfo.registrationPage?.lastName
                }`,
                20
              )}
            </h5>
            <p className='m-0'>
              {' '}
              {expertInfo.professionalInformationPage?.companyName &&
                handleTextLength(
                  expertInfo?.professionalInformationPage?.companyName,
                  30
                )}
            </p>
          </div>
          <div className='mt-4'>
            <p className='m-0 d-flex text-capitalize align-items-start'>
              <img
                src={require(`assets/images/svg/icons/briefcase.svg`).default}
                className='mr-2'
                alt=''
              />

              {expertInfo?.professionalInformationPage?.jobTitle
                ? handleTextLength(
                    expertInfo?.professionalInformationPage?.jobTitle,
                    30
                  )
                : 'N/A'}
            </p>
            <p className='m-0 mt-2 d-flex align-items-start'>
              <img
                src={
                  require(`assets/images/svg/icons/message-circle.svg`).default
                }
                className='mr-2'
                alt=''
              />
              {expertInfo?.professionalInformationPage?.professionalSummary
                ? handleTextLength(
                    expertInfo?.professionalInformationPage
                      ?.professionalSummary,
                    30
                  )
                : 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExpertCards: React.FC<PropsType> = ({ cardInfo, settings }) => {
  return (
    <div className='expert-group-cards'>
      <Slider {...settings}>
        {cardInfo?.map((info, i) => (
          <div key={i} className='mt-3'>
            <ExpertCard data={info} index={i} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ExpertCards;
