import React from 'react';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
import { Card, CardBody, Col, Row } from 'reactstrap';

interface arrayInterface {
  text: string;
}
const ContinentComponent = () => {
  var settings = {
    draggable: true,
    dots: true,
    autoplay: false /* this is the new line */,
    autoplaySpeed: 500,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      // {
      //     breakpoint: 900,
      //     settings: {
      //         slidesToShow: 3,
      //         slidesToScroll: 1,
      //         initialSlide: 2
      //     }
      // },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  let cardInfo: Array<arrayInterface> = [
    {
      text: '“Velit officia consequat duis enim velit mollit. Exercitation veniam consequat. Amet minim mollit non deserunt ullamco.'
    },
    {
      text: '“Velit officia consequat duis enim velit mollit. Exercitation veniam consequat. Amet minim mollit non deserunt ullamco.'
    },
    {
      text: '“Velit officia consequat duis enim velit mollit. Exercitation veniam consequat. Amet minim mollit non deserunt ullamco.'
    },
    {
      text: '“Velit officia consequat duis enim velit mollit. Exercitation veniam consequat. Amet minim mollit non deserunt ullamco.'
    },
    {
      text: '“Velit officia consequat duis enim velit mollit. Exercitation veniam consequat. Amet minim mollit non deserunt ullamco.'
    }
  ];

  return (
    <div className='lower-slider container-fluid'>
      <Row className='px-md-5 px-0'>
        <Col>
          <div className='p-md-5 p-2'>
            {/* <div className='my-5'>
                            <h1 className='landing-mid-header'>Leaps around the <br /> world</h1>
                            <Slider  {...settings}>
                                {cardInfo.map((info, i) => (
                                    <div key={i}>
                                        <Card className={`inner mr-md-5 mt-md-5 m-0`} >
                                            <CardBody className='h-100'>

                                                <div className='px-3 py-4 lower-half'>
                                                    <div className='mb-2'>
                                                        <h5 className='mb-0'> {info.text}</h5>
                                                    </div>
                                                    <div className='bottom mt-4 d-block d-sm-flex p-3 align-items-center'>
                                                        <div className='mr-3'>
                                                            <img src={require(`assets/images/svg/dummy-image-theresa.svg`).default} className="mr-2" alt="" />

                                                        </div>
                                                        <div>
                                                            <p className='m-0 small font-weight-bold'>
                                                                Theresa Webb
                                                            </p>
                                                            <p className='m-0 small text-muted'>
                                                                UI Designer, Adobe
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                            </Slider>
                        </div> */}
            <Row className='py-5 my-5'>
              <Col sm='12'>
                <Card className='floating-card text-center text-md-left'>
                  <CardBody className='container py-5'>
                    <Row>
                      <Col md='8'>
                        <h3 className='text-darkBlue'>
                          Learn Tech. Build Products. Conduct Research.
                          Together.
                        </h3>
                        <p className='text-muted '>
                          Building community is one, creating wealth is another.
                          These two must exist together. Coachmie is how!
                        </p>
                      </Col>
                      <Col md='4' className='text-center text-md-right'>
                        <Link
                          to='/auth/signup'
                          className='btn btn-warning text-grayScale btn-lg'>
                          Sign Up
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContinentComponent;
