import { sessionInterface } from 'Helpers/constants';
import React from 'react';
import Slider from 'react-slick';
import { Card, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { handleTextLength } from 'Helpers/utility';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducers';
import { async_status } from 'redux/Actions/types';

type PropsType = {
  sessionInfo: sessionInterface[];
  settings: any;
};

export const GroupCard: React.FC<any> = ({ data, index }) => {
  const { user } = useSelector((state: RootState) => state);

  const loggedInUser = user.status === async_status.__LOADED__;

  const getStatus = () => {
    if (
      moment()
        .local()
        .isBetween(moment(data.fromDate).local(), moment(data.toDate).local())
    ) {
      return 'Ongoing';
    } else if (data.seats === data.reserved) {
      return 'Full';
    } else {
      return 'Upcoming';
    }
  };
  return (
    <Card className={`session-card mr-md-3  m-1`}>
      <div className='h-100'>
        <div className='px-3 py-4'>
          <div className='mt-2 mb-2'>
            <span
              className={`mb-0 py-2 px-3 status ${
                data.seats === data.reserved ? 'bg-full' : 'bg-pending'
              }`}>
              {' '}
              {getStatus()}
            </span>
          </div>
          <div className='mt-3 mb-2'>
            <h4 className='mb-0'>
              <Link
                id={`titleToolTip${index}`}
                to={`/${loggedInUser ? 'group-sessions' : 'sessions'}/${
                  data.sessionId
                }`}
                className='text-dark'>
                {' '}
                {handleTextLength(data.title, 16)}
              </Link>
            </h4>
            <UncontrolledTooltip
              placement='bottom'
              target={`titleToolTip${index}`}>
              {data.title}
            </UncontrolledTooltip>
          </div>
          <div className='mt-3'>
            <p className='my-1 d-flex align-items-start'>
              <img
                src={require(`assets/images/svg/icons/calendar.svg`).default}
                className='mr-2'
                alt=''
              />

              {moment(data.fromDate).local().format('MMMM Do, YYYY')}
            </p>
            <p className='my-1 d-flex align-items-start'>
              <img
                src={require(`assets/images/svg/icons/clock.svg`).default}
                className='mr-2'
                alt=''
              />
              {moment(data.fromDate).local().format('h:mma')}
            </p>
            <span className='status bg-full py-2 px-3 mt-2 mb-1 d-inline-block'>
              {data.fee === '0' ? 'Free Session' : 'Paid Session'}
            </span>
          </div>
          <div className='border-top mt-4 d-flex pt-3'>
            <div>
              <img
                src={
                  data?.expert?.profilePages?.profileMediaPage
                    ?.profilePicture ||
                  require(`assets/images/svg/dummy-image.svg`).default
                }
                className='mr-2 review-img'
                alt=''
              />
            </div>
            <div>
              <p className='m-0 small'>
                <b className='text-dark'>Hosted by </b>
                <span className='text-capitalize'>
                  {data?.expert?.profilePages?.registrationPage?.firstname ||
                    data?.expert?.profilePages?.registrationPage
                      ?.firstName}{' '}
                  {data?.expert?.profilePages?.registrationPage?.lastname ||
                    data?.expert?.profilePages?.registrationPage?.lastName}
                </span>
              </p>
              {data?.expert?.profilePages?.professionalInformationPage
                ?.jobTitle &&
                data?.expert?.profilePages?.professionalInformationPage
                  ?.companyName && (
                  <p className='m-0 small mt-2'>
                    {
                      data?.expert?.profilePages?.professionalInformationPage
                        ?.jobTitle
                    }{' '}
                    at{' '}
                    {
                      data?.expert?.profilePages?.professionalInformationPage
                        ?.companyName
                    }
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const GroupCards: React.FC<PropsType> = ({ sessionInfo, settings }) => {
  return (
    <div
      className={`expert-group-cards ${
        sessionInfo.length < 3 ? 'full-track' : ''
      }`}>
      <Slider {...settings}>
        {sessionInfo.map((info, i) => (
          <div key={i}>
            <GroupCard data={info} index={i} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default GroupCards;
