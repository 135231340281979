import FormsWrapper, { showFieldError } from 'components/Forms/Formik';
import React from 'react';
import * as Yup from 'yup';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import Button from 'components/common/Button';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducers';
import { DomainsComponent } from 'components/common/Domains';
interface formInterface {
  formControl: any;
  onSubmit?: any;
  onChange?: any;
  formName: any;
  domains?: any[] | undefined;
}

const FormEdit: React.FC<formInterface | any> = React.memo((props) => {
  let {
    formControl,
    onChange,
    onSubmit,
    formName,
    loadingDomains,
    topDomains,
    userDomains,
    topDomainClick,
    onRemoveDomain,
    onDomainSubmit,
    formProfessional
  } = props;

  let validation: Record<any, any> = React.useMemo(() => {
    let obj: Record<any, any> = {};

    // if (!formControl.about || formControl.about === "") {
    //     obj.about = Yup.string().required("Required").nullable();
    // }
    // if (!formControl.company || formControl.company === "") {
    //     obj.company = Yup.string().required("Required").nullable();
    // }
    if (!formControl.firstname || formControl.firstname === '') {
      obj.firstname = Yup.string().required('Required').nullable();
    }
    if (!formControl.lastname || formControl.lastname === '') {
      obj.lastname = Yup.string().required('Required').nullable();
    }
    if (formControl.country === '') {
      obj.country = Yup.string().required('Required');
    }
    if (formControl.timezone === '') {
      obj.timezone = Yup.string().required('Required');
    }
    // if (!formControl.location || formControl.location === "") {
    //     obj.location = Yup.string()
    //         .required("Required");
    // }

    // if (!formControl.jobTitle || formControl.jobTitle === "") {
    //     obj.jobTitle = Yup.string()
    //         .required("Required").nullable();
    // }
    // if (!formControl.industry || formControl.industry === "") {
    //     obj.industry = Yup.string()
    //         .required('Required').nullable()
    // }
    return Yup.object().shape(obj);
  }, [
    formControl.lastname,
    formControl.firstname,
    formControl.country,
    formControl.timezone
  ]);
  const {
    countries: { data: countriesData }
  } = useSelector((state: RootState) => {
    return state;
  });
  return (
    <FormsWrapper
      values={formControl}
      handleSubmit={onSubmit}
      handleChange={onChange}
      validationSchema={validation}>
      {(props: {
        values: any;
        isValid: any;
        touched: any;
        errors: any;
        handleBlur: any;
        handleChange: any;
        handleSubmit: any;
        isSubmitting: any;
      }) => {
        const {
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting
        } = props;
        return (
          <>
            <form name={formName} onChange={onChange} onSubmit={handleSubmit}>
              <Container>
                <Row>
                  <Col md='6'>
                    <Label className='small text-muted mb-0 mt-3'>
                      Firstname
                    </Label>
                    <Input
                      type='text'
                      name='firstname'
                      onBlur={handleBlur}
                      placeholder='Firstname'
                      value={formControl.firstname}
                      invalid={touched.firstname && errors.firstname}
                      onChange={handleChange}
                    />
                    {showFieldError('firstname', errors, touched)}
                  </Col>
                  <Col md='6'>
                    <Label className='small text-muted mb-0 mt-3'>
                      Lastname
                    </Label>
                    <Input
                      type='text'
                      name='lastname'
                      onBlur={handleBlur}
                      placeholder='Lastname'
                      value={formControl.lastname}
                      invalid={touched.lastname && errors.lastname}
                      onChange={handleChange}
                    />
                    {showFieldError('lastname', errors, touched)}
                  </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <Label className='small text-muted mb-0 mt-3'>
                      Email Address
                    </Label>
                    <Input
                      type='email'
                      name='email'
                      onBlur={handleBlur}
                      value={formControl.username}
                      disabled
                    />
                    {showFieldError('email', errors, touched)}
                  </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <Label className='small text-muted mb-0 mt-3'>
                      Country
                    </Label>
                    <Input
                      type='select'
                      name='country'
                      className='form-control'
                      onBlur={handleBlur}
                      value={formControl.country}
                      invalid={errors.country && touched.country}
                      onChange={handleChange}>
                      <option>Select Country</option>
                      {countriesData.map((country: Record<any, any>) => (
                        <option key={country.id} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Input>
                    {showFieldError('country', errors, touched)}
                  </Col>
                </Row>

                {/* <Row>
                  <Col md="12">
                    <Label className="small text-muted mb-0 mt-3">
                      Time Zone
                    </Label>
                    <Input
                      type="select"
                      name="timezone"
                      className="form-control"
                      onBlur={handleBlur}
                      value={formControl.timezone}
                      invalid={errors.country && touched.country}
                      onChange={handleChange}
                    >
                      <option>Select Time Zone</option>
                      {timezones.map((timezone: any, id: any) => (
                        <option key={id} value={timezone}>
                          {timezone}
                        </option>
                      ))}
                    </Input>
                    {showFieldError("country", errors, touched)}
                  </Col>
                </Row> */}
                {/* <Row>
                                    <Col md="12">
                                        <Label className="small text-muted mb-0 mt-3">Job Title</Label>
                                        <Input
                                            type="text"
                                            name="jobTitle"
                                            onBlur={handleBlur}
                                            placeholder="Job Title"
                                            value={formControl.jobTitle}
                                            invalid={touched.jobTitle && errors.jobTitle}
                                            onChange={handleChange} />
                                        {showFieldError("jobTitle", errors, touched)}

                                    </Col>
                                </Row> */}
                {/* <Row>
                                    <Col md="12">
                                        <Label className="small text-muted mb-0 mt-3">Company</Label>
                                        <Input
                                            type="text"
                                            name="company"
                                            onBlur={handleBlur}
                                            placeholder="Company"
                                            value={formControl.company}
                                            invalid={touched.company && errors.company}
                                            onChange={handleChange} />
                                        {showFieldError("company", errors, touched)}

                                    </Col>
                                </Row> */}
                {/* <Row>
                                    <Col md="12">
                                        <Label className="small text-muted mb-0 mt-3">Industry</Label>
                                            <Input
                                        type="select"
                                        name="industry"
                                        className='form-control'
                                        onBlur={handleBlur}
                                        value={formControl.industry}
                                        invalid={touched.industry && errors.industry}
                                        onChange={handleChange} >
                                        <option>Select Industry</option>
                                        {domains?.map((domain) => (
                                            <option key={domain.id} value={domain.name}>{domain.name}</option>
                                        ))}
                                    </Input>
                                        {showFieldError("industry", errors, touched)}

                                    </Col>
                                </Row> */}
                {/* <Row>
                                    <Col md="12">
                                        <Label className="small text-muted mb-0 mt-3">Professional Summary</Label>
                                        <Input
                                            type="textarea"
                                            rows="5"
                                            name="about"
                                            onBlur={handleBlur}
                                            value={formControl.about}
                                            invalid={errors.about && touched.about}
                                            placeholder="Tell us about yourself, your interest and what you love"
                                            onChange={handleChange} />
                                        {showFieldError("about", errors, touched)}
                                    </Col>
                                </Row> */}
                {/* <Row>
                                    <Col md="12">
                                        <Label className="small text-muted mb-0 mt-3">Location</Label>
                                        <Input
                                            type="text"
                                            name="location"
                                            onBlur={handleBlur}
                                            placeholder="Location"
                                            value={formControl.location}
                                            invalid={touched.location && errors.location}
                                            onChange={handleChange} />
                                        {showFieldError("location", errors, touched)}

                                    </Col>
                                </Row> */}
              </Container>
            </form>
            <Container>
              <p className='text-muted  mt-4 mb-n2'>Leaning interests</p>
              <DomainsComponent
                loading={loadingDomains}
                topDomains={topDomains}
                userDomains={userDomains}
                topDomainClick={topDomainClick}
                onRemoveDomain={onRemoveDomain}
                handleChange={onChange}
                onFormSubmit={onDomainSubmit}
                formControl={formProfessional}
              />
              <div className='d-flex my-4'>
                <Button
                  onClick={handleSubmit}
                  className='ml-auto px-5'
                  loading={isSubmitting}
                  type='submit'
                  color='darkBlue'
                  text='Save'
                />
              </div>
            </Container>
          </>
        );
      }}
    </FormsWrapper>
  );
});

export default FormEdit;
