import React from "react";
import { Modal, ModalBody } from "reactstrap";

const CustomModal: React.FC<any> = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      className={`modal-dialog-centered custom-modal ${props.classProp}`}
      {...props}
    >
      {!props.hideHeader && (
        <div className="modal-header d-flex align-items-center px-3">
          <h5 className="text-center w-100 mb-0">{props.header}</h5>
          <div className="ml-auto">
            <button
              type="button"
              className="close-btn"
              onClick={props.toggle}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      )}
      <ModalBody>{props.children}</ModalBody>
    </Modal>
  );
};

export default CustomModal;
