import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import Button from 'components/common/Button';
import { RootState } from 'redux/Reducers';
import { apiErrorHandler } from 'Helpers/utility';
import { useSelector } from 'react-redux';
import { createSessionPayment } from 'services/sessionService';
import { ApiKeysContext } from 'Contexts/ApiKeysContext';
import { async_status } from 'redux/Actions/types';
import { handleLogout } from 'services/authService';
import { DateTime } from 'luxon';
import './PaymentSelector.css';

import paymentMethods from './payment_data.json';
export const PaymentSelector: React.FC<any> = (props) => {
  const {
    keys: { data }
  } = useContext(ApiKeysContext);
  const payment: {
    [key: string]: {
      id: string;
      value: string;
      text: string;
      country: string;
      paymentType: string;
      disable?: boolean;
    };
  } = paymentMethods;
  const [country, setCountry] = useState<any>({
    Canada: {
      paymentType: '',
      currency: ''
    },
    Usa: {
      paymentType: '',
      currency: ''
    },
    Nigeria: {
      paymentType: '',
      currency: ''
    },
    Uk: {
      paymentType: '',
      currency: ''
    }
  });
  const { accountInfo } = props;
  const isAccountLinked = accountInfo?.isAccountLinked === true;
  const expertAccCurrency = accountInfo.currency;
  const { user } = useSelector((state: RootState) => state);
  const loggedInUser = user.status === async_status.__LOADED__;

  const [name, setName] = useState('');
  const [proceed, setProceed] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [methods, setpaymentMethods] = useState<any>([]);

  let exchangeRate;
  if (expertAccCurrency === 'NGN') {
    exchangeRate = data.cadRate;
  } else if (expertAccCurrency === 'usd') {
    exchangeRate = data.usdRate;
  } else if (expertAccCurrency === 'cad') {
    exchangeRate = data.cadRate;
  } else if (expertAccCurrency === 'gbp') {
    exchangeRate = data.gbpRate;
  }
  const paymentMethodHandler = (e: any) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedCountry = selectedOption.dataset.country;
    const paymentType = selectedOption.dataset.payment;
    setName(selectedCountry);
    setSelectedValue(e.target.value);
    if (e.target.value === 'cad' && expertAccCurrency === 'NGN') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType: 'stripe'
        }
      });
    } else if (e.target.value === 'usd' && expertAccCurrency === 'NGN') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType: 'stripe'
        }
      });
    } else if (e.target.value === 'gbp' && expertAccCurrency === 'NGN') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType: 'stripe'
        }
      });
    } else if (e.target.value === 'gbp' && expertAccCurrency === 'cad') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
      props.setForeignBuffer(+data.bufferValue);
    } else if (e.target.value === 'usd' && expertAccCurrency === 'cad') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
      props.setForeignBuffer(+data.bufferValue);
    } else if (e.target.value === 'usd' && expertAccCurrency === 'gbp') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
      props.setForeignBuffer(+data.bufferValue);
    } else if (e.target.value === 'cad' && expertAccCurrency === 'gbp') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
      props.setForeignBuffer(+data.bufferValue);
    } else if (e.target.value === 'cad' && expertAccCurrency === 'usd') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
      props.setForeignBuffer(+data.bufferValue);
    } else if (e.target.value === 'gbp' && expertAccCurrency === 'usd') {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
      props.setForeignBuffer(+data.bufferValue);
    } else {
      setCountry({
        [selectedCountry]: {
          currency: e.target.value,
          paymentType
        }
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    const payment_methods = Object.keys(paymentMethods).map(
      (el: string) => payment[el]
    );
    let data = {};
    const filtered_methods = payment_methods.map((el) => {
      if (el.value === '') {
        data = {
          id: el.id,
          value: el.value,
          country: el.country,
          text: el.text,
          paymentType: el.paymentType,
          disable: el.disable
        };
      } else if (el.value === 'NGN') {
        data = {
          id: el.id,
          value: el.value,
          country: el.country,
          text: el.text,
          paymentType: el.paymentType,
          disable: false
        };
      } else if (el.value === 'usd') {
        data = {
          id: el.id,
          value: el.value,
          country: el.country,
          text: el.text,
          paymentType: el.paymentType,
          disable: el.value === accountInfo.currency ? false : true
        };
      } else if (el.value === 'cad') {
        data = {
          id: el.id,
          value: el.value,
          country: el.country,
          text: el.text,
          paymentType: el.paymentType,
          disable: el.value === accountInfo.currency ? false : true
        };
      } else if (el.value === 'gbp') {
        data = {
          id: el.id,
          value: el.value,
          country: el.country,
          text: el.text,
          paymentType: el.paymentType,
          disable: el.value === accountInfo.currency ? false : true
        };
      }
      return data;
    });
    if (mounted) {
      setpaymentMethods(filtered_methods);
    }
    return () => {
      mounted = false;
    };
  }, [accountInfo.currency, payment]);

  const handleProceed = async () => {
    if (!loggedInUser) {
      handleLogout(`/auth/login?callback=${window.location.pathname}`);
    }
    setProceed(true);
    if (props.handleProceed) {
      props.handleProceed(
        country[name].paymentType,
        country[name].currency,
        name
      );
    }
    if (props.setStep) {
      props.setStep(2);
    }
    // }
    if (country[name].paymentType === 'flutterwave') {
      try {
        const DTO = {
          ...props.submitData,
          paymentType: country[name].paymentType,
          currency: country[name].currency,
          type: props.sessionType,
          callbackUrl: `${window.location.origin}${window.location.pathname}`
        };
        let { data } = await createSessionPayment(DTO);
        return window.open(data?.data?.link, '_self');
      } catch (error) {
        apiErrorHandler(error);
        setProceed(false);
      }
    } else if (country[name].paymentType === 'stripe') {
      props.setPaymentType(country);
      props.setCountryName(name);
    }
  };
  if (proceed && country[name].paymentType === 'flutterwave') {
    return (
      <div className='text-center'>
        <p>Please wait while we redirect you to the payment page</p>
        <Spinner color='yellow'>
          <></>
        </Spinner>
      </div>
    );
  }
  return (
    <div>
      {isAccountLinked ? (
        <select
          className='select_component'
          onChange={paymentMethodHandler}
          value={selectedValue}>
          {methods.map(
            (item: {
              id: string;
              value: string;
              country: string;
              disable: boolean;
              paymentType: string;
              text: string;
            }) => (
              <option
                className='option'
                key={item.id}
                value={item.value}
                data-country={item.country}
                data-payment={item.paymentType}>
                {item.text}
              </option>
            )
          )}
        </select>
      ) : (
        <p> No Account Linked</p>
      )}
      <div className='text-right'>
        <small className={`d-block my-${name === 'Nigeria' ? '1' : '4'}`}>
          {country[name]?.currency === 'NGN' && expertAccCurrency !== 'NGN' ? (
            <i>
              Pay N{Number(props.amount) * exchangeRate} at rate N{exchangeRate}{' '}
              to $1{' '}
            </i>
          ) : null}
        </small>
      </div>
      {/* {country[name]?.currency === 'NGN' && (
        <p className='paragraph'>
          Note: Kindly make payment using your card other means of Payment is
          currently not supported
        </p>
      )} */}
      <Button
        color='primary'
        onClick={() => handleProceed()}
        disabled={name === ''}
        block
        text='proceed'
      />
    </div>
  );
};
