import Tabs from 'components/common/Tabs'
import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import New from './Components/New'
import Sent from './Components/Sent'

const MapSubComponents: React.FC<{ activeTab: string }> = ({ activeTab, ...props }) => {
    let componnents: Record<string, any> = {
        [`New`]: <New />,
        [`Sent`]: <Sent />
    }

    return componnents[activeTab]
}
const Invite = () => {
    const [state, setState] = useState<any>({
        tabs: ["New", 'Sent'],
        activeTab: "New",
    })

    const toggleTab = (param: String) => {
        setState((prev: Object) => ({
            ...prev,
            activeTab: param,
        }))
    }
    return (
        <>
        <Container>
            <Row>
                <Col md="12" className='mb-4'>
                    <Tabs className='border' tabs={state.tabs} activeTab={state.activeTab} onClickTab={toggleTab} />

                </Col>
            </Row>
        </Container>
        <MapSubComponents activeTab={state.activeTab} />
        </>
    )
}

export default Invite