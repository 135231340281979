import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducers';

const UserAvatar: React.FC<any> = (props) => {
  const {
    user: {
      data: { data: user }
    }
  } = useSelector((state: RootState) => state);
  const imgSrc =
    user.imageUrl && user.imageUrl !== ''
      ? new URL(`${process.env.REACT_APP_BASE_URL}/${user.imageUrl}`)
      : require('assets/images/backgrounds/Pages/Landing/dummy-image.png');
  return (
    <img
      className='user-avatar'
      // alt={`${user.firstname} ${user.lastname}`}
      alt=''
      src={imgSrc}
      {...props}
    />
  );
};

export default UserAvatar;
