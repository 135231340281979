import React, { useContext, useState } from 'react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { createSessionPayment } from 'services/sessionService';
import { apiErrorHandler } from 'Helpers/utility';
import Button from 'components/common/Button';
import { toast } from 'react-toastify';
import { ApiKeysContext } from 'Contexts/ApiKeysContext';

const PaymentComponent: React.FC<any> = ({
  callBackFn,
  submitData,
  intent,
  currency,
  countryName,
  paymentType,
  sessionType,
  expertAccountCurrency,
  fee
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    try {
      setLoading(true);
      let intentData = intent;
      if (!intentData) {
        let { data } = await createSessionPayment({
          ...submitData,
          type: sessionType,
          currency,
          paymentType,
          callbackUrl: `${window.location.origin}${window.location.pathname}`
        });
        intentData = data?.data?.payment_intent_client_secret;
      }
      const paymentIntent = await stripe?.confirmCardPayment(intentData, {
        payment_method: {
          card: elements.getElement(CardElement) as StripeCardElement
        }
      });
      if (paymentIntent?.error) {
        toast.error(paymentIntent.error.message);
        setLoading(false);
      }
      if (paymentIntent?.paymentIntent) {
        if (sessionType === 'group') {
          callBackFn(
            paymentType,
            paymentIntent.paymentIntent?.id,
            currency,
            countryName,
            () => setLoading(false)
          );
        } else if (sessionType === 'one-to-one') {
          callBackFn(paymentIntent?.paymentIntent?.id, () => setLoading(false));
        }
      }
    } catch (error) {
      setLoading(false);
      apiErrorHandler(error);
    }
  };
  return (
    <div id='payment-page'>
      <form id='payment-form' className='text-center' onSubmit={handleSubmit}>
        {currency !== expertAccountCurrency && (
          <p>
            This session Price includes conversion rate
            <span className='text-primary'> ({fee})</span>
          </p>
        )}

        <label htmlFor='card-element' className='d-block mt-2'>
          Card
        </label>
        <CardElement id='card-element' />
        <p className='small text-muted text-center mt-4'>
          We will send you an order details to your email <br /> after the
          successfull payment
        </p>
        <Button
          color='success'
          loading={loading ? loading : undefined}
          block
          text='Pay for Session'
        />
      </form>
    </div>
  );
};

const Payment: React.FC<any> = (props) => {
  const {
    keys: { data }
  } = useContext(ApiKeysContext);
  const stripePromise = loadStripe(data?.stripePk);
  return (
    <Elements stripe={stripePromise}>
      <PaymentComponent {...props} />
    </Elements>
  );
};

export default Payment;
