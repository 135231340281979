import React from 'react';
import style from './Index.module.css';
import { Plus } from 'react-feather';
const Index = () => {
  return (
    <div className={style.container}>
      <span>Cloud Engineering</span>
      <div className={style.icon_wrapper}>
        <Plus className={style.icon} />
      </div>
    </div>
  );
};

export default Index;
