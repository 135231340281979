import { apiErrorHandler, onChange } from 'Helpers/utility';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import CalendarIcon from './Icons/CalendarIcon';
import EditIcon from './Icons/EditIcon';
import EmailIcon from './Icons/EmailIcon';
import GlobeIcon from './Icons/GlobeIcon';
import moment from 'moment';
import FormEdit from './FormEdit';
import { addUser, editUser } from 'services/userService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from 'redux/Actions/userAction';
import { toast } from 'react-toastify';

import { RootState } from 'redux/Reducers';
import { async_status } from 'redux/Actions/types';

interface stateInterface {
  formProfessional?: any;
  formEdit?: any;
  topDomains: string[];
  userDomains: string[];
  originalDomains: string[];
  showEdit: boolean;
  showShare: boolean;
  viewStep: number;
  showDomainEdit: boolean;
}
const Details: React.FC<any> = ({ userInfo }) => {
  const dispatch = useDispatch();
  const { domains } = useSelector((state: RootState) => state);
  const [state, setState] = useState<stateInterface>({
    formProfessional: {
      domain: ''
    },
    formEdit: {
      about: '',
      company: '',
      firstname: '',
      industry: '',
      jobTitle: '',
      lastname: '',
      timezone: '',
      location: ''
    },
    topDomains: [],
    userDomains: [],
    originalDomains: [],
    showEdit: false,
    showDomainEdit: false,
    viewStep: 1,
    showShare: false
  });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (domains.subDomainStatus === async_status.__LOADED__) {
      const newDomains = domains.subDomains.map((a: any) => a.name);
      setState((prev) => ({
        ...prev,
        topDomains: newDomains
      }));
    }
  }, [domains]);

  const handleChange = (e: HTMLInputElement) => {
    onChange(e, setState);
  };

  const toggleEdit = (modalName = 'showEdit') => {
    setState((prev: any) => ({
      ...prev,
      formEdit: {
        ...state.formEdit,
        ...userInfo,
        language: userInfo.language?.split(',') || [],
        domains: state.userDomains
      },
      [modalName]: !prev[modalName]
    }));
  };

  const topDomainClick = (index: number) => {
    let selected = state.topDomains[index];
    if (!state.userDomains?.includes(selected)) {
      setState((prev: any) => {
        return {
          ...prev,
          userDomains: [...prev.userDomains, selected]
        };
      });
    }
  };

  const onFormSubmit = (e: any) => {
    // e.preventDefault()
    let selected = state.formProfessional.domain.trim();
    if (!state.userDomains?.includes(selected)) {
      setState((prev: any) => {
        return {
          ...prev,
          formProfessional: {
            ...prev.formProfessional,
            domain: ''
          },
          userDomains: [...prev.userDomains, selected]
        };
      });
    }
  };

  const onRemoveDomain = (index: number) => {
    let domains = state.userDomains;
    domains.splice(index, 1);
    setState((prev) => ({
      ...prev,
      userDomains: domains
    }));
  };

  useEffect(() => {
    const domains = userInfo.domains !== '' ? userInfo.domains?.split(',') : [];
    setState((prev) => ({
      ...prev,
      userDomains: domains,
      originalDomains: domains,
      formEdit: {
        ...prev.formEdit,
        ...userInfo,
        language: userInfo.language?.split(',') || []
      }
    }));
  }, [userInfo]);
  const handleSubmit = async () => {
    try {
      let { data } = await editUser({
        ...state.formEdit,
        domains: state.userDomains
      });
      // dispatch(fetchUser(true))
      toggleEdit();
      toast.success(data.message);
    } catch (error) {
      apiErrorHandler(error);
    }
  };

  return (
    <>
      <Container>
        <Row>
          {/* <Col md="12">
            <AlertComponent text=" Ask CDCR San Quintin State Prison 2008. We installed Purex dispensers throughout the prison to combat" />
          </Col> */}
        </Row>
        <Row>
          <Col md='8'>
            <Card>
              <CardBody>
                <FormEdit
                  formControl={state.formEdit}
                  onChange={handleChange}
                  formName={'formEdit'}
                  onSubmit={handleSubmit}
                  domains={domains.data}
                  onDomainSubmit={onFormSubmit}
                  formProfessional={state.formProfessional}
                  topDomainClick={topDomainClick}
                  onRemoveDomain={onRemoveDomain}
                  loadingDomains={domains.status === async_status.__LOADING__}
                  topDomains={state.topDomains}
                  userDomains={state.userDomains}
                />
              </CardBody>
            </Card>
            {/* <Card className='user-info'>
                            <div className='background-header'></div>
                            <CardBody className='pt-1'>
                                <div className='d-block d-md-flex'>
                                    <div className="image-div mr-3">
                                        <UserAvatar />
                                    </div>
                                    <div className="name-div d-flex pb-5 w-100">
                                        <div>
                                            <h2 className='m-0 text-capitalize'>{userInfo.firstname} {userInfo.lastname}</h2>
                                            <p className='m-0 font-weight-light'>{userInfo.jobTitle}</p>
                                        </div>
                                        <div className="ml-auto mt-1 cursor-pointer">
                                            <CTAEditIcon />
                                        </div>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                        <Card className='my-3'>
                            <CardBody>
                                <div className='d-flex'>
                                    <div>
                                        <h5>Professional summary</h5>
                                    </div>
                                    <div className="ml-auto mt-1 cursor-pointer">
                                        <CTAEditIcon />
                                    </div>
                                </div>
                                <p className='font-weight-light'>{userInfo.about}</p>
                            </CardBody>
                        </Card> */}
            {/* <Card className='mb-3'>
                            <CardBody>
                                <div className="d-flex align-items-center mb-3">
                                    <h6>Interested Domains</h6>
                                    <div className="ml-auto mt-1 cursor-pointer">
                                        <EditIcon onClick={() => toggleEdit("showDomainEdit")} />
                                    </div>
                                </div>
                                <Row className='domain-component '>
                                    {state.originalDomains.map((domain: string, i: number) => (
                                        <Col md="4" key={domain}>
                                            <div className='top-domain d-flex align-items-center p-2 mb-2'>
                                                <p className={`small mb-0`}>{domain}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card> */}
          </Col>
          <Col md='4'>
            <Card>
              <CardBody>
                <div className='d-flex border-bottom'>
                  <div>
                    <h5>Additional details</h5>
                  </div>
                  {/* <div className="ml-auto cursor-pointer">
                                        <CTAEditIcon />
                                    </div> */}
                </div>
                <div className='d-flex align-items-center mt-2'>
                  <div>
                    <EmailIcon />
                  </div>
                  <div className='ml-3'>
                    <p className='small text-muted m-0'>Email</p>
                    <a
                      href='mailto:EmailowolobiJoseph@gmail.com'
                      className='small m-0'>
                      {userInfo.username}
                    </a>
                  </div>
                </div>
                <div className='d-flex align-items-center mt-2'>
                  <div>
                    <GlobeIcon />
                  </div>
                  <div className='ml-3'>
                    <p className='small text-muted m-0'>Country</p>
                    <p className='small text-primary m-0'>{userInfo.country}</p>
                  </div>
                </div>
                {/* <div className="d-flex align-items-center mt-2">
                                    <div>
                                        <FileIcon />
                                    </div>
                                    <div className='ml-3'>
                                        <p className="small text-muted m-0">Industry</p>
                                        <p className="small text-primary m-0">{userInfo.industry}</p>
                                    </div>
                                </div> */}
                <div className='d-flex align-items-center mt-2'>
                  <div>
                    <CalendarIcon />
                  </div>
                  <div className='ml-3'>
                    <p className='small text-muted m-0'>Join Date</p>
                    <p className='small text-primary m-0'>
                      {moment(userInfo.createdAt)
                        .local()
                        .format('MMMM Do YYYY, h:mm:ss a')}
                    </p>
                    {/* <p className="small text-primary m-0">2 months ago 0n 12/15/21</p> */}
                  </div>
                </div>
              </CardBody>
              <CardFooter className='details-footer bg-primary'>
                {/* <div className="d-flex justify-content-center align-item-center cursor-pointer" id="share">
                                    <h5 className='text-white mr-2' >Share Profile</h5> <ShareIcon />
                                    <PopoverComponent isOpen={state.showShare} id="share" toggle={toggleShare}>
                                        <TwitterShareButton url={`${window.location.origin}${window.location.pathname}`} title={"Share Profile"}>
                                            <TwitterIcon />
                                        </TwitterShareButton>
                                        <WhatsappShareButton url={`${window.location.origin}${window.location.pathname}`} title={"Share Profile"}>
                                            <WhatsappIcon />
                                        </WhatsappShareButton>
                                        <FacebookShareButton url={`${window.location.origin}${window.location.pathname}`} title={"Share Profile"}>
                                            <FacebookIcon />
                                        </FacebookShareButton>
                                    </PopoverComponent>
                                </div> */}
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {/* <CustomModal
                    isOpen={state.showEdit}
                    toggle={() => toggleEdit()}
                    backdrop={false}
                    keyboard={false}
                    header="Edit Personal details"
                >
                    <FormEdit
                        formControl={state.formEdit}
                        onChange={handleChange}
                        formName={"formEdit"}
                        onSubmit={handleSubmit}
                        domains={domains.data}
                    />
                </CustomModal>

                <CustomModal
                    isOpen={state.showDomainEdit}
                    toggle={submitting ? null : () => toggleEdit("showDomainEdit")}
                    backdrop={false}
                    keyboard={false}
                    classProp="modal-lg"
                    header="Edit Domains"
                >
                    <DomainsComponent topDomains={state.topDomains} userDomains={state.userDomains} topDomainClick={topDomainClick} onRemoveDomain={onRemoveDomain} handleChange={handleChange} onFormSubmit={onFormSubmit} formControl={state.formProfessional} />
                    <div className="text-right">
                        <Button onClick={submitDomains} text="Submit" color="primary" className="my-2" loading={submitting} />
                    </div>
                </CustomModal> */}
      </Container>
    </>
  );
};

export default Details;
