import React, { useEffect, useState } from 'react';
import style from './Index.module.css';
import { Link, useSearchParams } from 'react-router-dom';
import { verifyMail } from 'services/authService';
import Logo from 'components/common/Logo';
import { toast } from 'react-toastify';
import Loader from 'components/common/Loader';

const Index = () => {
  const [searchParams] = useSearchParams();
  const mailToken = searchParams.get('mailToken');
  const authToken = searchParams.get('authToken');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const verify = async () => {
      try {
        await verifyMail({ mailToken, authToken });
        setIsLoading(false);
      } catch (err) {
        setError(true);
        setIsLoading(false);
        console.log(err, 'error');
      }
    };
    verify();
  }, [mailToken, authToken]);

  let content;
  if (isLoading) {
    content = <Loader />;
  }
  if (isError === true) {
    content = toast.error('Something Went Wrong');
  }
  if (isLoading === false && isError === false) {
    content = (
      <div className={style.container}>
        <Logo className={style.img} />
        <div className={style.box}>
          <h1 className={style.header}>You've been Successfully Verified</h1>
          {/* <SmileEmoji className={style.icon} /> */}
          <Link className={style.login} to='/auth/login'>
            Proceed to Login
          </Link>
        </div>
      </div>
    );
  }

  return <>{content}</>;
};

export default Index;
