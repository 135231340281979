import React from 'react'
import { Col, Row } from 'reactstrap'
import Loader from '../Loader'
import Form from './Form'

export const DomainsComponent: React.FC<any> = ({ topDomains, userDomains, topDomainClick, onRemoveDomain, formControl, onFormSubmit, handleChange, loading }) => {
  return (
    <Row className='domain-component'>
      <Col sm="12">
        {topDomains.length > 0 && <p className='text-muted small my-3'>Top Domains</p>}
        {loading ? <div>
          <Loader />
        </div> :
          <Row>
            {topDomains.map((domain: string, i: number) => (
              <Col md="4" key={domain}>
                <div className='top-domain d-flex align-items-center p-2 mb-2'>
                  <p className={`small text-${userDomains?.includes(domain) ? 'muted' : 'deepBlue'} mb-0`}>{domain}</p>
                  <div className='ml-auto add-btn cursor-pointer' onClick={() => topDomainClick(i)}>
                    <img src={require('assets/images/svg/icons/ic_add_blue.svg').default} alt="add" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        }
        <Row>
          <Col sm="12">
            <p className='text-muted small mt-3 mb-0'>Enter prefered domains and hit ‘enter’ key</p>
            <div className='user-domain-div p-3'>
              <Row>
                {userDomains?.map((domain: string, i: number) => (
                  <Col md="4" key={domain}>
                    <div className='domain p-2 my-2 text-white d-flex align-items-center'>
                      <p className={`mb-0 small`}>{domain}</p>
                      <div className='delete-btn ml-auto cursor-pointer' onClick={() => onRemoveDomain(i)}>
                        <img src={require('assets/images/svg/icons/ic_delete_white.svg').default} alt="" />
                      </div>
                    </div>
                  </Col>
                ))}
                <Col md="4">
                  <Form
                    onChange={handleChange}
                    onSubmit={onFormSubmit}
                    formControl={formControl}
                    formName="formProfessional"
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
