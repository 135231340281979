import { ExpertCard } from 'components/common/ExpertCards';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getAllExperts } from 'services/expertService';
import { apiErrorHandler } from 'Helpers/utility';
import InfiniteScroll from 'components/common/InfiniteScroll';
import Loader from 'components/common/Loader';

const Experts: React.FC<any> = ({ col, search }) => {
  const [state, setState] = useState({
    experts: [],
    pageNo: 0,
    pageSize: 20,
    hasMore: false,
    totalRecord: 0,
    loading: false
  });

  const toggleLoading = (loading = false) => {
    setState((prev) => ({
      ...prev,
      loading: loading || !prev.loading
    }));
  };
  const loadExperts = useCallback(
    async (pageNo = 1, searchParam = null) => {
      pageNo === 1 && toggleLoading(true);
      try {
        let { data } = await getAllExperts(state.pageSize, pageNo, searchParam);
        setState((prev) => ({
          ...prev,
          experts:
            pageNo > 1
              ? prev.experts.concat(data.data.expert)
              : data?.data?.expert,
          pageNo: data?.data?.page,
          totalRecord: data?.data?.count,
          hasMore: pageNo * state.pageSize < data?.totalRecord,
          loading: false
        }));
      } catch (error) {
        apiErrorHandler(error);
        pageNo === 1 && toggleLoading(false);
      }
    },
    [state.pageSize]
  );
  useEffect(() => {
    setState({
      experts: [],
      pageNo: 0,
      pageSize: 20,
      hasMore: false,
      totalRecord: 0,
      loading: false
    });

    loadExperts(1, search?.trim() !== '' ? search : null);
  }, [loadExperts, search]);
  return (
    <div>
      <Container>
        <Row>
          <Col sm='12'>
            <div>
              <h5 className='text-grayScale my-3'>Experts for you</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        className='expert-group-cards'
        id='scrollTarget'
        style={{ height: '90%', overflow: 'hidden' }}>
        {state.loading && <Loader />}
        {!state.loading && state?.experts?.length === 0 && (
          <h3 className='text-center'> New Experts will appear here soon 😄</h3>
        )}
        <InfiniteScroll
          dataLength={state?.experts?.length} //This is important field to render the next data
          next={() =>
            loadExperts(state.pageNo + 1, search.trim() !== '' ? search : null)
          }
          hasMore={state.hasMore}>
          <Container>
            <Row>
              {state?.experts?.map((info, i) => (
                <Col {...col} className='mt-3'>
                  <ExpertCard data={info} index={i} />
                </Col>
              ))}
            </Row>
          </Container>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Experts;
