import FormsWrapper, { showFieldError } from 'components/Forms/Formik';
import React from 'react';
import * as Yup from "yup"
import { Col, Container, Input, Row } from 'reactstrap'
import Button from 'components/common/Button';

interface formInterface {
    formControl: any,
    onSubmit?: any,
    onChange?: any,
    formName: any,
}

const validation = Yup.object().shape({
    oldPassword: Yup.string()
        .required("Required"),
    password: Yup.string()
        .required("Required")
        .min(8, "Minimum of 8 characters"),
    confirmPassword: Yup.string()
        .required("Required").oneOf([Yup.ref("password"), null], "Password dont match"),

})

const FormPassword: React.FC<formInterface> = React.memo((props) => {
    let { formControl, onChange, onSubmit, formName } = props

    return (
        <FormsWrapper
            values={formControl}
            handleSubmit={onSubmit}
            handleChange={onChange}
            validationSchema={validation}>
            {
                (props: { values: any; isValid: any, touched: any; errors: any; handleBlur: any; handleChange: any; handleSubmit: any; isSubmitting: any }) => {
                    const {
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting } = props;
                    return (
                        <form name={formName} onChange={onChange} onSubmit={handleSubmit}>
                            <Container fluid>
                                <Row>
                                <Col md="4">
                                        <label htmlFor="" className="small text-muted m-0">Old Password</label>
                                        <Input
                                            type='password'
                                            className='form-control'
                                            name="oldPassword"
                                            onBlur={handleBlur}
                                            value={formControl.oldPassword}
                                            invalid={touched.oldPassword && errors.oldPassword}
                                            onChange={handleChange} />
                                        {showFieldError("oldPassword", errors, touched)}
                                    </Col>
                                    <Col md="4">
                                        <label htmlFor="" className="small text-muted m-0">New Password</label>
                                        <Input
                                            type='password'
                                            className='form-control'
                                            name="password"
                                            onBlur={handleBlur}
                                            value={formControl.password}
                                            invalid={touched.password && errors.password}
                                            onChange={handleChange} />
                                        {showFieldError("password", errors, touched)}
                                    </Col>
                                    <Col md="4">
                                        <label htmlFor="" className="small text-muted m-0">Confirm Password</label>
                                        <Input
                                            type='password'
                                            className='form-control'
                                            name="confirmPassword"
                                            value={formControl.confirmPassword}
                                            onBlur={handleBlur}
                                            invalid={touched.confirmPassword && errors.confirmPassword}
                                            onChange={handleChange} />
                                        {showFieldError("confirmPassword", errors, touched)}
                                    
                                    </Col>
                                </Row>
                                <Row>
                                <Col md="12" className='d-block d-md-flex mt-2'>
                                        <Button type="submit" role="submit" color='darkBlue' loading={isSubmitting} className='ml-auto' text={"Change"} />
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    )
                }
            }
        </FormsWrapper >
    )
})


export default FormPassword;