// import AdvertComponent from './SubComponents/AdvertComponent';
// import { CommunityComponent } from './SubComponents/CommunityComponent';
import LearningComponent from './SubComponents/LearningComponent';
import SessionsComponent from './SubComponents/SessionsComponent';
import TopComponent from './SubComponents/TopComponent';
import CardScroller from './SubComponents/CardScroller';
import ContinentComponent from './SubComponents/ContinentComponent';
import CanDo from './SubComponents/CanDo';
// import Reviews from 'Pages/Landing/SubComponents/Review';

export default function Landing() {
  return (
    <div id='landing'>
      <div className='mx-0 mx-md-4'>
        <TopComponent />

        {/* <AdvertComponent /> */}
        <LearningComponent />
        <SessionsComponent />
        <CardScroller />
        {/* <Reviews /> */}

        {/* <CommunityComponent /> */}
      </div>
      <CanDo />
      <ContinentComponent />
    </div>
  );
}
