import { Popover, PopoverBody } from 'reactstrap'

const PopoverComponent: React.FC<any> = (props) => {

    return (
        <Popover placement="top" popperClassName="popover-no-border" isOpen={props.isOpen} target={props.id} toggle={props.toggle}>
            <PopoverBody>
                {props.children}
            </PopoverBody>
        </Popover>
    )
}

export default PopoverComponent;